import { createSlice } from '@reduxjs/toolkit';
import { Type } from 'utils/interface';
import { accountSettingsApi, updateProfileApi, verifyEmailApi, verifyEmailOTPApi } from '../actions/accountSettingAction';

const values = { data: [], status: 'idle', error: null } as Type;
const initialState = {
  verifyEmailApi: values,
  verifyEmailOTPApi: values,
  accountSettingsApi: values,
  updateProfileApi:values,
};

const accountSettingSlice = createSlice({
  name: 'accountSetting',
  initialState,
  reducers: {
    clearAccountStatus: (state) => {
      const keys = Object.keys(state);
      for (let index = 0; index < keys.length; index++) {
        state[keys[index]].status = 'idle';
      }
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        (action) =>
          [accountSettingsApi.pending,verifyEmailOTPApi.pending,verifyEmailApi.pending,updateProfileApi.pending].some((type) =>
            action.type.endsWith(type)
          ),
        (state, { type }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'loading';
          state[apiName].error = null;
        }
      )
      .addMatcher(
        (action) =>
          [accountSettingsApi.fulfilled,verifyEmailOTPApi.fulfilled,verifyEmailApi.fulfilled,updateProfileApi.fulfilled].some((type) =>
            action.type.endsWith(type)
          ),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'success';
          state[apiName].data = payload;
          state[apiName].error = null;
        }
      )
      .addMatcher(
        (action) =>
          [accountSettingsApi.rejected,verifyEmailOTPApi.rejected,verifyEmailApi.rejected ,updateProfileApi.rejected].some((type) =>
            action.type.endsWith(type)
          ),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'error';
          state[apiName].data = [];
          state[apiName].error = payload;
        }
      );
  },
});

export const accountSettingsSelector = (state: {
  accountSetting: { accountSettingsApi: Type };
}) => state.accountSetting.accountSettingsApi;
export const verifyEmailAddressSelector = (state: {
  accountSetting: { verifyEmailApi: Type };
}) => state.accountSetting.verifyEmailApi;
export const verifyEmailOtpSelector = (state: {
  accountSetting: { verifyEmailOTPApi: Type };
}) => state.accountSetting.verifyEmailOTPApi;

export const updateProfileSelector = (state: {
  accountSetting: { updateProfileApi: Type };
}) => state.accountSetting.updateProfileApi;

export const { clearAccountStatus } = accountSettingSlice.actions;
export default accountSettingSlice.reducer;

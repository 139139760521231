import { createSlice } from "@reduxjs/toolkit";
import { Type } from "utils/interface";
import { disableClientTwoFaApi, enableClientTwoFaApi, twoFaSettingApi, verifyClientTwoFaApi } from "../actions/twofaVerificationAction";


const values = { data: [], status: 'idle', error: null } as Type
const initialState = {
    enableClientTwoFaApi: values,
    verifyClientTwoFaApi: values,
    disableClientTwoFaApi: values,
    twoFaSettingApi:values,
};
const twofaverificationSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        clearTwoFAStatus: (state) => {
            const keys = Object.keys(state)
            for (let index = 0; index < keys.length; index++) {
                state[keys[index]].status = 'idle'
            }
        }
    },
    extraReducers(builder) {
        builder.addMatcher(action => [twoFaSettingApi.pending, enableClientTwoFaApi.pending, verifyClientTwoFaApi.pending, disableClientTwoFaApi.pending].some(type => action.type.endsWith(type)), (state, { type }) => {
            const apiName = type.split('/')[1];
            state[apiName].status = 'loading';
            // state[apiName].data = [];
            state[apiName].error = null;

        })
            .addMatcher(action => [twoFaSettingApi.fulfilled, enableClientTwoFaApi.fulfilled, verifyClientTwoFaApi.fulfilled, disableClientTwoFaApi.fulfilled].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].status = 'success';
                state[apiName].data = payload;
                state[apiName].error = null;

            })
            .addMatcher(action => [twoFaSettingApi.rejected, enableClientTwoFaApi.rejected, verifyClientTwoFaApi.rejected, disableClientTwoFaApi.rejected].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].status = 'error';
                state[apiName].data = [];
                state[apiName].error = payload;
            });

    },
});

export const enableClientTwoFaSelector = (state: { securitytwoFa: { enableClientTwoFaApi: Type } }) => state.securitytwoFa.enableClientTwoFaApi
export const verifyClientTwoFaSelector = (state: { securitytwoFa: { verifyClientTwoFaApi: Type } }) => state.securitytwoFa.verifyClientTwoFaApi
export const disableClientTwoFaSelector = (state: { securitytwoFa: { disableClientTwoFaApi: Type } }) => state.securitytwoFa.disableClientTwoFaApi
export const twoFaSettingSelector = (state: { securitytwoFa: { twoFaSettingApi: Type } }) => state.securitytwoFa.twoFaSettingApi

export const { clearTwoFAStatus } = twofaverificationSlice.actions
export default twofaverificationSlice.reducer
// import { useInView } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export interface AccordionProps {
  title?: string;
  content?: string;
  showContent?: boolean;
}
export default function Accordion({
  title,
  content,
  showContent,
}: AccordionProps): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);
  // const isInView = useInView(ref);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

  const toggleAccordion = useCallback(
    (open: any = false) => {
      if (open === true) {
        setActiveAccordion(title as string);
        setIsOpen(true);
      }

      if (activeAccordion === title) {
        setActiveAccordion(null);
        setIsOpen(false);
      } else {
        setActiveAccordion(title as string);
        setIsOpen(true);
      }
    },
    [title, activeAccordion]
  );

  useEffect(() => {
    if(showContent){
      setIsOpen(true)
      setActiveAccordion(title as string)
    }
  }, [showContent, title]);
  return (
    <div
      ref={ref}
      className=' mb-8 overflow-hidden rounded-xl border-2 border-solid border-[#F1F1F1]'
    >
      <button
        className='flex w-full items-center justify-between rounded-xl p-5 pb-6 focus:outline-none '
        onClick={toggleAccordion}
      >
        <span className=' pr-4   text-left text-sm/[16px]  font-bold sm:text-neutral-900 md:text-xl'>
          {title}
        </span>
        <div className='icontArrow rounded-md bg-sky-50 p-3'>
          <svg
            className={`h-4 w-4 transform stroke-[#0040E3] text-blue-700 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''
              }`}
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M19 9l-7 7-7-7'
            />
          </svg>
        </div>
      </button>
      {activeAccordion === title && (
        <div className='bg-white p-4 pt-0'>
          <p className='mb-[10px] w-[100%] max-w-[750px] text-left text-[#4e4e4e]'>
            {content}
          </p>
        </div>
      )}
    </div>
  );
}

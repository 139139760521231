import { useAuthContext } from 'components/providers/auth-state-provider';
import { Loader } from 'components/ui/loader';
import Sidenav from 'components/user/app/sidenav';
import React, { Suspense, memo, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const DashboardLayout = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  useEffect(() => {
    if (!isAuthenticated) navigate('/', { replace: true });
  }, [isAuthenticated, navigate]);

  return (
    <>
      <div className=' py-[30px]'>
        <div className='m-auto flex w-full max-w-[1120px]  flex-wrap items-start  gap-[20px] px-4 sm:px-6 md:flex-wrap lg:flex-nowrap lg:px-8 '>
          <Sidenav />
          <Suspense fallback={<Loader className='' />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default memo(DashboardLayout);

import { createSlice } from "@reduxjs/toolkit";
import { getBlogsApi } from "../actions/blogAction";
import { format } from 'date-fns';


interface Props {
    status: 'idle' | 'error' | 'loading' | 'success',
    blogs: Array<Object>,
    paginationData: object
    error: any

}

const initialState: Props = {
    status: 'idle',
    blogs: [],
    paginationData: {},
    error: null

}


const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        clearState() {
            return initialState
        }

    },
    extraReducers(builder) {
        builder.addMatcher(action => [getBlogsApi.pending].some(type => action.type.endsWith(type)), (state) => {
            state.status = 'loading';
            state.error=null
            state.blogs=[]
            state.paginationData={}
        })
            .addMatcher(action => [getBlogsApi.fulfilled].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                state.status = 'success';
            state.error=null

                const blogsWithFormattedDate = payload.data.posts.map(post => {
                    const dateString = post.date_created;
                    const date = new Date(dateString);
                    const formattedDate = format(date, 'MMMM dd');
                    return {
                        ...post,
                        formattedDate: formattedDate,
                    };
                });
                const { count, page, limit } = payload.data
                state.paginationData = { count, page, limit }
                state.blogs = blogsWithFormattedDate;
            })
            .addMatcher(action => [getBlogsApi.rejected].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                state.status = 'error';
                state.error = payload
                state.blogs=[]
                state.paginationData={}
            })
    },
})

export const { clearState } = blogSlice.actions
export default blogSlice.reducer
import toast from 'react-hot-toast';
import { MdOutlineDone } from "react-icons/md";
import { motion } from 'framer-motion'
export interface ToastProps {
    message: string;
    variant: "default" | "error" | "success" | "warning" | "info"
}


const ToastrError = (message: string): void => {
    toast.error(message, { className: " text-red-600 bg-red-100" })
}


const ToastrSuccess = (message: string): void => {
    toast.remove();
    toast.custom((t) => (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            className={`bg-[#E7FFE3] text-black px-3 py-2 shadow-md rounded-[5px] w-[20%] flex gap-3 items-center ${t.visible ? 'animate-enter' : 'animate-leave'
                }`}
        >
            <span className='h-[20px] w-[20px] rounded-lg bg-blue-500 flex items-center justify-center'>
                <MdOutlineDone className='text-white' /> </span>
            {message}
        </motion.div>
    ));
    // toast.success(message, { className: " bg-green-100" })
}

export {
    ToastrError, ToastrSuccess
}

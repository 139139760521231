import cn from 'classnames';

import Spinner from 'components/ui/spinner';
import { memo } from 'react';

export interface ButtonProps {
  loading?: boolean;
}

export default memo(function Button({
  className,
  children,
  disabled,
  loading,
  ...props
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  return (
    <button
      {...props}
      disabled={loading || disabled}
      className={cn(
        'flex w-full items-center justify-center text-sm font-bold text-white',
        'border border-transparent bg-blue-500 px-5 py-2',
        'hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-black',
        'rounded-xl disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-200',
        className
      )}
    >
      {loading ? <Spinner size={7} /> : children}
    </button>
  );
});

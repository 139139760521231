import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, apiEndPoints } from 'utils/helper/config';

interface sellerOrdersHistoryProp {
  page_number: number;
  page_size: number;
  status?: string;
}
const sellerOrdersHistoryApi = createAsyncThunk(
  'seller/sellerOrdersHistoryApi',
  async (
    { page_number, page_size, status }: sellerOrdersHistoryProp,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        apiEndPoints.sellerOrdersHistory +
          `?page_number=${page_number}&page_size=${page_size}&status=${status}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const sellerOrdersReceiptApi = createAsyncThunk(
  'seller/sellerOrdersReceiptApi',
  async (
    { id }:{id:string} ,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        apiEndPoints.viewSellerOrderReceipt +`?id=`+id
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
export { sellerOrdersHistoryApi,sellerOrdersReceiptApi };

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, apiEndPoints } from 'utils/helper/config';

interface Parmas {
    page: number;
    limit: number;
    query?: string;
    sortBy?: string;
}
const acceptedCardsApi = createAsyncThunk(
    'acceptedCards/acceptedCardsApi',
    async (parmas: Parmas, { rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await apiClient.get(`${apiEndPoints.acceptedCards}?page=${parmas.page}&limit=${parmas.limit}&query=${parmas.query}${parmas.sortBy && '&sortBy=' + parmas.sortBy}`);
            const { data } = response;
            return fulfillWithValue(data);
        } catch (error: any) {
            const { data } = error.response;
            return rejectWithValue(data);
        }
    }
);

export { acceptedCardsApi };

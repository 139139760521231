import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

// import linkedIn from 'assets/images/linkedIn.svg'
// import facebook from 'assets/images/facebook.svg'
// import twtter from 'assets/images/twtter.svg'
// import instaGram from 'assets/images/insta.svg'
import Logo from './logo'
import { FaXTwitter } from "react-icons/fa6";
import { BsThreadsFill } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { publicRoutes } from 'routes/routes';
// import { useAppDispatch } from 'store/store'
// import { useAuthContext } from './auth-state-provider'

export interface FooterProps {
  unHide?: boolean
}

export default function Footer({ unHide }: FooterProps): JSX.Element {
  // const dispatch = useAppDispatch()
  // const { isAuthenticated }: any = useAuthContext()

  const { pathname } = useLocation()
  const [isHidden, setHidden] = useState<boolean>(false)


  useEffect(() => {
    const hiddenPaths: string[] = ['/docs-form']
    setHidden(hiddenPaths.some(path => path === pathname))
  }, [pathname])
  // const handleLogout = () => {
  //   dispatch({ type: 'logout' })
  //   document.location.href = '/'
  // }
  return !isHidden && !!unHide ? (
    <></>
  ) : (
    <footer
      className="flex min-h-[270px] flex-col items-center justify-centerprint:hidden"
      aria-labelledby="footer-heading"
    >
      <div className='lg:max-w-[1080px] w-[100%] lg:p-0 mx-auto flex items-start lg:items-stretch flex-wrap md:w-[100%] md:px-[20px] sm:px-[20px]  xl:px-0 lg:px-8  px-8  justify-center lg:justify-start md:justify-start '>
        <div className=' sm:px-0 lg:w-5/12 md:w-[100%] lg:flex  lg:items-start  lg:justify-between lg:p-0 sm:w-[100%] md:flex md:flex-wrap md:items-center md:justify-between flex flex-col items-center  justify-center w-full' >
          <Link className='mb-0 w-[183px] block' to="/">
            <Logo headerLogo={true} className="h-[50px] w-[183px] lg:w-[140px] lg:h-[45px] md:w-[190px] md:h-[50px] sm:h-[50px]" />
          </Link>
          <div className='flex flex-wrap mt-14  mb-16 gap-[15px] max-w-[300px] w-full xl:justify-start lg:justify-start  justify-center '>
            <div className='max-w-[40px] w-full h-[40px] flex items-center justify-center rounded-md border-2 border-solid border-[#F1F1F1] '>
              <Link to={'https://twitter.com/GiftXDOfficial'} target='_blanck' >
                 <FaXTwitter  className='text-[#9a9a9a] text-[20px]'/>
              </Link>
            </div>
            <div className='max-w-[40px] w-full h-[40px] flex items-center justify-center rounded-md border-2 border-solid border-[#F1F1F1]'>
              <Link to={'https://www.linkedin.com/company/giftxd'} target='_blanck'>
              <FaLinkedinIn  className='text-[#9a9a9a] text-[20px]'/>
                </Link>
            </div>
            <div className='max-w-[40px] w-full h-[40px] flex items-center justify-center rounded-md border-2 border-solid border-[#F1F1F1]'>
              <Link to={'https://www.threads.net/@giftxdofficial'} target='_blanck'>
            <BsThreadsFill className='text-[#9a9a9a] text-[20px]'/>
            </Link>
            </div>
            <div className='max-w-[40px] w-full h-[40px] flex items-center justify-center rounded-md border-2 border-solid border-[#F1F1F1]'>
              <Link to={'https://www.instagram.com/GiftXDOfficial/'} target='_blanck' >
              <FaInstagram  className='text-[#9a9a9a] text-[20px]'/>
                </Link>
            </div>
          </div>
        </div>
        <nav className='lg:w-7/12 mb-16 flex md:w-[100%] lg:order-2  sm:w-[100%]  flex-wrap'>
          <ul className='grid sm:flex gap-[20px]  flex-col lg:w-2/6 md:w-[33%] px-5 sm:px-0 w-[100%] mb-[25px] lg:mb-[0px] md:mb-[0px] justify-center lg:justify-start '>
            <li className='lg:text-left text-center'>
              <Link to="/" className='text-[#4e4e4e] text-lg  font-bold lg:text-left text-center'>Website</Link>
            </li>
            <li className='lg:text-left text-center'>
              <Link to="/" className='text-[#373737] text-base font-medium '>Home</Link>
            </li>
            <li className='lg:text-left text-center'>
              <Link to="/wallet" className='text-[#373737] text-base font-medium'> Wallet</Link>
            </li>
            <li className='lg:text-left text-center'>
              <Link to="/accepted-cards" className='text-[#373737] text-base font-medium'>Accepted Card</Link>
            </li>
            <li className='lg:text-left text-center'>
              <Link to="/become-vendor" className='text-[#373737] text-base font-medium'>Become A Vendor</Link>
            </li>
          </ul>
          <ul className='grid sm:flex gap-6  flex-col lg:w-2/6 md:w-[33%] px-5 sm:px-0 w-[100%] mb-[25px] lg:mb-[0px] md:mb-[0px]'>
            <li className='lg:text-left text-center'>
              <Link to="/" className='text-[#373737] text-lg  font-bold'>Resources</Link>
            </li>
            {/* <li className='lg:text-left text-center'>
              <Link to="/blog" className='text-[#373737] text-base font-medium' > Our Blog</Link>
            </li> */}
            <li className='lg:text-left text-center'>
              <Link to="/faq" className='text-[#373737] text-base font-medium'>FAQ’s</Link>
            </li>
            <li className='lg:text-left text-center'>
              <Link to={publicRoutes.contactUs.path} className='text-[#373737] text-base font-medium'>Contact Us</Link>
            </li>
            {/* <li className='lg:text-left text-center'>
              <Link to="/maps" className='text-[#373737] text-base font-medium'>Where To Buy Gift Cards</Link>
            </li> */}
          </ul>
          {/*<ul className='grid sm:flex gap-6  flex-col lg:w-2/6 md:w-[33%] px-5 sm:px-0 w-[100%] '>
            <li className='lg:text-left text-center'>
              <Link to="" className='text-[#373737] text-lg  font-bold'>Account</Link>
            </li>
            {isAuthenticated ? (
              <>
                <li className='lg:text-left text-center'>
                  <Link to="/dashboard" className='text-[#373737] text-base font-medium'>Go To Dashboard</Link>
                </li>
                <li className='lg:text-left text-center'>
                  <button onClick={handleLogout} className='text-[#373737] text-base font-medium'>Log Out</button>
                </li>
              </>
            ) : (
              <>
                <li className='lg:text-left text-center'>
                  <Link to="/auth/signup" className='text-[#373737] text-base font-medium'> Create An Account</Link>
                </li>
                <li className='lg:text-left text-center'>
                  <Link to="/auth/login" className='text-[#373737] text-base font-medium'>Log In</Link>
                </li>
              </>
            )}
            </ul>*/}
        </nav>
      </div>

      <div className='bg-blue-500 w-full '>
        <div className='flex lg:h-[60px] md:h-[60px] sm:h-16 h-[auto]   w-[100%] items-center m-auto  flex-wrap  justify-between sm:justify-center flex-col-reverse lg:flex-row lg:justify-between pt-[30px] pb-[20px] lg:pt-[15px] lg:pb-[15px] max-w-[1080px] '>
          <div className='mt-0 mb-0 w-[100%] sm:w-[auto]'>
            <p className='text-center  text-base text-[#ffffff] font-normal mb-[10px] lg:mb-[0px]'  >2023 GiftXD</p>
          </div>
          <div className='quickLinks'>
            <ul className='flex flex-wrap w-[100%] sm:w-[auto]'>
              <li className='px-5 w-[100%] sm:w-[auto] text-center mb-[10px] lg:mb-[0px]'>
                <Link to={publicRoutes.TermsOfService.path} className=' text-base text-[#ffffff] font-normal ' > Terms of Service</Link>
              </li>
              <li className='px-5 w-[100%] sm:w-[auto] text-center mb-[10px] lg:mb-[0px]'>
                <Link to={publicRoutes.PrivacyPolicy.path} className=' text-base text-[#ffffff] font-normal'>Privacy Policy</Link>
              </li>
              {/* <li className='px-5 w-[100%] sm:w-[auto] text-center mb-[10px] lg:mb-[0px]'>
                <Link to="" className='text-base text-[#ffffff] font-normal'>Cookies</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';

import { usePreloaderContext } from 'components/providers/preloader-state-provider';
import { useAuthContext } from 'components/providers/auth-state-provider';
import PublicLayout from 'components/user/public/layout';
import AuthLayout from 'components/user/auth/layout';

import MainLayout from 'components/user/layout';

import DashboardLayout from 'compositions/app/dashboard/layout';
import { authRoutes, publicRoutes, dashBoardRoutes, appRoutes } from './routes';
import AppLayout from 'components/user/app/layout';

export default function Wrapped(): JSX.Element {
  return (
    <MainLayout >
      <UserRoutes />
    </MainLayout>
  );
}

function UserRoutes(): JSX.Element {
  const { preloaderAnimate } = usePreloaderContext();
  const { isAuthenticated } = useAuthContext();
  const [URLSearchParam] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    const preloadPaths = ['/'];
    const { hash } = location;

    if (
      preloadPaths.some((path) => path === location.pathname) &&
      !URLSearchParam.get('step')
    )
      if (hash !== '')
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
  }, [location, URLSearchParam, preloaderAnimate]);

  useEffect(() => {
    preloaderAnimate();
  }, [isAuthenticated, preloaderAnimate]);

  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        {
          /*-----  Dashboard pages  -----*/
          <Route path='/dashboard' element={<DashboardLayout />}>
            {Object.keys(dashBoardRoutes).map((key: string, index: number) => (
              <Route
                key={key + ' ' + index}
                path={dashBoardRoutes[key].path}
                element={dashBoardRoutes[key].element}
              />
            ))}
          </Route>
        }

        {
          /*----- Auth pages -----*/
          <Route path='/auth' element={<AuthLayout />}>
            {Object.keys(authRoutes).map((key: string, index: number) => (
              <Route
                key={index + ' ' + key}
                path={authRoutes[key].path}
                element={authRoutes[key].element}
              />
            ))}
          </Route>
        }

        {
          /*-----  Public pages  -----*/
          <Route path='/app' element={<AppLayout />}>
            <Route
              path={appRoutes.profile.path}
              element={appRoutes.profile.element}
            />
          </Route>
        }
        {
          /*-----  Public pages  -----*/
          <Route path='/' element={<PublicLayout />}>
            {Object.keys(publicRoutes).map((key: string, index: number) => (
              <Route
                key={index + ' ' + key}
                path={publicRoutes[key].path}
                element={publicRoutes[key].element}
              />
            ))}
          </Route>
        }

        {/*----- End page -----*/}
        <Route path='*' element={<Navigate to='/' replace={true} />} />
      </Routes>
    </AnimatePresence>
  );
}

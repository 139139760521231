import cn from 'classnames'
import { motion } from 'framer-motion'
export interface Props {
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children?: React.ReactNode
  className?: string
}

export default function Heading({ size, className, children }: Props) {
  switch (size) {
    case 'h6':
      return (
        <h6
          className={cn(
            'text-md font-bold text-gray-950 sm:text-lg lg:text-2xl',
            className
          )}
        >
          {children}
        </h6>
      )
    case 'h5':
      return (
        <h5
          className={cn(
            ' font-bold text-gray-950 sm:text-xl lg:text-2xl mb-[30px] text-  base ',
            className
          )}
        >
          {children}
        </h5>
      )
    case 'h4':
      return (
        <h4
          className={cn(
            'text-xl font-bold text-gray-950 sm:text-2xl lg:text-3xl',
            className
          )}
        >
          {children}
        </h4>
      )
    case 'h3':
      return (
        <h3
          className={cn(
            'text-2xl font-extrabold text-gray-950 sm:text-3xl lg:text-4xl',
            className
          )}
        >
          {children}
        </h3>
      )
    case 'h2':
      return (
        <motion.h2
          className={cn(
            'text-3xl font-extrabold text-gray-950 sm:text-4xl lg:text-5xl',
            className
          )}
        >
          {children}
        </motion.h2>
      )
    default:
      return (
        <h1
          className={cn(
            'text-4xl font-extrabold text-gray-950 sm:text-5xl lg:text-6xl',
            className
          )}
        >
          {children}
        </h1>
      )
  }
}

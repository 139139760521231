import Page from 'components/ui/page'
import React from 'react'

const className = 'mb-[10px] text-sm font-medium not-italic leading-5 text-black'
export default function PrivacyPolicy() {
    return (
        <Page>
            <b className='mb-[10px] block text-sm font-bold not-italic leading-5 text-black'>
                Privacy 1:
            </b>
            <p className={className}>
                Enhanced privacy measures due to new partnerships,
                ensuring the secure conversion of gift cards to cryptocurrency.
            </p>
            <p className=' mb-[10px] text-sm font-medium not-italic leading-5 text-[#F04438]'>
                An extension of GiftXD's features and services with added privacy protections
                for a more secure liquidation experience.
            </p>
            <p className={className}>
                Implementation of stronger account management protocols,
                incorporating clear definitions of data stewardship roles.
            </p>
            <p className=' mb-[10px] text-sm font-medium not-italic leading-5 text-[#F04438]'>
                Improved clarity in our legal provisions to ensure the privacy and
                protection of all members of the GiftXD platform.
            </p>
            <p className={className}>
                Introduction of an Arbitration agreement and Class Action Waiver to resolve disputes efficiently, respecting your privacy rights.
            </p>
            <p className={className}>
                Detailed insights into how GiftXD interacts with third-party services while maintaining your data confidentiality.
            </p>
        </Page>
    )
}

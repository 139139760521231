import ReactPinInput from 'react-pin-input';
import emotion from '@emotion/styled';
import { memo } from 'react';

export default memo(function PinInput({
  length,
  verified,
  onPinChange,
  ...props
}: any) {
  const StyledDiv = emotion.div<any>`
  .pincode-input-container { 
    display: grid;
  	grid-template-columns: repeat(${Number(length) || 4}, minmax(0, 1fr)); 
    gap: 0.75rem;
  }

  .pincode-input-text {
    aspect-ratio: 1/1;
    width: 100% !important;
    height: auto !important;
    border-radius: 10px;
    border: 2px solid ${verified ? '#6bbb26' : '#f6f6f6'}!important;
  }

  .pincode-input-text:focus {
    border: 2px solid #0040E3 !important;
  }
`;
  const handlePinChange = (value, index: number) => {
    if (onPinChange) {
      onPinChange(value);
    }
  };

  return (
    <StyledDiv className='text-4xl font-extrabold text-black'>
      <ReactPinInput
        autoSelect={true}
        length={length}
        onChange={handlePinChange}
        {...props}
      />
    </StyledDiv>
  );
});

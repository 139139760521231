import { createSlice } from '@reduxjs/toolkit';
import {
  addNewLinkedAccount,
  deleteLinkedAccount,
  getAllLinkedAccount,
  updateLinkedAccount
} from '../actions/connectedAccountAction';
import { Type } from 'utils/interface';

const values = { data: [], status: 'idle', error: null } as Type;
const initialState = {
  getAllLinkedAccount: values,
  addNewLinkedAccount: values,
  deleteLinkedAccount: values,
  updateLinkedAccount: values
};
const connectedAccountSlice = createSlice({
  name: 'connectedAccount',
  initialState,
  reducers: {
    clearStatus: state => {
      const keys = Object.keys(state);
      for (let index = 0; index < keys.length; index++) {
        state[keys[index]].status = 'idle';
      }
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        action =>
          [
            getAllLinkedAccount.pending,
            addNewLinkedAccount.pending,
            deleteLinkedAccount.pending,
            updateLinkedAccount.pending
          ].some(type => action.type.endsWith(type)),
        (state, { type }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'loading';
          // state[apiName].data = [];
          state[apiName].error = null;
        }
      )
      .addMatcher(
        action =>
          [
            getAllLinkedAccount.fulfilled,
            addNewLinkedAccount.fulfilled,
            deleteLinkedAccount.fulfilled,
            updateLinkedAccount.fulfilled
          ].some(type => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'success';
          state[apiName].data = payload;
          state[apiName].error = null;
        }
      )
      .addMatcher(
        action =>
          [
            getAllLinkedAccount.rejected,
            addNewLinkedAccount.rejected,
            deleteLinkedAccount.rejected,
            updateLinkedAccount.rejected
          ].some(type => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'error';
          state[apiName].data = [];
          state[apiName].error = payload;
        }
      );
  }
});
export const allLinkedAccountsSelector = (state: {
  connectedAccount: { getAllLinkedAccount: Type };
}) => state.connectedAccount.getAllLinkedAccount;
export const addNewLinkedAccountsSelector = (state: {
  connectedAccount: { addNewLinkedAccount: Type };
}) => state.connectedAccount.addNewLinkedAccount;
export const deleteLinkedAccountsSelector = (state: {
  connectedAccount: { deleteLinkedAccount: Type };
}) => state.connectedAccount.deleteLinkedAccount;
export const updateLinkedAccountsSelector = (state: {
  connectedAccount: { updateLinkedAccount: Type };
}) => state.connectedAccount.updateLinkedAccount;

export const { clearStatus } = connectedAccountSlice.actions;
export default connectedAccountSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, apiEndPoints, } from 'utils/helper/config';

/* Client 2Fa verification  */

const enableClientTwoFaApi = createAsyncThunk(
  'twoFA/enableClientTwoFaApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.enableTwoFA);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const verifyClientTwoFaApi=createAsyncThunk(
    'twoFA/verifyClientTwoFaApi',
    async ({otp}:{otp:any}, { rejectWithValue, fulfillWithValue }) => {
      try {
        const response = await apiClient.post(apiEndPoints.verifyTwoFA+'?appSecret='+otp);
        const { data } = response;
        return fulfillWithValue(data);
      } catch (error: any) {
        const { data } = error.response;
        return rejectWithValue(data);
      }
    }
  );
  const disableClientTwoFaApi=createAsyncThunk(
    'twoFA/disableClientTwoFaApi',
    async ({otp}:{otp:any}, { rejectWithValue, fulfillWithValue }) => {
      try {
        const response = await apiClient.post(apiEndPoints.disableTwoFA+'?appSecret='+otp);
        const { data } = response;
        return fulfillWithValue(data);
      } catch (error: any) {
        const { data } = error.response;
        return rejectWithValue(data);
      }
    }
  );
  const twoFaSettingApi=createAsyncThunk(
    'twoFA/twoFaSettingApi',
    async (offerTwoFa:any, { rejectWithValue, fulfillWithValue }) => {
      try {
        const response = await apiClient.post(apiEndPoints.twoFaSetting,offerTwoFa);
        const { data } = response;
        return fulfillWithValue(data);
      } catch (error: any) {
        const { data } = error.response;
        return rejectWithValue(data);
      }
    }
  );
export { enableClientTwoFaApi,verifyClientTwoFaApi,twoFaSettingApi ,disableClientTwoFaApi}
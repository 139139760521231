import React, { useState, useEffect, memo } from 'react';
import { useSpring, animated } from 'react-spring';

const AnimatedCounter = ({ value }) => {
  const [numericValue, setNumericValue] = useState(0);

  useEffect(() => {
    const cleanedValue = parseFloat(String(value).replace(/[$,]/g, ''));
    setNumericValue(isNaN(cleanedValue) ? 0 : cleanedValue);
  }, [value]);

  const { number } = useSpring({
    reset: true,
    from: { number: 0 },
    to: { number: numericValue },
  });

  return (
    <animated.span>
      {number.to((val) => {
        const formattedValue = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(val);

        return formattedValue;
      })}
    </animated.span>
  );
};

export default memo(AnimatedCounter);

import { BrowserRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import Routes from 'routes/user-routes';
import { Toaster } from 'react-hot-toast';

import { PreloaderStateProvider } from 'components/providers/preloader-state-provider';
import { ThemeStateProvider } from 'components/providers/theme-state-provider';
import { useAuthContext } from 'components/providers/auth-state-provider';
import ScrollToTop from 'components/ui/scroll-to-top';

import { useAppDispatch } from 'store/store';

import {
  cryptoPriceApi,
  userProfileApi,
} from 'features/public/actions/homeAction';
import { getLocalStorage, storageKeys } from 'utils/helper/storage';
import { internalWalletBalanceApi } from 'features/app/actions/walletAction';
import { useSelector } from 'react-redux';
import { userProfileSelector } from 'features/public/slices/homeSlice';
import {
  giftCardApi,
  providerDetailApi,
} from 'features/app/actions/checkoutAction';
import useScript from 'hooks/useScript';

// react-phone-input-2 css link
import 'react-phone-input-2/lib/bootstrap.css';

export default function App(): JSX.Element {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthContext();
  const { data: USER_DETAILS } = useSelector(userProfileSelector);
  useEffect(() => {
    if (isAuthenticated) {
      const token = getLocalStorage(storageKeys.jwtToken) || '';
      dispatch(userProfileApi({ token }));
      dispatch(internalWalletBalanceApi());
      dispatch(providerDetailApi());
      dispatch(giftCardApi());
    }
    const fetchCryptoPrice = () => {
      dispatch(cryptoPriceApi()); // Fetch crypto price data
    };

    fetchCryptoPrice(); // Fetch crypto price data on app start

    const interval = setInterval(fetchCryptoPrice, 20000); // Fetch crypto price data at regular 2-minute intervals

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts or the isAuthenticated state changes
    };
  }, [USER_DETAILS?.length, dispatch, isAuthenticated]);

  useScript(
    'https://salesiq.zohopublic.com/widget',
    'siq922339af6988dc8f0a10dc125b8b0a6a502ed69da50b8299772040114141b354'
  );

  return (
    <ThemeStateProvider>
      <PreloaderStateProvider>
        <BrowserRouter>
          <Toaster />
          <ScrollToTop />
          <Routes />
        </BrowserRouter>
      </PreloaderStateProvider>
    </ThemeStateProvider>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { acceptedCardsApi } from "../actions/acceptedCardsAction";


import { Type } from 'utils/interface';

const initialState = {
    acceptedCardsApi: { data: [], error: null, status: 'idle' } as Type,
};

const acceptedCardsSlice = createSlice({
    name: 'acceptedCards',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addMatcher(
                (action) =>
                    [acceptedCardsApi.pending].some((type) => action.type.endsWith(type)),
                (state, { type }) => {
                    const apiName = type.split('/')[1];
                    state[apiName].status = 'loading';
                    // state[apiName].data = [];
                    state[apiName].error = null;
                }
            )
            .addMatcher(
                (action) =>
                    [acceptedCardsApi.fulfilled,].some((type) => action.type.endsWith(type)),
                (state, { type, payload }) => {
                    const apiName = type.split('/')[1];
                    state[apiName].status = 'success';
                    state[apiName].data = payload;
                    state[apiName].error = null;
                }
            )
            .addMatcher(
                (action) =>
                    [acceptedCardsApi.rejected,].some((type) => action.type.endsWith(type)),
                (state, { type, payload }) => {
                    const apiName = type.split('/')[1];
                    state[apiName].error = payload;
                    state[apiName].status = 'error';
                    state[apiName].data = [];
                }
            );
    },
});

export const acceptedCardsSelector = (state: {
    acceptedCars: { acceptedCardsApi: Type };
}) => state.acceptedCars.acceptedCardsApi;

export default acceptedCardsSlice.reducer




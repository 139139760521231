import { createSlice } from "@reduxjs/toolkit";
import { createUserApi, forgotPasswordApi, loginApi, newSignUpApi, resetPasswordApi, signUpApi, verifyEmailApi } from "../actions/authAction";

interface Auths {
    status: 'idle' | 'error' | 'loading' | 'success';
    data: any;
    error:any
}
const initialState={
    signupApi:{data:[] ,status:'idle',error:null} as Auths,
    loginApi :{data:[] ,status:'idle',error:null} as Auths ,
    forgotPasswordApi :{data:[] ,status:'idle',error:null} as Auths,
    resetPasswordApi :{data:[] ,status:'idle',error:null} as Auths,
    newSignUpApi:{data:[] ,status:'idle',error:null}as Auths,
    verifyEmailApi:{data:[] ,status:'idle',error:null}as Auths,
    createUserApi:{data:[] ,status:'idle',error:null}as Auths
}

export interface Auth {
    status: 'idle' | 'error' | 'loading' | 'success';
    data: any;
    signUpData: any
    loginData: any
    isLoginError: object | any;
    isSignUpError: object | any;
    isForgotPassError: object | any;
    isResetPasswordError: object | any;
}




const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearAuthState: (state) => {
            const keys = Object.keys(state);
            for (let index = 0; index < keys.length; index++) {
              const api = state[keys[index]];
              if (api) {
                api.status = 'idle';
                api.error = null;
              }
            }
          },
    },
    extraReducers(builder) {
        builder.addMatcher(action => [signUpApi.pending, loginApi.pending, forgotPasswordApi.pending, resetPasswordApi.pending,newSignUpApi.pending,verifyEmailApi.pending,createUserApi.pending].some(type => action.type.endsWith(type)), (state ,{type}) => {
            const apiName = type.split('/')[1];
            state[apiName].status = 'loading';
            state[apiName].data = [];
            state[apiName].error = null;
        })
            .addMatcher(action => [signUpApi.fulfilled, loginApi.fulfilled, forgotPasswordApi.fulfilled, resetPasswordApi.fulfilled,newSignUpApi.fulfilled,verifyEmailApi.fulfilled,createUserApi.fulfilled].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].status = 'success';
                state[apiName].data = payload;
                state[apiName].error = null
            })
            .addMatcher(action => [signUpApi.rejected, loginApi.rejected, forgotPasswordApi.rejected, resetPasswordApi.rejected,newSignUpApi.rejected,verifyEmailApi.rejected,createUserApi.rejected].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].error = payload;
                state[apiName].status = 'error';
                state[apiName].data = [];
            });
    },
});

export const signUpSelector=(state:{auth:{signupApi:Auths}})=>state.auth.signupApi
export const signInSelector=(state:{auth:{loginApi:Auths}})=>state.auth.loginApi
export const forgotPasswordSelector=(state:{auth:{forgotPasswordApi:Auths}})=>state.auth.forgotPasswordApi
export const resetPasswordSelector=(state:{auth:{resetPasswordApi:Auths}})=>state.auth.resetPasswordApi
export const newSignUpSelector=(state:{auth:{newSignUpApi:Auths}})=>state.auth.newSignUpApi
export const verifyEmailOtpSelector=(state:{auth:{verifyEmailApi:Auths}})=>state.auth.verifyEmailApi
export const createUserSelector=(state:{auth:{createUserApi:Auths}})=>state.auth.createUserApi

export const { clearAuthState } = authSlice.actions;
export default authSlice.reducer;
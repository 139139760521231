import { createSlice } from '@reduxjs/toolkit';
import { cryptoCardApi } from '../../app/actions/checkoutAction';
import {
  contactUsApi,
  cryptoPriceApi,
  userProfileApi,
} from '../actions/homeAction';
import { Type } from 'utils/interface';

const initialState = {
  cryptoPriceApi: { data: [], error: null, status: 'idle' } as Type,
  userProfileApi: { data: {}, error: null, status: 'idle' } as Type,
  showWalletAmount: true as boolean,
  contactUsApi: { data: {}, error: null, status: 'idle' } as Type,
};

const homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  reducers: {
    updateProfileDetail: (state, { payload }) => {
      let newData = { ...state['userProfileApi'].data };
      // eslint-disable-next-line array-callback-return
      Object.keys(payload).map((key) => {
        newData = { ...newData, [key]: payload[key] };
      });
      state['userProfileApi'].data = newData;
    },
    setNavWalletAmount: (state, action) => {
      state.showWalletAmount = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        (action) =>
          [
            cryptoPriceApi.pending,
            userProfileApi.pending,
            contactUsApi.pending,
          ].some((type) => action.type.endsWith(type)),
        (state, { type }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'loading';
          // state[apiName].data = [];
          state[apiName].error = null;
        }
      )
      .addMatcher(
        (action) =>
          [
            cryptoPriceApi.fulfilled,
            userProfileApi.fulfilled,
            contactUsApi.fulfilled,
          ].some((type) => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'success';
          state[apiName].data = payload;
          state[apiName].error = null;
        }
      )
      .addMatcher(
        (action) =>
          [
            cryptoPriceApi.rejected,
            cryptoCardApi.rejected,
            userProfileApi.rejected,
            contactUsApi.rejected,
          ].some((type) => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].error = payload;
          state[apiName].status = 'error';
          state[apiName].data = [];
        }
      );
  },
});
export const cryptoPriceSelector = (state: {
  home: { cryptoPriceApi: Type };
}) => state.home.cryptoPriceApi;
export const userProfileSelector = (state: {
  home: { userProfileApi: Type };
}) => state.home.userProfileApi;
export const contactUsSelector = (state: { home: { contactUsApi: Type } }) =>
  state.home.contactUsApi;
export const showAmountSelector = (state) => state.home.showWalletAmount;
export const { updateProfileDetail, setNavWalletAmount } = homeSlice.actions;
export default homeSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, apiEndPoints } from 'utils/helper/config';

const internalWalletBalanceApi = createAsyncThunk(
  'wallet/internalWalletBalanceApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(
        `${apiEndPoints.internalWalletBalance}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const pendingWalletBalanceApi = createAsyncThunk(
  'wallet/pendingWalletBalanceApi',
  async (userId: { userId: string }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(
        `${apiEndPoints.pendingWalletBalance}/${userId}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const walletTransactionApi = createAsyncThunk(
  'wallet/walletTransactionApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(
        `${apiEndPoints.walletTransaction}?&status=${'CONFIRMED'}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const internalBalanceTransferApi = createAsyncThunk(
  'wallet/internalBalanceTransferApi',
  async (
    { payload }: { payload: any },
    { dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${apiEndPoints.internalWalletTransfer}`,
        payload
      );
      const { data } = response;
      if (data) {
        await dispatch(internalWalletBalanceApi());
      }
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const externalWithdrawalApi = createAsyncThunk(
  'wallet/externalWithdrawalApi',
  async (
    { payload }: { payload: any },
    { dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${apiEndPoints.externalWithdrawal}/${payload?.assetType}`,
        payload
      );
      const { data } = response;
      if (data) {
        await dispatch(internalWalletBalanceApi());
      }
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const getWalletAddressApi = createAsyncThunk(
  'wallet/getWalletAddressApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(`${apiEndPoints.getWalletAddress}`);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
export {
  internalWalletBalanceApi,
  pendingWalletBalanceApi,
  walletTransactionApi,
  internalBalanceTransferApi,
  externalWithdrawalApi,
  getWalletAddressApi,
};

import { useState, useEffect } from 'react';

const useScript = (url, widgetCode) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'zsiqscript');
    script.defer = true;
    script.src = url;
    document.body.appendChild(script);

    const widgetCodeSnippet = `
      var $zoho=$zoho || {};
      $zoho.salesiq = $zoho.salesiq || {
        widgetcode: "${widgetCode}",
        values:{},
        ready:function(){}
      };
    `;

    // const widgetElementSnippet = `
    //   <div id='zsiqwidget'></div>
    // `;

    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.innerHTML = widgetCodeSnippet;
    document.body.appendChild(scriptElement);

    const widgetElement = document.createElement('div');
    widgetElement.id = 'zsiqwidget';
    document.body.appendChild(widgetElement);

    return () => {
      const scriptElement = document.getElementById('zsiqscript');
      if (scriptElement) {
        scriptElement.remove();
      }
      const widgetElement = document.getElementById('zsiqwidget');
      if (widgetElement) {
        widgetElement.remove();
      }
    };
  }, [url, widgetCode]);

  const showchat = () => {
    const floatElement = document.getElementById('zsiq_float');
    const agtpicElement = document.getElementById('zsiq_agtpic');

    if (floatElement && agtpicElement) {
      floatElement.classList.remove('d-none');
      agtpicElement.click();
    }
  };

  const handleButtonClick = () => {
    setIsChatOpen(true);
  };

  return { handleButtonClick, showchat, isChatOpen };
};

export default useScript;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, apiEndPoints } from 'utils/helper/config';

const getAllLinkedAccount = createAsyncThunk(
  'connectedAccount/getAllLinkedAccount',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await apiClient.get(apiEndPoints.getAllLinkedAccount);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const addNewLinkedAccount = createAsyncThunk(
  'connectedAccount/addNewLinkedAccount',
  async (
    { payload }: { payload: any },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        apiEndPoints.LinkNewAccounts,
        payload
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const updateLinkedAccount = createAsyncThunk(
  'connectedAccount/updateLinkedAccount',
  async (
    { id, proxies }: { id: string; proxies: any },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await apiClient.put(
        apiEndPoints.updateLinkedAccount + `?id=${id}`,
        proxies
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const deleteLinkedAccount = createAsyncThunk(
  'connectedAccount/deleteLinkedAccount',
  async ({ id }: { id: string }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        apiEndPoints.deleteLinkedAccount + `?id=${id}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

export {
  getAllLinkedAccount,
  addNewLinkedAccount,
  updateLinkedAccount,
  deleteLinkedAccount,
};

import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import handWalletVector from 'assets/vectors/hand-wallet.svg';
import handCardVector from 'assets/vectors/hand-card.svg';
import GuestCheckout from 'components/user/app/checkout';
import CheckoutCard from 'components/ui/checkout-card';
import Heading from 'components/ui/heading';
import Button from 'components/ui/button';
import Page from 'components/ui/page';
import Accordion from 'components/ui/accordion';
import PaymentMethod from 'assets/vectors/PaymentMethods.svg';
import CardDetail from 'assets/vectors/CardDetail.svg';
import GiftCardPhotos from 'assets/vectors/GiftCardPhotos.png';
import InstantlyLiquidate from 'assets/vectors/InstantlyLiquidate.svg';
import Card from 'components/ui/card';
import Bitcoin from 'assets/vectors/Bitcoin.svg';
import Tether from 'assets/vectors/Tether.svg';
import Usdcoin from 'assets/vectors/Usdcoin.svg';
/*import DailyVolumeCard from 'components/ui/daily-volume-card';
import VectorIcon from 'assets/vectors/Vector.svg';
import globeLocationVector from 'assets/vectors/globe-location.svg';
import DailyTrade from 'assets/vectors/DailyTrade.svg';*/
import Newsletter from 'components/user/public/news-letter';
import AnimatedCounter from 'components/ui/AnimatedCounter';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import { useAuthContext } from 'components/providers/auth-state-provider';
import { useSelector } from 'react-redux';
import { cryptoPriceSelector } from 'features/public/slices/homeSlice';
import { cutZeros } from 'utils/constants';

export interface HomeProps {
  status: 'idle' | 'error' | 'loading' | 'success';
  cryptoPrice: any;
}
export const faqs = [
  {
    title: 'Do I need an account to sell my gift cards?',
    content:
      "No, you don't! You can check out directly from our widget here, just confirm your SMS/WhatsApp number. However, members enjoy exclusive benefits such as higher rates, affiliate perks, and more!",
  },
  {
    title: 'Which cryptocurrencies can I receive?',
    content:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    title: 'Is my transaction secure?',
    content:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    title: 'How do I liquidate my gift cards?',
    content:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];
export default function Home(): JSX.Element {
  const handCardControls = useAnimation();
  const handWalletControls = useAnimation();
  const { data: CRYPTO_PRICE } = useSelector(cryptoPriceSelector);
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    async function handCardAnimate() {
      await handCardControls.start({
        x: ['0%', '-5%', '-5%', '0%'],
        y: ['0%', '-10%', '-10%', '0%'],
        rotate: [0, -5, -5, 0],
        transition: { duration: 1, times: [0, 0.4, 0.6, 0.8] },
      });
    }

    async function handWalletAnimate() {
      await handWalletControls.start({
        x: ['0%', '5%', '5%', '0%'],
        y: ['0%', '10%', '10%', '0%'],
        rotate: [0, -5, -5, 0],
        transition: { duration: 0.8, times: [0, 0.4, 0.6, 0.8] },
      });
    }

    const intervalId = setInterval(() => {
      handCardAnimate();
      const timeoutId: any = setTimeout(handWalletAnimate, 900);
      localStorage.setItem('hand-wallet-timeout', timeoutId);
    }, 6000);

    return () => {
      const timeout: any = localStorage.getItem('hand-wallet-timeout');
      clearInterval(intervalId);
      clearTimeout(timeout);
    };
  });

  const BTC: any = CRYPTO_PRICE?.filter(
    (crypto: any) => crypto.symbol === 'BTC'
  )[0];
  const USDT = CRYPTO_PRICE?.filter(
    (crypto: any) => crypto.symbol === 'USDT'
  )[0];
  const USDC = CRYPTO_PRICE?.filter(
    (crypto: any) => crypto.symbol === 'USDC'
  )[0];

  return (
    <Page>
      <div className='mx-auto flex flex-col gap-[60px]'>
        <div className='cardGraph m-auto w-full max-w-[1120px] px-[16px] md:px-[0] lg:px-[0]'>
          <div className='m-auto flex w-full max-w-6xl flex-wrap justify-center gap-[15px] px-2 sm:flex md:flex-nowrap  md:gap-[30px] md:px-8 lg:flex-nowrap lg:gap-[30px] lg:px-8 '>
            <>
              <Card
                icon={Bitcoin}
                title='Bitcoin'
                subTitle='(BTC)'
                percentage={BTC?.percentageChange}
                currency={
                  USDC?.price ? (
                    <>
                      $<AnimatedCounter value={cutZeros(BTC?.price)} />
                    </>
                  ) : (
                    <div className='w-[70%]'>
                      <Skeleton />
                    </div>
                  )
                }
              />
              <Card
                icon={Tether}
                title='Tether USDT'
                subTitle='(USDT)'
                percentage={USDT?.percentageChange}
                currency={
                  USDT?.price ? (
                    <>
                      $<AnimatedCounter value={cutZeros(USDT?.price)} />
                    </>
                  ) : (
                    <div className='w-[70%]'>
                      <Skeleton />
                    </div>
                  )
                }
              />
              <Card
                icon={Usdcoin}
                title='USD Coin'
                subTitle='(USDC)'
                percentage={USDC?.percentageChange}
                currency={
                  USDC?.price ? (
                    <>
                      $<AnimatedCounter value={cutZeros(USDC?.price)} />
                    </>
                  ) : (
                    <div className='w-[70%]'>
                      <Skeleton />
                    </div>
                  )
                }
              />
            </>
          </div>
        </div>
        <div className='flex flex-col gap-[60px]'>
          <div className='flex flex-col items-center gap-[30px] px-4 text-center sm:px-6 lg:px-8'>
            <Heading
              size='h2'
              className=' !lg:leading-[66px]  max-w-lg text-[30px] leading-[48px] text-[#181818] sm:text-[24px] md:text-3xl md:text-[30px] lg:max-w-2xl lg:text-[40px]  xl:text-[40px] '
            >
              Get Instant Crypto for Your Gift Cards
            </Heading>
            <p className='w-[100%] max-w-xs text-xl font-medium !leading-[32px] text-[#4E4E4E] md:max-w-[80%] md:text-base lg:max-w-[40%] lg:text-[24px]'>
              Unlock the highest offers for your gift cards with our tailored
              matching system, ensuring you get the best possible rates.
            </p>
          </div>
          <div className='flex items-center justify-between lg:gap-8'>
            <div className='hidden flex-1 justify-start md:flex'>
              <motion.img
                className='w-full max-w-md cursor-pointer'
                src={handCardVector}
                animate={handCardControls}
                initial={{ x: '-20%', y: '-100%', rotate: -100 }}
                whileHover={{ x: '-10%', y: '10%', rotate: 10 }}
                whileTap={{ x: '-10%', y: '-20%', rotate: -10 }}
                whileInView={{
                  x: 0,
                  y: 0,
                  rotate: 0,
                  transition: { delay: 0.25 },
                }}
              />
            </div>
            <CheckoutCard>
              <GuestCheckout />
            </CheckoutCard>
            <div className='hidden flex-1 justify-end md:flex'>
              <motion.img
                className='w-full max-w-md cursor-pointer'
                src={handWalletVector}
                animate={handWalletControls}
                initial={{ x: '20%', y: '100%', rotate: -125 }}
                whileHover={{ x: '10%', y: '10%', rotate: -10 }}
                whileTap={{ x: '10%', y: '-20%', rotate: 10 }}
                whileInView={{
                  x: 0,
                  y: 0,
                  rotate: 0,
                  transition: { delay: 0.5 },
                }}
              />
            </div>
          </div>
        </div>
        <motion.div
          initial={{ rotate: 0, scale: 0.9 }}
          whileInView={{
            rotate: 0,
            scale: 1,
            transition: { delay: 0.4, type: 'bounce', stiffness: 500 },
          }}
          className='mt-[40px] flex flex-col items-center gap-0  px-4 text-center sm:px-6 md:mt-[70px] lg:mt-[120px] lg:gap-0  lg:px-8'
        >
          <Heading
            size='h2'
            className='mb-[30px] mt-[0px] max-w-lg lg:max-w-2xl'
          >
            How It Works
          </Heading>
          <div className='mb-[60px] w-full max-w-[373px]'>
            <p className='text-center text-xl font-medium not-italic leading-6 text-[#4E4E4E]'>
              Our platform is optimized to exchange your gift cards as easy as
              1,2,3,4.
            </p>
          </div>
          <motion.div className='stepesBlock grid w-[100%] flex-wrap   sm:flex lg:max-w-[1080px] '>
            {_.map(
              [PaymentMethod, CardDetail, GiftCardPhotos, InstantlyLiquidate],
              (i, idx) => (
                <motion.div
                  key={idx}
                  className='imagesStep one shrink-0 grow-0 basis-1/2'
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ delay: 0.1 + 0.2 * idx }}
                >
                  <img
                    loading='lazy'
                    src={i}
                    alt='stepImg'
                    className='w-full'
                  />
                </motion.div>
              )
            )}
          </motion.div>
        </motion.div>

        {/* <motion.div
          initial={{ rotate: 0, scale: 0.9 }}
          whileInView={{
            rotate: 0,
            scale: 1,
            transition: { delay: 0.4, type: 'bounce', stiffness: 500 },
          }}
          className='mt-[30px] flex flex-col items-center gap-3 px-[20px] md:mt-[80px] lg:mt-[120px] '
        >
          <Heading
            size='h4'
            className='mt-[10px] max-w-lg pb-5 text-center  text-[36px] font-bold  not-italic !leading-[50px] text-black lg:max-w-2xl lg:text-[50px]'
          >
            Daily Trade Volume
          </Heading>
          <p className=' mb-20px m-auto w-[100%] break-normal  text-center font-sans text-[20px] font-medium text-[#4E4E4E] sm:w-[100%] md:mb-[30px] lg:mb-[50px] lg:w-[660px]'>
            Get a live view of the amount of trades we have completed and the
            transactional value of all of our trades over the last few months.
          </p>
          <motion.div className='m-auto flex  w-full flex-wrap sm:w-[100%]  sm:px-[20px] lg:max-w-[1080px]'>
            <DailyVolumeCard
              icon={DailyTrade}
              title='Daily Trade Volume'
              currency='$1,728,220'
              percentage='10%'
              vector={VectorIcon}
            />
          </motion.div>
        </motion.div> */}

        <div className='flex flex-col items-center gap-5 px-[30px] text-center sm:mt-[30px] sm:px-6 md:mt-[50px] lg:mt-[70px] lg:gap-20 lg:px-8'>
          {/* <div className='flex flex-col items-center gap-[30px]'>
            <Heading size='h2' className='max-w-lg lg:max-w-2xl lg:text-[50px]'>
              80,000+ Locations
            </Heading>
            <p className='max-w-[390px] text-[20px]  font-medium text-[#4E4E4E]'>
              Buy a gift card from any of the 80,000+ different locations spread
              across the US.
            </p>
          </div>
          <motion.img
            initial={{ rotate: 50, scale: 0.8 }}
            whileInView={{
              rotate: 0,
              scale: 1,
              transition: { delay: 0.4, type: 'spring', stiffness: 500 },
            }}
            whileTap={{ rotate: 10, scale: 0.8 }}
            className='cursor-pointer'
            src={globeLocationVector}
            alt='globeLocationVector'
          />
          <Button
            type='submit'
            className='max-w-fit cursor-pointer   !rounded-full px-12 py-4 !text-[18px] '
          >
            Find A Location Near You
          </Button> */}
          <div>
            <div>
              <Heading
                size='h2'
                className='lg::text-[32px] mt-[0px] max-w-lg text-[26px]  sm:mt-[10px] md:mt-[0px] md:text-[30px] lg:mt-[0px] lg:max-w-2xl xl:text-[36px]'
              >
                Frequently Asked Questions
              </Heading>
              <div className=''>
                <p className='m-auto mt-[30px]  max-w-[390px] text-[20px] font-medium text-[#4E4E4E]'>
                  Browse through a few frequently asked questions for in depth
                  answers.
                </p>
              </div>
            </div>
          </div>
          <div className=' w-[100%] max-w-[1080px]'>
            {_.map(faqs, ({ title, content }, i) => (
              <Accordion
                key={i}
                title={title}
                content={content}
                showContent={i === 0}
              />
            ))}
            <div className='md-[30px] mt-[60px] flex  justify-center  sm:mb-[40px] md:mb-[60px] lg:mb-[120px]'>
              <Button
                type='submit'
                className=' !w-[260px]   cursor-pointer !rounded-full  px-12 py-4 !text-[18px] '
              >
                View All FAQ’s
              </Button>
            </div>
          </div>
          {!isAuthenticated && <Newsletter />}
        </div>
      </div>
    </Page>
  );
}

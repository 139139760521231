import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, apiEndPoints, } from 'utils/helper/config';

const payload = {
  fullName: '',
  email: '',
  country: '',
  ResidencyProof: ''
}

const createVendorApi = createAsyncThunk(
  'vendor/createVendorApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.createVendor, payload,
        { headers: { 'content-type': 'multipart/form-data' } }

      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
export { createVendorApi }
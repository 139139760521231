import React, { Children, Fragment, cloneElement, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';

export interface ModalProps {
  open?: boolean;
  setOpen: Function;
  children?: React.ReactNode;
  containerClassName?: string;
  title?: string;
  cancel?: boolean;
  DialogPanelClass?: string;
}
export default function Modal({
  open,
  setOpen,
  children,
  containerClassName,
  title,
  cancel,
  DialogPanelClass,
}: ModalProps): JSX.Element {
  const cancelButtonRef = useRef(null);

  const modifiedChildren = Children?.map(children, (child) => {
    if (React.isValidElement(child)) {
      const addClassProps = {
        style: { fontWeight: 400, fontSize: '1rem' },
      };

      return cloneElement(child, addClassProps);
    }

    return child;
  });
  return (
    <Transition.Root show={open || false} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-[100]'
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div
          onClick={() => setOpen()}
          className='fixed inset-0 z-[100] w-screen overflow-y-auto'
        >
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className={cn(
                  'relative w-full max-w-[100%] transform overflow-hidden rounded-[25px] bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-[500px] lg:max-w-[500px]',
                  DialogPanelClass
                )}
              >
                <div
                  onChange={(e) => e.stopPropagation()}
                  className={cn(
                    'bg-white px-4 py-[30px]   sm:p-6 sm:pb-4 ',
                    containerClassName
                  )}
                >
                  <div className=' mb-6 flex justify-between'>
                    <p className='text-xl font-bold not-italic leading-5 text-black'>
                      {title}
                    </p>
                    {cancel && (
                      <span className=' flex h-8 w-8 cursor-pointer  items-center justify-center rounded-md '>
                        <svg
                          onClick={() => setOpen(false)}
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='1.5'
                          stroke='currentColor'
                          className='h-6 w-6 '
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                      </span>
                    )}
                  </div>

                  {modifiedChildren}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React, { useRef, useState, useEffect, memo, useCallback } from 'react';
import Arrow from 'assets/images/arrow-down.png';
// import BitCoin from 'assets/vectors/Bitcoin.svg';
import SearchIcon from 'assets/icons/searchicon.svg';
import CrossIcon from 'assets/icons/crossicon.svg';
import searchDropIcon from 'assets/icons/search.icon.svg';
import QuestioMark from 'assets/icons/questionmark.svg';

import { motion } from 'framer-motion';

export interface SearchableDropdownProps {
  options?: any;
  onSelect: Function;
  startIcon?: string;
  placeHolder?: string;
  value?: string;
  label?: string;
  searchIcon?: boolean;
  isOpen?: any;
  onOpen?: any;
  id?: any;
}

const SearchableDropdown = memo(
  ({
    options,
    onSelect,
    startIcon,
    placeHolder,
    value,
    label,
    isOpen,
    onOpen,
    id,
    searchIcon = true,
  }: SearchableDropdownProps) => {
    const inputSearch = useRef<any>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [icon, setIcon] = useState<string>(QuestioMark);
    const [search, setSearch] = useState<string>('');
    const filteredOptions = options?.filter((option) => {
      const name = label ? option[label] : option?.name;
      return name?.toLowerCase().includes(search.toLowerCase());
    });

    const handleSelect = useCallback(
      (value, icon, option) => {
        setSearch('');
        setSearchTerm(label ? option[label] : value);
        onSelect(option);
        onOpen(-1);
        setIcon(icon);
      },
      [label, onSelect, onOpen]
    );

    useEffect(() => {
      if (startIcon) {
        setIcon(startIcon);
      }
      if (value) {
        setSearchTerm(value);
      }
      const handleClickOutside = (event: any) => {
        event.stopPropagation();
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          if (!event.target.closest('.relative')) {
            onOpen(-1); // Pass -1 to indicate closing the dropdown at the specified index
          }
        }
      };

      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [onOpen, value,startIcon]);

    const variants = {
      visible: { opacity: 1 },
      hidden: { opacity: 0 },
    };
    const handleSearchValue = () => {
      setSearchTerm('');
      onSelect('');
    };

    return (
      <div className='relative ' ref={dropdownRef}>
        <div className={` flex ${isOpen && ''}`}>
          <div className='position-relative me-2 w-full overflow-hidden  rounded-xl border-2  border-solid border-[#F1F1F1] '>
            <img
              alt=''
              src={icon}
              className='absolute top-4 z-10 w-[50px] pl-4 '
            />
            <input
              autoComplete='false'
              ref={inputSearch}
              type='text'
              className='placeholder:text-black-bold relative  h-12 w-[95%] cursor-pointer  px-4   py-2  pl-14 text-xs font-medium not-italic leading-4 text-[#515151] focus:outline-none lg:text-base  '
              placeholder={placeHolder}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setSearch(e.target.value);
              }}
              onClick={() => onOpen(id)}
            />
            <span className='absolute  right-[66px] top-6 z-[9] lg:right-20'>
              <img
                onClick={() => onOpen(id)}
                src={Arrow}
                alt='arrow-down'
                className=' cursor-pointer'
              />
            </span>
          </div>
          {searchIcon && (
            <img
              className=' cursor-pointer'
              src={searchDropIcon}
              alt='search-icon'
              onClick={() => { inputSearch.current.focus(); onOpen(id) }}
            />
          )}
        </div>
        {isOpen && (
          <div className={`absolute top-[0px] z-20 mt-0 w-full overflow-hidden rounded-xl border-2 border-solid ${filteredOptions?.length?' border-blue-500 ':' border-[#E30000]'} bg-white  pb-[17px] shadow-lg`}>
            <div className='flex'>
              <div className=' position-relative   w-full overflow-hidden '>
                {searchTerm === '' ? (
                  <>
                    <img
                      alt=''
                      src={SearchIcon}
                      className='absolute top-4 z-10 pl-4 '
                    />
                  </>
                ) : (
                  <>
                    <img
                      alt=''
                      src={searchTerm === '' ? startIcon : icon}
                      className='absolute top-4 z-10 pl-4 '
                    />
                  </>
                )}
                <input
                  autoComplete='false'
                  type='text'
                  className='placeholder:text-black-bold relative h-[50px] w-[95%]  cursor-pointer  px-2  py-2  pl-14 text-base font-medium not-italic leading-4 text-[#515151] focus:outline-none '
                  placeholder={placeHolder}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setSearch(e.target.value);
                  }}
                  onClick={() => onOpen(id)}
                />

                {searchTerm !== '' ? (
                  <span
                    className='absolute  right-5 top-[18px] z-[9]'
                    onClick={handleSearchValue}
                  >
                    {' '}
                    <img
                      className=' cursor-pointer'
                      onClick={() => onOpen(id)}
                      src={CrossIcon}
                      alt='arrow-down'
                    />
                  </span>
                ) : (
                  <span className='absolute  right-5 top-6 z-[9]'>
                    {' '}
                    <img
                      className={`${isOpen && 'rotate-180 '}cursor-pointer`}
                      onClick={() => onOpen(-1)}
                      src={Arrow}
                      alt='arrow-down'
                    />
                  </span>
                )}
              </div>
            </div>
            <motion.div
              initial='hidden'
              animate='visible'
              variants={variants}
              className='scrollbar  max-h-[204px] max-w-[95%] overflow-y-scroll '
            >
              {filteredOptions?.length > 0 ? (
                filteredOptions?.map((option: any, index: number) => (
                  <div
                    key={index}
                    className={`${searchTerm === option.name
                      ? 'bg-gray-200 '
                      : ' hover:bg-gray-100 '
                      }px-4 cursor-pointer  py-2`}
                    onClick={() =>
                      handleSelect(option.name, option.icon || '', option)
                    }
                  >
                    <span className='flex gap-2 font-bold  text-black '>
                      {option.icon && (
                        <img alt='icons' src={option.icon || ''} />
                      )}{' '}
                      {label ? option[label] : option.name}
                    </span>
                  </div>
                ))
              ) : (
                <span className=' pl-5 text-[#860000] text-base not-italic font-bold leading-4'>No results - try something else</span>
              )}
            </motion.div>
          </div>
        )}
      </div>
    );
  }
);

export default SearchableDropdown;

import { Link } from 'react-router-dom';
import React, { useState } from 'react';

import { useAuthContext } from 'components/providers/auth-state-provider';
import Button from 'components/ui/button';
import Logo from 'components/ui/logo';
import Page from 'components/ui/page';
import Error from 'components/ui/error';

import { apiClient, apiEndPoints } from 'utils/helper/config';
import { saveLogin, setLocalStorage, storageKeys } from 'utils/helper/storage';
import PinInput from 'components/ui/pin-input';
import { useSignal } from '@preact/signals-react';

export default function TwoFactorAuth() {
  const authCode = useSignal<string>('');
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const { setAuthentication } = useAuthContext();

  const handleAuthCodeChange = (value) => {
    authCode.value = value;
  };

  const handleSubmit = async () => {
    if (authCode.value.length) {
      setError(false);
      setLoading(true);
      const response = await apiClient.post(
        `${apiEndPoints.verifyTwoFA}?appSecret=${authCode.value}`
      );
      setLoading(false);
      const { data } = response;
      if (data.message?.valid) {
        const { token } = data.message;
        setAuthentication(token);
        setLocalStorage(storageKeys.jwtToken, token);
        saveLogin(token);
      } else {
        setError(true);
      }
    }
  };

  return (
    <Page title='Login'>
      <div className='flex flex-col items-center justify-center gap-8'>
        <Link to='/'>
          <Logo className='h-16' />
        </Link>
        <div className='flex flex-col gap-2 px-4 text-center sm:px-6 lg:px-8'>
          <h4 className='text-[30px]  font-bold  lg:text-[35px] xl:text-[40px] '>
            Two Factor Authentication
          </h4>
        </div>
        {isError ? <Error message={'Invalid code'} /> : ''}
        <div className='m-auto w-full max-w-[540px] px-[20px]'>
          <PinInput
            length={6}
            onPinChange={handleAuthCodeChange}
            onComplete={(value, index) => {
              authCode.value = value;
            }}
          />
          <Button
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
            type='button'
            className='my-[40px] h-[50px] rounded-xl py-2 text-xl'
          >
            Continue
          </Button>
        </div>
      </div>
    </Page>
  );
}

import { memo, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';

import { Popover } from '@headlessui/react';

import Button from 'components/ui/button';
import Logo from 'components/ui/logo';
import Menu from 'components/ui/menu';

import cn from 'classnames';
import { useAuthContext } from 'components/providers/auth-state-provider';
import MenuDropdown from 'components/ui/menu-dropdown';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { internalWalletBalanceSelector } from 'features/app/slices/walletSlice';
import { cutZeros } from 'utils/constants';
import {
  cryptoPriceSelector,
  setNavWalletAmount,
  showAmountSelector,
} from 'features/public/slices/homeSlice';
import { useAppDispatch } from 'store/store';
import AnimatedCounter from 'components/ui/AnimatedCounter';

/*-----  Deprecate for now due to opacity transition bug on Framer-motion  -----*/
const variants = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

interface navItem {
  name: string;
  to: string;
}

export interface PublicNavProps {
  navigations: navItem[];
  fixedPath?: string[];
  props?: any;
}

export default memo(function PublicNav({
  navigations,
  fixedPath = [],
}: PublicNavProps): JSX.Element {
  const navWalletAmount = useSelector(showAmountSelector);
  const dispatch = useAppDispatch();
  const closeBtnRef: any = useRef();
  const { pathname } = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  const [isValidPath] = useState(true);
  const { isAuthenticated } = useAuthContext();
  const { data: CRYPTO_PRICE } = useSelector(cryptoPriceSelector);
  const BTC: any = CRYPTO_PRICE?.filter(
    (crypto: any) => crypto.symbol === 'BTC'
  )[0];
  // const [navWalletAmount, setNavWalletAmount] = useState<boolean>(false);
  const { data: INTERNAL_WALLET_BALANCE } = useSelector(
    internalWalletBalanceSelector
  );

  const { data: walletBalance } = INTERNAL_WALLET_BALANCE;
  const { available: availableBalance } = walletBalance ?? {};

  useEffect(() => {
    closeBtnRef.current?.click();
    setIsFixed(fixedPath.some((path) => path === pathname));
  }, [fixedPath, pathname, navigations]);

  const Auths = [
    {
      to: '/auth/login',
      name: 'Login',
    },
    {
      to: '/auth/signup',
      name: 'Signup',
    },
  ];
  return (
    <div
      className={cn('z-20 w-full  pb-6 pt-6 ', isFixed ? 'fixed' : 'relative')}
    >
      <AnimatePresence mode='wait'>
        <motion.div
          key={isValidPath ? 'active' : ''}
          initial='initial'
          animate='animate'
          exit='exit'
          variants={variants}
          transition={{ duration: 0.2, type: 'easeInOut' }}
        >
          <Popover style={{ visibility: isValidPath ? 'visible' : 'hidden' }}>
            <div className="'md-max-w-[100%]' mx-auto max-w-[1120px] px-4 sm:px-6 sm:pt-[0px] md:pt-0 lg:px-[18px]">
              <nav
                className='md:pl[0px] relative flex items-center justify-between sm:h-10 lg:justify-center lg:pl-[0px] '
                aria-label='Global'
              >
                <div className='flex w-[200px] flex-1 items-center lg:absolute lg:inset-y-0  lg:left-0 xl:w-[250px]  '>
                  <div className='flex w-full items-center  justify-start gap-[0] sm:justify-start sm:gap-[20px]  md:w-[100%]  md:justify-start   md:gap-[20px] lg:w-auto lg:justify-start lg:gap-[20px]'>
                    <div className='flex items-center gap-1'>
                      <Link to='/'>
                        <Logo
                          headerLogo={true}
                          className='h-[50px] w-[183px] sm:h-[50px] md:h-[50px] md:w-[190px] lg:h-[45px] lg:w-[140px]'
                        />
                      </Link>
                    </div>

                    <div className='relative ml-3 flex w-full max-w-[100%] justify-end gap-3 sm:max-w-[100%]  sm:gap-6 md:max-w-[100%] lg:hidden'>
                      {isAuthenticated && (
                        <div className=' ml-0  flex  w-[100%] max-w-[110px]  cursor-pointer items-center justify-between rounded-lg   border-2 border-solid border-[#F1F1F1] p-[9px] sm:flex md:flex lg:hidden'>
                          <div>
                            <span className='block text-[8px] font-extrabold not-italic leading-[8px] text-[#626262]'>
                              Your Holdings
                            </span>
                            <span className='text-xs font-extrabold not-italic leading-3 text-[#0F0F0F]'>
                              $
                              {navWalletAmount ? (
                                <AnimatedCounter
                                  value={cutZeros(
                                    +availableBalance?.btcBalance * BTC?.price
                                  )}
                                />
                              ) : (
                                '*****'
                              )}
                            </span>
                          </div>
                          {!navWalletAmount ? (
                            <AiOutlineEye
                              onClick={() =>
                                dispatch(setNavWalletAmount(!navWalletAmount))
                              }
                            />
                          ) : (
                            <AiOutlineEyeInvisible
                              onClick={() =>
                                dispatch(setNavWalletAmount(!navWalletAmount))
                              }
                            />
                          )}
                        </div>
                      )}
                      <Menu>
                        {navigations.map(({ to, name }) => (
                          <Menu.Item
                            key={name}
                            to={to}
                            as={Link}
                            active={to === pathname}
                          >
                            {name}
                          </Menu.Item>
                        ))}
                        {isAuthenticated ? (
                          <MenuDropdown />
                        ) : (
                          Auths.map(({ to, name }, index) => (
                            <Menu.Item
                              key={index + ' '}
                              as={Link}
                              to={to}
                              className=' w-[100%] text-left'
                            >
                              {name === 'Signup' ? (
                                <Button className='!justify-start !rounded  '>
                                  {name}
                                </Button>
                              ) : (
                                name
                              )}
                            </Menu.Item>
                          ))
                        )}
                      </Menu>
                    </div>
                  </div>
                </div>

                <div className='relative hidden w-full max-w-[calc(100%_-_400px)] justify-center md:gap-[20px] lg:flex lg:space-x-10'>
                  {navigations.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={cn(
                        '!m-0 font-medium hover:text-gray-900 dark:hover:text-gray-400',
                        pathname === item.to ? 'text-blue-500' : 'text-gray-950'
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>

                <div className='hidden w-[100%] max-w-[270px] gap-3 lg:absolute lg:inset-y-0 lg:right-0 lg:flex lg:items-center lg:justify-end'>
                  {isAuthenticated ? (
                    <>
                      <MenuDropdown />
                    </>
                  ) : (
                    <>
                      <Link
                        to='/auth/login'
                        className='font-bold text-gray-950 hover:underline'
                      >
                        Log in
                      </Link>
                      <Link to='/auth/signup'>
                        <Button className='!rounded-full'>Sign up</Button>
                      </Link>
                    </>
                  )}
                </div>
              </nav>
            </div>
          </Popover>
        </motion.div>
      </AnimatePresence>
    </div>
  );
});

import cn from 'classnames';
import { memo } from 'react';

export default memo(function Checkbox({ className, ...props }: any) {
  return (
    <input
      type='checkbox'
      className={cn(
        'h-6 w-6 rounded-md border-2 !border-gray-100 p-1 text-lg text-white outline-none cursor-pointer  checked:bg-blue-500 hover:bg-gray-200 checked:hover:bg-blue-600 focus:border-black',
        className
      )}
      {...props}
    />
  );
});

import React, { useRef, useState, useEffect, memo } from 'react';
import Arrow from 'assets/images/arrow-down.png';
import SearchIcon from 'assets/icons/searchicon.svg';
import CrossIcon from 'assets/icons/crossicon.svg';
import searchDropIcon from 'assets/icons/search.icon.svg';

import { motion } from 'framer-motion';
import getSymbolFromCurrency from 'currency-symbol-map';
import cn from 'classnames';

export interface CountryDropDownProps {
  options?: any;
  currencyFlag?: boolean;
  onSelect: Function;
  startIcon?: string;
  placeHolder?: string;
  value?: string;
  label?: string;
  searchIcon?: boolean;
  className?: string;
  emptyTheSearch?: boolean;
}

const CountryDropDown = memo(
  ({
    options,
    currencyFlag = true,
    onSelect,
    startIcon,
    placeHolder,
    value,
    label,
    searchIcon = true,
    className,
    emptyTheSearch = false,
  }: CountryDropDownProps) => {
    const inputSearch = useRef<any>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>(value || '');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [icon, setIcon] = useState<any>('');
    const [search, setSearch] = useState<string>('');

    const filteredOptions = options?.filter((option) => {
      const name = label ? option[label] : option?.currency;
      return name?.toLowerCase().includes(search.toLowerCase());
    });


    const handleSelect = (option: any) => {
      setSearch('');
      onSelect(option);
      setIsOpen(false);
      if (emptyTheSearch) {
        setSearchTerm('')
        setIcon('')
      } else {
        setSearchTerm(label ? option[label] : option.currency);
        setIcon(option);
      }
    };

    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      if (!searchTerm) {
        // onSelect('')
        setIcon('');
      }
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [searchTerm, value]);

    const variants = {
      visible: { opacity: 1 },
      hidden: { opacity: 0 },
    };
    const handleSearchValue = () => {
      setSearchTerm('');
      onSelect('');
    };

    return (
      <div className='relative' ref={dropdownRef}>
        <div className='flex'>
          <div
            className={cn(
              'position-relative me-2 w-full overflow-hidden  rounded-xl border-2  border-solid border-[#F1F1F1] ',
              className
            )}
          >
            {icon.flag ? (
              <>
                <div className='absolute top-[5px] z-10 pl-4 text-[28px]'>
                  {icon.flag}
                </div>
              </>
            ) : (
              <>
                <img
                  alt=''
                  src={searchTerm === '' ? startIcon : icon}
                  className='absolute top-4 z-10 pl-4 '
                />
              </>
            )}
            <div className=' relative'>
              <input
                autoComplete='false'
                ref={inputSearch}
                type='text'
                className='placeholder:text-black-bold relative  h-12 w-full cursor-pointer   px-4   py-2   pl-14  text-[12px] font-bold  not-italic leading-4 text-[#202020] focus:outline-none md:text-base lg:text-base '
                placeholder={placeHolder}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setSearch(e.target.value);
                }}
                onClick={() => setIsOpen(true)}
              />
              <span className='absolute  right-[15px] top-[20px]  z-[9] '>
                {' '}
                <img src={Arrow} alt='arrow-down' />
              </span>
            </div>
          </div>
          {searchIcon && (
            <img
              src={searchDropIcon}
              alt='search-icon'
              onClick={() => {inputSearch.current.focus() ;setIsOpen(true)}}
            />
          )}
        </div>
        {isOpen && (
          <div className='absolute top-[0px] z-10 mt-0 w-full overflow-hidden rounded-xl border-2 border-blue-500 bg-white  pb-[17px] shadow-lg'>
            <div className='flex'>
              <div className=' position-relative   w-full overflow-hidden '>
                {searchTerm !== '' ? (
                  <>
                    <img
                      alt=''
                      src={SearchIcon}
                      className='absolute top-4 z-10 pl-4 '
                    />
                  </>
                ) : (
                  <>
                    <img
                      alt=''
                      src={searchTerm === '' ? startIcon : icon}
                      className='absolute top-4 z-10 pl-4 '
                    />
                  </>
                )}
                <input
                  autoComplete='false'
                  type='text'
                  className='placeholder:text-black-bold relative h-[50px] w-[88%]  cursor-pointer  px-2  py-2  pl-14 text-base font-medium not-italic leading-4 text-[#515151] focus:outline-none '
                  placeholder={placeHolder}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setSearch(e.target.value);
                  }}
                  onClick={() => setIsOpen(true)}
                />

                {searchTerm !== '' ? (
                  <span className='absolute  right-5 top-[18px] z-[9]'>
                    {' '}
                    <img
                      onClick={handleSearchValue}
                      src={CrossIcon}
                      alt='arrow-down'
                      className=' cursor-pointer'
                    />
                  </span>
                ) : (
                  <span className='absolute  right-5 top-6 z-[9]'>
                    {' '}
                    <img
                      onClick={() => setIsOpen(false)}
                      src={Arrow}
                      alt='arrow-down'
                      className=' cursor-pointer'
                    />
                  </span>
                )}
              </div>
            </div>
            <motion.div
              initial='hidden'
              animate='visible'
              variants={variants}
              className='scrollbar  max-h-[204px] max-w-[95%] overflow-y-scroll '
            >
              {filteredOptions?.length > 0 ? (
                filteredOptions?.map((option: any, index: number) => (
                  <div
                    key={index}
                    className={`${searchTerm === option.currency ? 'bg-gray-200' : ''
                      } cursor-pointer px-4  py-2 hover:bg-gray-100`}
                    onClick={() => handleSelect(option)}
                  >
                    <span className='flex gap-2 font-bold  text-black '>
                      {option.flag} {label ? option[label] : option.currency}
                      {currencyFlag !== false && (
                        <span>({getSymbolFromCurrency(option.currency)})</span>
                      )}
                    </span>
                  </div>
                ))
              ) : (
                <span className=' pl-5'>No results found</span>
              )}
            </motion.div>
          </div>
        )}
      </div>
    );
  }
);

export default CountryDropDown;

import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import {
  getTransactionsApi,
  submitSuggestionApi,
  verifyIdApi,
} from '../actions/dashboardAction';
import { Type } from 'utils/interface';

interface TransactionsProps extends Type {
  transactions: any;
  paginationData: any;
}

const initialState = {
  getTransactionsApi: {
    paginationData: {},
    transactions: [],
    error: null,
    status: 'idle',
  } as TransactionsProps,
  internalWalletBalanceApi: { data: [], error: null, status: 'idle' } as Type,
  verifyIdApi: { data: [], error: null, status: 'idle' } as Type,
  submitSuggestionApi: { data: [], error: null, status: 'idle' } as Type,
};

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    clearDashboardState: (state) => {
      const keys = Object.keys(state);
      for (let index = 0; index < keys.length; index++) {
        state[keys[index]].status = 'idle';
      }
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        (action) =>
          [
            getTransactionsApi.pending,
            verifyIdApi.pending,
            submitSuggestionApi.pending,
          ].some((type) => action.type.endsWith(type)),
        (state, { type }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'loading';
          state[apiName].data = [];
          state[apiName].error = null;
        }
      )
      .addMatcher(
        (action) =>
          [
            getTransactionsApi.fulfilled,
            verifyIdApi.fulfilled,
            submitSuggestionApi.fulfilled,
          ].some((type) => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          if (type === 'dashboard/getTransactionsApi/fulfilled') {
            const TransactionsWithFormattedDate = payload.data.map(
              (transaction) => {
                const dateString = transaction.purchase_date;
                const date = new Date(dateString);
                const formattedDate = format(date, 'MMMM dd , yyyy');
                return {
                  ...transaction,
                  formattedDate: formattedDate,
                };
              }
            );
            const { count, page, limit } = payload.data;
            state[apiName].status = 'success';
            state[apiName].paginationData = { count, page, limit };
            state[apiName].transactions = TransactionsWithFormattedDate;
            state[apiName].error = null;
          } else {
            state[apiName].status = 'success';
            state[apiName].data = payload;
            state[apiName].error = null;
          }
        }
      )
      .addMatcher(
        (action) =>
          [
            getTransactionsApi.rejected,
            verifyIdApi.rejected,
            submitSuggestionApi.rejected,
          ].some((type) => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].error = payload;
          state[apiName].status = 'error';
          state[apiName].data = [];
        }
      );
  },
});

export const getTransactionsSelector = (state: {
  dashboard: { getTransactionsApi: TransactionsProps };
}) => state.dashboard.getTransactionsApi;
export const verifyIdSelector = (state: { dashboard: { verifyIdApi: Type } }) =>
  state.dashboard.verifyIdApi;
export const submitSuggestionSelector = (state: {
  dashboard: { submitSuggestionApi: Type };
}) => state.dashboard.submitSuggestionApi;

export const { clearDashboardState } = dashboardSlice.actions;
export default dashboardSlice.reducer;

import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';

import { useAuthContext } from 'components/providers/auth-state-provider';
import PublicNav, { PublicNavProps } from 'components/user/public/nav';
import { dashBoardRoutes, publicRoutes } from 'routes/routes';
import Footer from 'components/ui/footer';
import { useSelector } from 'react-redux';
import { userProfileSelector } from 'features/public/slices/homeSlice';

const motionProps = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

export interface MainLayoutProps {
  children?: React.ReactNode;
}

export default function MainLayout({
  children,
}: MainLayoutProps): JSX.Element {
  const { isAuthenticated }: any = useAuthContext();
  const { data: USER_DETAILS } = useSelector(userProfileSelector);

  const publicNavigations: PublicNavProps['navigations'] = [
    {
      name: `${isAuthenticated ? 'Dashboard' : 'Home'}`,
      to: `${isAuthenticated
        ? dashBoardRoutes.dashBoardHome.path
        : publicRoutes.home.path
        }`,
    },
    {
      name: 'Wallet',
      to: `${isAuthenticated
        ? dashBoardRoutes.myWallet.path
        : publicRoutes.wallet.path
        }`,
    },
    { name: 'Accepted Cards', to: '/accepted-cards' },
    { name: 'Become A Vendor', to: '/become-vendor' },
  ];
  const vendorNavigations: PublicNavProps['navigations'] = [
    {
      name: `${isAuthenticated ? 'Dashboard' : 'Home'}`,
      to: `${isAuthenticated
        ? dashBoardRoutes.dashBoardHome.path
        : publicRoutes.home.path
        }`,
    },
    {
      name: 'Wallet',
      to: `${isAuthenticated
        ? dashBoardRoutes.myWallet.path
        : publicRoutes.wallet.path
        }`,
    },
    { name: 'Accepted Cards', to: '/accepted-cards' },
  ];

  return (
    <>
      <div className={cn('flex  flex-col justify-start  pt-[0px]')}>
        <AnimatePresence mode='wait'>
          <motion.div
            className='sticky top-0 z-50 w-full border-b-2 border-[#ddd] bg-[#fff]'
            key={isAuthenticated || ''}
            {...motionProps}
          >
            <PublicNav
              navigations={
                USER_DETAILS?.role?.name === 'CLIENT' || !isAuthenticated
                  ? publicNavigations
                  : vendorNavigations
              }
            />
          </motion.div>
        </AnimatePresence>
        {children}
      </div>
      <Footer />
    </>
  );
}

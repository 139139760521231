import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateProfileDetail } from 'features/public/slices/homeSlice';
import { apiClient, apiEndPoints } from 'utils/helper/config';
import amazonIcon from 'assets/icons/amazonLogoIcon.svg'
import steamIcon from 'assets/icons/steamIcon.svg'
import razerGoldIcon from 'assets/icons/razerGoldIcon.svg'
import targetIcon from 'assets/icons/targetIcon.svg'
import appleIcon from 'assets/icons/appleIcon.svg'
import iTunesIcon from 'assets/icons/iTunesIcon.svg'
export  const giftCardIcons = [
  { name: 'AMAZON', icon: amazonIcon },
  { name: "STEAM", icon: steamIcon },
  { name: "RAZER_GOLD", icon: razerGoldIcon },
  { name: 'I_TUNES', icon: iTunesIcon },
  { name: "Apple", icon: appleIcon },
  { name: "Target", icon: targetIcon },
]

const giftCardApi = createAsyncThunk(
  'checkout/giftCardApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(apiEndPoints.giftCards);
      const { data } = response;
      const dataCopy = data?.data?.map((cards: any) => (
        cards?.active && { name: cards?.name, active: cards?.active, value: cards?.value, icon: giftCardIcons?.filter((icon) => icon.name?.replace(/_/g,'')?.toLowerCase() === cards?.value?.replace(/_/g,'')?.toLowerCase())[0]?.icon || '' }
      ))
      const newData = { ...data, ...{ data: dataCopy } }
      return fulfillWithValue(newData);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const providerDetailApi = createAsyncThunk(
  'checkout/providerDetailApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(apiEndPoints.providerDetail);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const cryptoCardApi = createAsyncThunk(
  'checkout/cryptoCardApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(apiEndPoints.cryptoCards);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const currencyCodesApi = createAsyncThunk(
  'checkout/currencyCodesApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(apiEndPoints.currency_codes);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const attributeGetAll = createAsyncThunk(
  'checkout/attributeGetAll',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(
        apiEndPoints.attributeGetAll + '?provider=' + id
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const getRecommendationApi = createAsyncThunk(
  'checkout/getRecommendationApi',
  async (sellerId: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(
        `${apiEndPoints.getSellerRecommdation}?sellerId=${sellerId}`
      );
      const { data } = response;

      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const textExtractApi = createAsyncThunk(
  'checkout/textExtractApi',
  async (payload: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.textExtract, payload, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const sellerOfferApi = createAsyncThunk(
  'checkout/sellerOfferApi',
  async (payload: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.sellerOffers, payload);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const userUploadCardApi = createAsyncThunk(
  'checkout/userUploadCardApi',
  async (payload: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(
        apiEndPoints.userUploadCard,
        payload,
        { headers: { 'content-type': 'multipart/form-data' } }
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const sendOtpApi = createAsyncThunk(
  'checkout/sendOtpApi',
  async (payload: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.sendOtp, payload);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const verifyOtpApi = createAsyncThunk(
  'checkout/verifyOtpApi',
  async (payload: any, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.verifyOtp, payload);
      const { data } = response;
      if (data) {
        const payloads = { phoneVerified: true, phone_number: payload.phoneNumber };
        dispatch(updateProfileDetail(payloads));
        return fulfillWithValue(data);
      }
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const createOrderApi = createAsyncThunk(
  'checkout/createOrderApi',
  async (payload: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.createOrder, payload);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const sellerOfferUpdateApi = createAsyncThunk(
  'checkout/sellerOfferUpdateApi',
  async (payload: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.put(apiEndPoints.updateSellerOffer, payload);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error?.response;
      return rejectWithValue(data);
    }
  }
);
export {
  giftCardApi,
  cryptoCardApi,
  currencyCodesApi,
  attributeGetAll,
  getRecommendationApi,
  textExtractApi,
  sellerOfferApi,
  userUploadCardApi,
  sendOtpApi,
  verifyOtpApi,
  createOrderApi,
  providerDetailApi,
  sellerOfferUpdateApi
};

import { createContext, useContext, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { clearUserData, isLogin, saveLogin } from 'utils/helper/storage'

interface AuthState {
  isAuthenticated: boolean | string
  setAuthentication: React.Dispatch<React.SetStateAction<boolean | string>>
}

const AuthStateContext = createContext<AuthState | undefined>(undefined)

interface AuthStateProviderProps {
  children: React.ReactNode
}

function AuthStateProvider({ children }: AuthStateProviderProps): JSX.Element {
  const queryClient = useQueryClient()

  // Either false, or user role in string
  const [isAuthenticated, setAuthentication] = useState<boolean | string | any>(
    isLogin()
  )

  const value = {
    isAuthenticated,
    setAuthentication
  }

  useEffect(() => {
    if (!isAuthenticated) {
      queryClient.clear()
      clearUserData()
    } else {
      saveLogin(isAuthenticated)
    }
  }, [isAuthenticated, queryClient])

  return (
    <AuthStateContext.Provider value={value}>
      {children}
    </AuthStateContext.Provider>
  )
}

function useAuthContext(): AuthState {
  const context = useContext(AuthStateContext)
  if (!context)
    throw new Error(
      'useAuthContext cannot be used outside of a AuthStateProvider'
    )
  return context
}

export { AuthStateProvider, useAuthContext, AuthStateContext }

import { TbQuestionMark } from 'react-icons/tb';
import { FaAngleLeft } from 'react-icons/fa';

import TwoCol from 'components/ui/two-col-table';
import Heading from 'components/ui/heading';
import Button from 'components/ui/button';
import { useSelector } from 'react-redux';
import {
  createOrderSelector,
  recommendationOfferSelector,
  sellerOfferSelector,
} from 'features/app/slices/checkoutSlice';
import { useAppDispatch } from 'store/store';
import { createOrderApi } from 'features/app/actions/checkoutAction';
import { useEffect } from 'react';
import { ToastrSuccess } from 'components/ui/toastr';
import { useNavigate } from 'react-router-dom';
import { publicRoutes } from 'routes/routes';
import { cryptoPriceSelector } from 'features/public/slices/homeSlice';
import { Banner } from 'components/user/app/banner';
import { cutZeros } from 'utils/constants';

export default function CheckoutFourthStep({
  onBack,
  onNext,
  checkOutData,
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sellerOffer = useSelector(sellerOfferSelector);
  const { data: CRYPTO_PRICE } = useSelector(cryptoPriceSelector);
  const recommendationOffer = useSelector(recommendationOfferSelector);
  const { data, status } = useSelector(createOrderSelector);
  const BTC: any = CRYPTO_PRICE?.filter((crypto: any) => crypto.symbol === 'BTC')[0];
  const sellerOfferId = sellerOffer.data?.data?.offerId
    ? sellerOffer.data?.data?.offerId
    : '';
  const buyerOfferId = recommendationOffer.data?.data?.highestOffer?.offer?._id
    ? recommendationOffer.data?.data?.highestOffer?.offer?._id
    : '';

  const handleCompleteOrder = () => {
    if (sellerOfferId && buyerOfferId) {
      const payload = {
        sellerOfferId,
        buyerOfferId,
      };
      dispatch(createOrderApi(payload));
    }
  };

  useEffect(() => {
    if (status === 'success') {
      ToastrSuccess(data?.message);
      onNext();
    }
    if (!sellerOfferId || !buyerOfferId) {
      navigate(publicRoutes.home.path);
    }
  }, [status, onNext, data, sellerOfferId, buyerOfferId, navigate]);
  const totalConversionPrice = Number(checkOutData?.giftCardAmount) - 5
  return (
    <>
      <div className='w-full'>
        <Banner state={checkOutData} />
      </div>
      <Heading
        size='h4'
        className='max-w-lg text-center !text-xl font-bold not-italic !leading-5 !text-black'
      >
        Order Overview
      </Heading>
      <div className='flex w-full max-w-md flex-col gap-2 px-2  '>
        <TwoCol >
          <TwoCol.tr >
            <TwoCol.th>
              <p>Gift Card Amount</p>
              <TbQuestionMark className='text-gray-400 border rounded-full' />
            </TwoCol.th>
            <TwoCol.td>
              <p className='text-[#515151]'>{`$${checkOutData?.giftCardAmount}`}</p>
              <p>{`${cutZeros(1 / BTC?.price * +checkOutData?.giftCardAmount)} BTC`}</p>
            </TwoCol.td>
          </TwoCol.tr>
          <TwoCol.tr >
            <TwoCol.th>
              <p>Exchange Fee</p>
              <TbQuestionMark className='text-gray-400 border rounded-full' />
            </TwoCol.th>
            <TwoCol.td>
              <p className=' text-blue-500'>{`-$5`}</p>
              <p>{`${cutZeros(1 / BTC?.price * 5)} BTC`}</p>
            </TwoCol.td>
          </TwoCol.tr>

          <TwoCol.tr>
            <TwoCol.th>
              <b className='mt-[300px] text-base font-bold not-italic leading-4 text-[#515151]'>
                You Receive:
              </b>
            </TwoCol.th>

            <TwoCol.td>
              <b className='mt-[300px] block text-right text-[14px] font-bold not-italic  leading-4 text-[#515151]'>
                ${cutZeros(checkOutData?.giftCardAmount) - 5} USD
              </b>
              <p className='mt-[5px] text-right text-[14px] font-bold not-italic leading-4 text-[#515151]'>
                {`${Number(1 / BTC?.price * parseFloat(String(totalConversionPrice))).toFixed(8)} BTC`}
              </p>
              <p className='mt-[10px] text-right text-[10px] font-bold not-italic leading-[14px] text-[#0040E3]'>
                <span className='text-right text-[14px] font-bold not-italic leading-[14px] text-[#0040E3]'>90%</span> ORIGINAL VALUE
              </p>
            </TwoCol.td>
          </TwoCol.tr>
        </TwoCol>
      </div>
      <div className='grid w-full max-w-md gap-x-10 gap-y-2 px-2 sm:grid-cols-2'>
        <Button onClick={onBack} className='rounded-xl py-2 text-xl'>
          <FaAngleLeft className='mr-2 mt-0.5 inline text-2xl' /> Back
        </Button>
        <Button
          loading={status === 'loading'}
          disabled={status === 'loading'}
          onClick={handleCompleteOrder}
          className='flex  w-full  items-center justify-center rounded-xl py-2 text-sm font-bold text-white'
        >
          Complete Order
        </Button>
      </div>
    </>
  );
}

import ArrowDown from 'assets/icons/arrow-down-icons.svg';
import ArrowUpIcon from 'assets/icons/arrow-up-icons.svg';
import TargetIcon from 'assets/icons/Target-icon.svg';
import Bitcoin from 'assets/vectors/Bitcoin.svg';
import classNames from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import { cryptoPriceSelector } from 'features/public/slices/homeSlice';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { cutZeros } from 'utils/constants';
interface BannerProps {
  currencySymbol?: any;
  state?: any;
  className?: string;
}

export const Banner: React.FC<BannerProps> = memo(
  ({ currencySymbol, state, className }) => {
    const { data: CRYPTO_PRICE } = useSelector(cryptoPriceSelector);
    const BTC: any = CRYPTO_PRICE?.filter(
      (crypto: any) => crypto.symbol === 'BTC'
    )[0];

    return (
      <div className={classNames('mb-[30px] flex  justify-between pr-[24px]',className)}>
        <div className='mb-[20px] flex h-[35px] w-full max-w-full  items-center gap-[4px] rounded-[6px] bg-[#DAE4FF] px-[6px] md:max-w-[220px] lg:h-[40px] lg:max-w-[255px]'>
          <button className='flex h-[25px]  w-full max-w-[90px] items-center justify-center gap-[4px] rounded-[6px] bg-[#0040E3] lg:h-[30px] lg:max-w-[100px]'>
            <img src={ArrowDown} alt='DownUpIcon' className='w-[20px]' />
            <span className='text-[8px] font-extrabold not-italic leading-[8px] text-white lg:text-[12px]'>
              RECIEVING
            </span>
          </button>
          <img src={Bitcoin} alt='Bitcoin' className='h-[30px]' />
          <span className='block break-all text-xs font-bold not-italic leading-3 text-black lg:text-[15px]'>
            {cutZeros(
              (1 / BTC?.price) * parseFloat(state?.giftCardAmount) || 0
            )}{' '}
            <span className='text-[6px]  font-black not-italic leading-[6px] text-[#575757] lg:text-[8px]'>
              BTC
            </span>
          </span>
        </div>
        <div className='flex h-[35px] w-full items-center gap-[4px] rounded-[6px] bg-[#DAE4FF] px-[6px] md:max-w-[220px] lg:h-[40px] lg:max-w-[255px]'>
          <button className='flex h-[25px] w-full max-w-[90px] items-center justify-center gap-[4px] rounded-[6px] bg-[#0040E3] lg:h-[30px] lg:max-w-[100px]'>
            <img src={ArrowUpIcon} alt='ArrowUpIcon' className='w-[20px]' />
            <span className='text-[8px] font-extrabold not-italic leading-[8px] text-white lg:text-[12px]'>
              SELLING
            </span>
          </button>
          <img
            src={state?.paymentMethod?.icon || TargetIcon}
            alt='TargetIcon'
            className='h-[30px]'
          />
          <span className='block break-all text-xs font-bold not-italic leading-3 text-black  lg:text-[15px]'>
            {getSymbolFromCurrency(state?.currency)}
            {state?.giftCardAmount || '0'}{' '}
            <span className='text-[6px] font-black not-italic leading-[6px] text-[#575757] lg:text-[8px]'>
              {state?.currency}
            </span>
          </span>
        </div>
      </div>
    );
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateProfileDetail } from 'features/public/slices/homeSlice';
import { apiClient, apiEndPoints } from 'utils/helper/config';

export interface Pagination {
  page_number: number;
  page_size: number;
}

const getTransactionsApi = createAsyncThunk(
  'dashboard/getTransactionsApi',
  async (pagination: Pagination, { rejectWithValue, fulfillWithValue }) => {
    try {
      let queryString = '';
      if (pagination) {
        for (let key in pagination) {
          queryString += `${key}=${pagination[key]}&`;
        }
      }
      const response = await apiClient.get(
        apiEndPoints.getTransactions + `${'?' + queryString}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const verifyIdApi = createAsyncThunk(
  'dashboard/verifyIdApi',
  async (_, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.put(apiEndPoints.verifyId);
      const { data } = response;
      if (data) {
        const payload = { idVerified: true };
        dispatch(updateProfileDetail(payload));
        return fulfillWithValue(data);
      }
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const submitSuggestionApi = createAsyncThunk(
  'dashboard/submitSuggestionApi',
  async ({message}:any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.submitSuggestion,{
        message:message,
      });
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

export { getTransactionsApi, verifyIdApi, submitSuggestionApi };

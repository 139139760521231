import React from 'react';
import ArrowDown from 'assets/icons/arrow-dow.svg';
import AnimatedCounter from './AnimatedCounter';
import ArrowUp from 'assets/vectors/Vector.svg';
import Skeleton from 'react-loading-skeleton';

export interface CardProps {
  icon?: string;
  title?: string;
  currency?: React.ReactNode;
  percentage?: string;
  subTitle?: string;
}

function Card({
  icon,
  title,
  currency,
  percentage,
  subTitle,
}: CardProps): JSX.Element {

  return (
    <>
      <div className='cardBlock  sm-w-[100%]  w-[29%] sm:w-[29%] md:w-[100%] lg:w-[340px]    '>
        <div className='m-auto h-[140px] max-w-sm  rounded-lg border border-gray-200 bg-white px-[10px] py-[10px] shadow  dark:border-gray-700 dark:bg-gray-800 md:h-[100px] lg:h-[100px] '>
          <div className='iconHeading mb-[10px] flex flex-col items-center gap-[10px] md:flex-row  lg:mb-[24px] lg:flex-row lg:gap-[0] '>
            <img
              src={icon}
              alt='iconsB'
              className='relative top-0.5 mb-[0px]  w-[100%] max-w-[32px] pr-2 md:mb-0 lg:mb-0'
            />
            <div className='flex flex-col items-center justify-start gap-[4px] sm:flex-row md:flex-row lg:flex-row'>
              <p className='text-center text-[12px] font-bold  md:text-[15px] lg:text-[16px]'>
                {title}
              </p>
              <p className='text-center text-[10px] font-medium text-[#3D3D3D] sm:text-[12px] sm:font-bold md:text-[15px] md:font-bold lg:text-[16px] lg:font-bold  '>
                {subTitle}
              </p>
            </div>
          </div>
          <div className='currencyB flex items-center justify-between  '>
            <div className='sm:text[20px] w-[100%] break-all !p-0  pr-5 text-center  text-[16px] font-bold capitalize leading-[normal] md:text-left md:text-[22px]  md:leading-7 lg:text-left lg:text-[26px] lg:leading-7 xl:text-[32px]'>
              {currency}
            </div>
            {percentage ? (
              <div
                className={`rounded-[30px]; hidden  h-[20px]  items-center justify-center gap-[5px] rounded-full ${parseFloat(percentage) > 0
                  ? 'bg-green-200 text-[#008D1F]'
                  : 'bg-red-200'
                  }  px-[12px] text-center text-[10px] font-bold not-italic leading-[10px] tracking-[-0.2px]   sm:flex md:flex lg:flex`}
              >
                <img
                  alt='vector'
                  src={parseFloat(percentage) > 0 ? ArrowUp : ArrowDown}
                />
                <AnimatedCounter value={String(percentage)} /> %
              </div>
            ) : (
              <div className='w-[20%]'>
                <Skeleton />
              </div>
            )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;

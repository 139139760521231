import React from 'react';
import CopyIcon from 'assets/icons/copy-right.svg';
import RightArrow from 'assets/icons/card-right-arrow.svg';
import Button from 'components/ui/button';
import AddOnIcon from 'assets/icons/add-on.svg';
import ViewSearchIcon from 'assets/icons/View-search.svg';
import HomeIcon from 'assets/icons/home-completed-order.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createOrderSelector } from 'features/app/slices/checkoutSlice';

const CheckoutFifthStep = () => {
  const { data: orderData } = useSelector(createOrderSelector);

  return (
    <>
      <div className='m-auto w-full max-w-[400px]'>
        <h3 className='mb-[30px] text-center text-xl font-bold not-italic leading-5 text-black'>
          Order #{orderData && orderData?.data?.orderNumber} Completed
        </h3>
        <div className='m-auto mb-[30px] w-full max-w-[270px]'>
          <p className='mb-[20px] text-center text-base font-medium not-italic leading-5 text-black'>
            Thank you for your order!
          </p>
          <p className='text-center text-base font-medium not-italic leading-5 text-black'>
            Please allow up to 24 hours to verify and liquidate your gift card.
          </p>
        </div>
        <div className='mb-[150px] w-full'>
          <div className='relative mb-[30px] w-full'>
            <label className='mb-[10px] block text-xs font-bold not-italic leading-3 text-[#333]'>
              ORDER RECIEPT LINK:
            </label>
            <input
            disabled
              type='text'
              className='relative h-[50px] w-full shrink-0 rounded-xl border-2 border-solid border-[#F1F1F1] bg-white pl-[10px] pr-[40px] text-sm font-medium not-italic leading-[14px] text-[#202020] placeholder:text-sm placeholder:font-medium placeholder:not-italic placeholder:leading-[14px] placeholder:text-[#202020]'
              placeholder='https://www.giftxd.com/order/199223401....'
            />
            <span className=' absolute right-[15px] top-[36px] cursor-pointer'>
              <img src={CopyIcon} alt='CopyIcon' />
            </span>
          </div>
          <div className='relative mb-[30px] w-full'>
            <label className='mb-[10px] block text-xs font-bold not-italic leading-3 text-[#333]'>
              SEND RECIEPT VIA EMAIL:
            </label>
            <input
              type='text'
              className='relative h-[50px] w-full shrink-0 rounded-xl border-2 border-solid border-[#F1F1F1] bg-white pl-[10px] pr-[40px] text-sm font-medium not-italic leading-[14px] text-[#202020] placeholder:text-sm placeholder:font-medium placeholder:not-italic placeholder:leading-[14px] placeholder:text-[#202020]'
              placeholder='Enter email address here'
            />
            <span className=' absolute right-[15px] top-[36px] cursor-pointer'>
              <img src={RightArrow} alt='RightArrow' />
            </span>
          </div>
        </div>
        <div className=''>
          <Link
            to='/'
            className='!mb-[10px] flex items-center !justify-start gap-[10px] rounded-xl border border-transparent bg-blue-500 px-5 py-2  !text-[22px] font-bold text-[#fff] hover:bg-blue-500   focus:outline-none focus:ring-2 focus:ring-black'
          >
            {' '}
            <img src={AddOnIcon} alt='AddOnIcon' /> Start New Order
          </Link>
          <Button className='!mb-[30px] flex !justify-start gap-[10px] !border-2 !border-solid !border-[#F1F1F1]  !bg-[#fff] !text-[22px] !text-[#0040E3]'>
            {' '}
            <img src={ViewSearchIcon} alt='ViewSearchIcon' /> View Receipt
          </Button>
          <Link
            to={'/'}
            className='!mb-[10px] flex items-center !justify-start gap-[10px] rounded-xl border border-transparent bg-blue-500 px-5 py-2  !text-[22px] font-bold text-[#fff] hover:bg-blue-500   focus:outline-none focus:ring-2 focus:ring-black'
          >
            {' '}
            <img src={HomeIcon} alt='HomeIcon' /> Go To Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default CheckoutFifthStep;

import { createSlice } from '@reduxjs/toolkit';
import { Type } from 'utils/interface';
import {
  externalWithdrawalApi,
  getWalletAddressApi,
  internalBalanceTransferApi,
  internalWalletBalanceApi,
  pendingWalletBalanceApi,
  walletTransactionApi,
} from '../actions/walletAction';

const initialState = {
  internalWalletBalanceApi: { data: [], error: null, status: 'idle' } as Type,
  pendingWalletBalanceApi: { data: [], error: null, status: 'idle' } as Type,
  walletTransactionApi: { data: [], error: null, status: 'idle' } as Type,
  internalBalanceTransferApi: { data: [], error: null, status: 'idle' } as Type,
  externalWithdrawalApi: { data: [], error: null, status: 'idle' } as Type,
  getWalletAddressApi: { data: [], error: null, status: 'idle' } as Type,
};

const walletSlice = createSlice({
  name: 'walletSlice',
  initialState,
  reducers: {
    clearWalletState: (state) => {
      const keys = Object.keys(state);
      for (let index = 0; index < keys.length; index++) {
        const api = state[keys[index]];
        if (api) {
          api.status = 'idle';
          api.error = null;
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        (action) =>
          [
            internalWalletBalanceApi.pending,
            pendingWalletBalanceApi.pending,
            walletTransactionApi.pending,
            internalBalanceTransferApi.pending,
            externalWithdrawalApi.pending,
            getWalletAddressApi.pending,
          ].some((type) => action.type.endsWith(type)),
        (state, { type }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'loading';
          state[apiName].data = [];
          state[apiName].error = null;
        }
      )
      .addMatcher(
        (action) =>
          [
            internalWalletBalanceApi.fulfilled,
            pendingWalletBalanceApi.fulfilled,
            walletTransactionApi.fulfilled,
            internalBalanceTransferApi.fulfilled,
            externalWithdrawalApi.fulfilled,
            getWalletAddressApi.fulfilled,
          ].some((type) => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'success';
          state[apiName].data = payload;
          state[apiName].error = null;
        }
      )
      .addMatcher(
        (action) =>
          [
            internalWalletBalanceApi.rejected,
            pendingWalletBalanceApi.rejected,
            walletTransactionApi.rejected,
            internalBalanceTransferApi.rejected,
            externalWithdrawalApi.rejected,
            getWalletAddressApi.rejected,
          ].some((type) => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].error = payload;
          state[apiName].status = 'error';
          state[apiName].data = [];
        }
      );
  },
});

export const internalWalletBalanceSelector = (state: {
  wallet: { internalWalletBalanceApi: Type };
}) => state.wallet.internalWalletBalanceApi;
export const pendingWalletBalanceSelector = (state: {
  wallet: { pendingWalletBalanceApi: Type };
}) => state.wallet.pendingWalletBalanceApi;
export const walletTransactionSelector = (state: {
  wallet: { walletTransactionApi: Type };
}) => state.wallet.walletTransactionApi;
export const balanceTransferSelector = (state: {
  wallet: { internalBalanceTransferApi: Type };
}) => state.wallet.internalBalanceTransferApi;
export const externalWithdrawalSelector = (state: {
  wallet: { externalWithdrawalApi: Type };
}) => state.wallet.externalWithdrawalApi;
export const walletAddressSelector = (state: {
  wallet: { getWalletAddressApi: Type };
}) => state.wallet.getWalletAddressApi;

export const { clearWalletState } = walletSlice.actions;
export default walletSlice.reducer;

import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { motion } from 'framer-motion';
import Bitcoin from 'assets/vectors/Bitcoin.svg';
import QuestioMark from 'assets/icons/questionmark.svg';

import Button from 'components/ui/button';
import Heading from 'components/ui/heading';
import SearchableDropdown from 'components/ui/searchabale-dropdown';

import ArrorDoubleDown from 'assets/icons/arrow-down.svg';
import LeftIcon from 'assets/icons/left-side-arrows.svg';
import RightIcon from 'assets/icons/right-arrows.svg';
import RightClick from 'assets/icons/right-click.svg';
import GiftCardIcon from 'assets/icons/gift-card.svg';
import BitCoins from 'assets/icons/bitcoin-blue.svg';
import RankIcon from 'assets/icons/rank-up.svg';
import RedCross from 'assets/icons/redcrossing.svg';
import LeftArrows from 'assets/icons/arrow-lefts.svg';
import RightArrows from 'assets/icons/arrow-rights.svg';
import checkcricle from 'assets/icons/check-circle.svg';
import frontOfCard from 'assets/images/Front-of-Card.svg';
import topOfferIcon from 'assets/icons/topOfferIcon.svg';

import {
  attributeGetAll,
  getRecommendationApi,
  sellerOfferApi,
  sellerOfferUpdateApi,
} from 'features/app/actions/checkoutAction';

import Checkbox from 'components/ui/checkbox';
import Modal from 'components/ui/modal';

import { Country } from 'country-state-city';
import CountryDropDown from 'components/ui/countrydropdown';
import getSymbolFromCurrency from 'currency-symbol-map';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Banner } from 'components/user/app/banner';
import Spinner from 'components/ui/spinner';
import {
  attributeSelector,
  clearCheckOutStatus,
  giftCardSelector,
  providerDetailSelector,
  recommendationOfferSelector,
  sellerOfferSelector,
  updateSellerOfferSelector,
} from 'features/app/slices/checkoutSlice';
import { useAuthContext } from 'components/providers/auth-state-provider';
import LoadingSkeleton from 'components/ui/skeloton-loading';
import { discountPrice, errorMotionProps } from 'utils/constants';
import Error from 'components/ui/error';
import _ from 'lodash';
import Input from 'components/ui/input';
const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const cryptoCards = [
  { name: 'BITCOIN', value: 'BITCOIN', _id: '10001', icon: Bitcoin },
  {
    name: 'BITCOIN TEST',
    value: 'BITCOIN',
    icon: Bitcoin,
  },
  /*{ name: 'USD Coin (USDC)', value: 'usdc', _id: '1003', icon: UsdCoinIcon },*/
];

export default function CheckoutFirstStep({ onNext, getCheckoutDetail }): any {
  const { data } = useSelector(giftCardSelector);
  const attribute = useSelector(attributeSelector);
  const recommendationOffer = useSelector(recommendationOfferSelector);
  const sellerOffer = useSelector(sellerOfferSelector);
  const sellerOfferId = sellerOffer.data?.data?.offerId
    ? sellerOffer.data?.data?.offerId
    : '';

  const [state, setState] = useState({
    paymentMethod: '',
    cashoutMethod: '',
    giftCardAmount: '',
    currency: '',
    eCodeValue: '',
    eCode: false,
    attributes: [],
  });
  const [step, setStep] = useState<number>(0);

  const handleUpdate = useCallback((key: string, value: string) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  const handleStepChange = useCallback((direction) => {
    setStep((prevStep) => prevStep + direction);
  }, []);
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleDropdownOpen = (dropdownId) => {
    setOpenDropdown(dropdownId);
  };

  return (
    <>
      {step === 0 ? (
        <StepOne
          openDropDown={openDropdown}
          setOpenDropdown={handleDropdownOpen}
          handleStep={handleStepChange}
          state={state}
          handleUpdate={handleUpdate}
          giftCards={data?.data}
        />
      ) : step === 1 ? (
        <StepTwo
          handleStep={handleStepChange}
          state={state}
          handleUpdate={handleUpdate}
          getCheckoutDetail={getCheckoutDetail}
        />
      ) : step === 2 ? (
        <StepThree
          handleUpdate={handleUpdate}
          handleStep={handleStepChange}
          state={state}
          allAttributes={attribute.data?.data}
          sellerOfferId={sellerOfferId}
          status={attribute.status}
          sellerStatus={sellerOffer.status}
          sellerErrors={sellerOffer.error}
        />
      ) : step === 3 ? (
        <StepFour
          handleStep={handleStepChange}
          state={state}
          onNext={onNext}
          recommendationOffer={recommendationOffer?.data?.data}
          sellerOfferId={sellerOfferId}
          status={recommendationOffer.status}
        />
      ) : (
        <StepFive
          handleUpdate={handleUpdate}
          handleStep={handleStepChange}
          state={state}
          sellerOfferId={sellerOfferId}
          getCheckoutDetail={getCheckoutDetail}
          onNext={onNext}
        />
      )}
    </>
  );
}

const StepOne = ({
  handleStep,
  state,
  handleUpdate,
  giftCards,
  setOpenDropdown,
  openDropDown,
}) => {
  const { isAuthenticated } = useAuthContext();

  return (
    <>
      <div className='flex w-full max-w-md flex-col gap-[40px]  px-2 sm:gap-[50px] md:gap-[100px] lg:gap-[150px]'>
        <div className='flex flex-col items-center justify-center gap-y-3 lg:flex-row'>
          <Heading size='h5'>Select Your Gift Card and Crypto</Heading>
        </div>
        <div className='mb-[40px]  sm:mb-[50px] md:mb-[100px] lg:mb-[160px]'>
          <div className=' mb-[10px]'>
            <button className='flex h-[24px] w-full  max-w-[150px] items-center gap-[6px] rounded-[100px] bg-[#EEF3FF] px-[10px]'>
              <img src={GiftCardIcon} alt='GiftCardIcon' className='w-[16px]' />
              <span className='text-xs font-bold not-italic leading-3 text-[#0040E3]'>
                I want to sell
              </span>
            </button>
          </div>
          <div className='mb-[30px]'>
            <SearchableDropdown
              value={state.paymentMethod?.name}
              startIcon={state.paymentMethod?.icon}
              options={giftCards}
              onSelect={(e) => handleUpdate('paymentMethod', e)}
              placeHolder='Select Gift Card'
              isOpen={openDropDown === 1}
              onOpen={setOpenDropdown}
              id={1}
            />
          </div>

          <div className='mx-0 my-2.5 mb-[30px] flex justify-center'>
            <img className=' ' src={ArrorDoubleDown} alt='arrow-down' />
          </div>
          <div className=''>
            <div className=' mb-[10px]'>
              <button className='flex h-[24px] w-full  max-w-[150px] items-center gap-[6px] rounded-[100px] bg-[#EEF3FF] px-[10px]'>
                <img src={BitCoins} alt='BitCoins' className='w-[16px]' />
                <span className='text-xs font-bold not-italic leading-3 text-[#0040E3]'>
                  I want to receive
                </span>
              </button>
            </div>
            <SearchableDropdown
              id={2}
              value={state.cashoutMethod.name}
              startIcon={state.cashoutMethod.icon}
              options={cryptoCards}
              onSelect={(e) => handleUpdate('cashoutMethod', e)}
              placeHolder='Select Cashout Method'
              isOpen={openDropDown === 2}
              onOpen={setOpenDropdown}
            />
          </div>
          {/* <div className='mt-[30px]'>
            <SearchableDropdownNew />
          </div> */}
        </div>
      </div>
      <div className='w-full max-w-md px-2'>
        <Button
          disabled={
            !state.paymentMethod || !state.cashoutMethod || !isAuthenticated
          }
          onClick={() => handleStep(1)}
          className='rounded-xl py-2 text-xl'
        >
          Start Your Purchase
        </Button>
      </div>
    </>
  );
};

const StepTwo = ({ handleStep, state, handleUpdate, getCheckoutDetail }) => {
  const { data: providersData } = useSelector(providerDetailSelector);
  const [range, setRange] = useState({ minRange: 0, maxRange: 0 });
  const [rangeError, setRangeError] = useState('');
  const providersDetail = providersData?.data ? providersData.data : {};

  const currencies =
    providersDetail[_.camelCase(state.paymentMethod?.value)].types[0]
      ?.currencies;

  const [inputValue, setInputValue] = useState<string>('');

  const country = Country.getAllCountries();

  const countriesWithCurrencies = _.uniqBy(
    country.filter((country) => {
      return currencies?.some((currency) => {
        return country.currency.includes(currency.name);
      });
    }),
    'currency'
  );

  const handleCurrency = useCallback(
    (e: any) => {
      handleUpdate('currency', e.currency);
      const [min, max] = currencies?.filter(
        (currency) =>
          currency?.name?.toLowerCase() === e.currency?.toLowerCase()
      )[0]?.range;
      setRange((pre) => ({ ...pre, minRange: min }));
      setRange((pre) => ({ ...pre, maxRange: max }));
    },

    [currencies, handleUpdate]
  );

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '').trim();
    if (+inputValue < range?.minRange || +inputValue > range?.maxRange) {
      setRangeError(
        `Gift card amount should be between ${range?.minRange} and ${range?.maxRange} `
      );
    } else {
      setRangeError('');
    }
    handleUpdate('giftCardAmount', inputValue);
    setInputValue(inputValue);
  };

  const handleNext = () => {
    handleStep(1);
    getCheckoutDetail(state);
  };

  return (
    <>
      <motion.div className='w-full'>
        <div className='m-auto flex !w-full  max-w-full   '>
          <Banner
            state={state}
            className='!flex !w-full !justify-between  !gap-11'
          />
        </div>
        <div className='m-auto flex w-full max-w-md flex-col gap-[150px] px-2'>
          <div className='flex flex-col items-center justify-center gap-y-3 lg:flex-row'>
            <Heading size='h5'>Enter Gift Card Amount</Heading>
          </div>
          <div className='mb-[160px] '>
            <div className='mb-[30px]'>
              <span className='mb-[10px] block text-[14px] font-bold uppercase not-italic leading-3 text-black'>
                Currency
              </span>
              <CountryDropDown
                value={state.currency && state.currency}
                startIcon={QuestioMark}
                options={countriesWithCurrencies}
                onSelect={handleCurrency}
                placeHolder='Select currency'
              />
            </div>
            <span className='mb-[10px] block text-[14px] font-bold uppercase not-italic leading-3  text-black'>
              Gift Card Amount
            </span>
            <div className=' relative flex w-full flex-1 items-center gap-[10px] rounded-[10px] border-2 border-solid border-[#F1F1F1] p-3 text-sm text-black outline-none placeholder:text-gray-600'>
              <span className=' text-[18px] font-bold text-[#000]'>
                {inputValue && getSymbolFromCurrency(state.currency)}
              </span>
              <input
                className='!placeholder:font-normal !w-full !border-0 !border-transparent !text-[17px] !font-bold !outline-none'
                pattern='[0-9]+'
                value={state.giftCardAmount.replace(/[^0-9]/g, '').trim()}
                onChange={handleInputValue}
                type='text'
                placeholder='Enter purchase amount'
              />
            </div>
            {rangeError && (
              <motion.p {...errorMotionProps} className='  text-red-500'>
                {rangeError}
              </motion.p>
            )}
          </div>
        </div>
        <div className='flex items-center justify-center gap-[16px]'>
          <Button
            onClick={() => handleStep(-1)}
            className='flex h-[50px] max-w-[172px] items-center justify-center gap-[16px] text-[18px]'
          >
            <img src={LeftIcon} alt='LeftIcon' />
            Back
          </Button>
          <Button
            onClick={handleNext}
            disabled={!state.currency || !state.giftCardAmount || rangeError?true:false}
            className='flex h-[50px]  max-w-[172px] items-center justify-center gap-[16px] text-[18px]'
          >
            Next <img src={RightIcon} alt='RightIcon' />
          </Button>
        </div>
      </motion.div>
    </>
  );
};
// step three
const StepThree = ({
  handleStep,
  state,
  allAttributes,
  sellerOfferId,
  status,
  sellerStatus,
  handleUpdate,
  sellerErrors,
}) => {
  const provider = state.paymentMethod._id || state.paymentMethod.value;
  const dispatch = useAppDispatch();

  // local state for handling local data
  const [exampleImage, setExampleImage] = useState<string>('');
  const [exampleTitle, setExampleTitle] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    // dispatching the attributes api if seller offer in is empty
    if (!sellerOfferId) {
      dispatch(attributeGetAll(provider));
    }
  }, [dispatch, provider, sellerOfferId]);

  // handling the example card images
  const handleExampleCard = (title: string, image: string) => {
    setExampleImage(image);
    setExampleTitle(title);
    setOpenModal(true);
  };

  // checking that how many attributes user have.
  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    handleUpdate('eCode', false);
    if (checked) {
      handleUpdate('attributes', [...state.attributes, ...[id]]);
    } else {
      handleUpdate(
        'attributes',
        state.attributes.filter((i: string) => i !== id)
      );
    }
  };

  // checking that user don't have any attributes
  const handleNoValueCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    handleUpdate('attributes', []);
    handleUpdate('eCode', checked);
  };

  // dispatching api of seller offers
  const handleQuote = () => {
    const payload = {
      paymentMethod: state.paymentMethod._id || state.paymentMethod.value,
      cardType: state.cashoutMethod.value,
      currency: state.currency,
      walletAddress: 'BTC_TEST',
      amount: +state.giftCardAmount,
      attributes: state.attributes,
      eCode: state.eCode,
    };
    dispatch(sellerOfferApi(payload));
  };

  // this is gonna working when the offer API is success executed
  useEffect(() => {
    if (sellerStatus === 'success' && sellerOfferId) {
      handleStep(1);
      dispatch(clearCheckOutStatus());
    }
  }, [dispatch, sellerOfferId, handleStep, sellerStatus]);

  return (
    <>
      <div className='w-full'>
        <Banner state={state} />
      </div>
      <motion.div className='w-full'>
        <div className='m-auto flex w-full max-w-md flex-col gap-[53px]'>
          <div className='flex flex-col items-center justify-center gap-y-3 lg:flex-row'>
            <Heading size='h5'>Pre-Upload Questions</Heading>
          </div>
          <div className='mb-[127px] '>
            <span className='mb-[20px] block text-[14px] font-bold uppercase not-italic leading-4 text-black lg:leading-3 xl:leading-3'>
              Picture Prefences
            </span>
            {status === 'loading' ? (
              <>
                {Array.from({ length: 5 }).map((_, index) => (
                  <LoadingSkeleton key={index} height={10} />
                ))}
              </>
            ) : (
              <>
                {allAttributes?.map((i, idx: number) => (
                  <div key={i._id}>
                    <div className='mb-[20px]  flex items-center justify-between'>
                      <div className='flex items-center gap-[10px]'>
                        <Checkbox
                          disabled={sellerStatus === 'loading'}
                          checked={state.attributes.includes(i._id)}
                          id={i._id}
                          className='!xl:h-[20px] !xl:w-[20px] !lg:h-[20px] !lg:w-[20px] !h-[18px] !w-[18px] cursor-pointer !rounded !border-2 !border-solid !border-[#F1F1F1]'
                          onChange={(e) => {
                            handleCheckBox(e);
                          }}
                        />
                        <span className='text-[14 px] block w-full break-all font-medium not-italic leading-[18px] text-[#515151] lg:text-[16px] lg:leading-[14px] xl:text-[16px] xl:leading-[14px]'>
                          {i.name}
                        </span>
                      </div>
                      <button
                        onClick={() =>
                          handleExampleCard('Front of Card', frontOfCard)
                        }
                        className='flex h-[24px] w-full max-w-[85px] items-center justify-center rounded-[100px] bg-[#EEF3FF] text-center text-[10px] font-bold not-italic leading-[8px] text-[#0040E3] lg:h-[25px] lg:max-w-[100px] lg:text-[12px] xl:h-[26px] xl:max-w-[102px] xl:text-[12px] '
                      >
                        View Example
                      </button>
                    </div>
                    <div>
                      {state.attributes.includes(i._id) && i.children && (
                        <div
                          key={i._id}
                          className='  mb-[20px] ml-4 items-center justify-between'
                        >
                          {i.children.map((child) => (
                            <div
                              key={child._id}
                              className='m-3 flex items-center gap-[10px] '
                            >
                              <Checkbox
                                disabled={sellerStatus === 'loading'}
                                checked={state.attributes.includes(child._id)}
                                id={child._id}
                                className=' !xl:h-[20px] !xl:w-[20px] !lg:h-[20px] !lg:w-[20px] !h-[18px] !w-[18px] cursor-pointer !rounded !border-2 !border-solid !border-[#F1F1F1]'
                                onChange={handleCheckBox}
                              />
                              <span className='text-[16px] font-medium not-italic leading-[18px] text-[#515151]'>
                                {child.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className='mb-[21px] mt-[15px] flex justify-center text-center text-[16px] font-extrabold not-italic leading-3 text-[#7D7D7D]'>
                  or
                </div>
                <div>
                  <div className='mb-[20px]  flex items-center justify-between'>
                    <div className='flex items-center gap-[10px]'>
                      <Checkbox
                        disabled={sellerStatus === 'loading'}
                        checked={state.eCode}
                        id='-1'
                        className=' !xl:h-[20px] !xl:w-[20px] !lg:h-[20px] !lg:w-[20px] !h-[18px] !w-[18px] cursor-pointer !rounded !border-2 !border-solid !border-[#F1F1F1]'
                        onChange={handleNoValueCheckBox}
                      />
                      <span className='text-[16px] font-medium not-italic leading-[14px] text-[#515151]'>
                        I have no pictures (ecode)
                      </span>
                    </div>
                  </div>
                  {sellerStatus === 'error' && (
                    <Error message={sellerErrors?.message} />
                  )}

                  {/* {isNoValueChecked &&
                        <div className=''>
                          <Input value={eCode.replace(/[^a-z0-9]/gi, '')} maxLength={12} placeholder='Enter  E-Code' onChange={(e) => setECode(e.target.value)} />
                        </div>
                      } */}
                </div>
              </>
            )}
          </div>
        </div>
        <div className='flex w-full items-center justify-center gap-[16px]'>
          <Button
            disabled={sellerStatus === 'loading'}
            onClick={() => handleStep(-1)}
            className='flex h-[50px] max-w-[172px] items-center justify-center gap-[16px] text-[18px]'
          >
            <img src={LeftIcon} alt='LeftIcon'></img>Back
          </Button>
          <Button
            disabled={
              (!state.eCode &&
                (!state.attributes?.length || sellerStatus === 'loading')) ||
              sellerStatus === 'loading'
            }
            onClick={handleQuote}
            className='h-[50px] max-w-[172px]  text-[18px]'
          >
            {sellerStatus === 'loading' ? <Spinner /> : 'Get My Quote'}
          </Button>
        </div>

        {/* card example modal */}
        <Modal
          DialogPanelClass='!max-w-[360px]'
          containerClassName='!px-[20px] !py-[20px]'
          title={exampleTitle}
          open={openModal}
          setOpen={setOpenModal}
          cancel={true}
        >
          <div className='flex flex-wrap justify-start'>
            <div className=' mb-[20px] w-full'>
              <img
                loading='lazy'
                src={exampleImage}
                alt='FrontCardImage'
                className='h-[180px] w-full max-w-[330px] object-cover'
              />
            </div>
            <div>
              <p className='mb-3.5 flex gap-3 font-medium '>
                <img src={checkcricle} alt='check' />
                The photo is taken in good light
              </p>
              <p className='mb-3.5 flex gap-3 font-medium'>
                <img src={checkcricle} alt='check' /> Details are in focus
              </p>
              <p className='mb-3.5 flex gap-3 font-medium'>
                <img src={checkcricle} alt='check' /> There is no glare on the
                picture
              </p>
            </div>
          </div>
        </Modal>
      </motion.div>
    </>
  );
};

const StepFour = ({
  handleStep,
  state,
  sellerOfferId,
  recommendationOffer,
  onNext,
  status,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sellerOfferId) {
      dispatch(getRecommendationApi(sellerOfferId));
    }
  }, [dispatch, sellerOfferId]);

  const [sliderRef, setSliderRef] = useState<any>();
  const recommendOffer =
    recommendationOffer?.recommendedOffers?.length > 0
      ? recommendationOffer?.recommendedOffers
      : [];

  const highestOffer =
    Object.keys(recommendationOffer?.highestOffer || {}).length > 0
      ? recommendationOffer?.highestOffer
      : null;

  useEffect(() => {
    if (status === 'success') {
      dispatch(clearCheckOutStatus());
    }
  }, [status, dispatch]);

  return (
    <>
      <div className='w-full'>
        <Banner state={state} />
      </div>
      {status === 'loading' ? (
        <div className='relative m-auto mb-[50px] flex w-full max-w-full flex-col gap-[20px]  px-2  pb-[40px] pt-[20px]'>
          <div className='m-auto  mb-[50px] w-full max-w-[300px] rounded-xl border-2  border-solid p-[15px] md:max-w-[400px] md:px-[36px] md:pb-[40px] md:pt-[20px] lg:max-w-[400px]  lg:px-[36px] lg:pb-[40px] lg:pt-[20px] xl:max-w-[400px] xl:px-[36px] xl:pb-[40px]  xl:pt-[20px]'>
            <div className='mb-[6px] flex w-[90%] justify-center text-center'>
              <LoadingSkeleton height={8} />
            </div>

            <div className='mb-[10px] text-center text-xl font-bold not-italic leading-5 text-[#149300]'>
              <LoadingSkeleton height={8} />
            </div>
            <span className='m-auto flex h-[30px] max-w-[150px] items-center justify-center rounded-[100px] text-center text-[15px] font-bold not-italic leading-3 text-[#572A00]'>
              <LoadingSkeleton height={8} />
            </span>
            <div className='m-auto mb-[20px] mt-[15px] w-full max-w-[300px] rounded-[8px] p-[20px]'>
              <span className='leading-[8px]] mb-[10px] block text-center text-xs font-extrabold not-italic'>
                <LoadingSkeleton height={8} />
              </span>
              <span className='mb-[10px] block text-center text-2xl font-bold not-italic leading-6 text-black'>
                <LoadingSkeleton height={8} />
                <span className='text-base font-bold not-italic leading-4 text-[#676767]'>
                  <LoadingSkeleton height={8} />
                </span>{' '}
              </span>
              <span className='block text-center text-2xl font-bold not-italic leading-6 text-black'>
                <LoadingSkeleton height={8} />
                <span className='text-base font-bold not-italic leading-4 text-[#676767]'>
                  <LoadingSkeleton height={8} />
                </span>
              </span>
            </div>
          </div>
        </div>
      ) : highestOffer === null && recommendOffer?.length === 0 ? (
        <div className='m-auto  mb-[50px] flex h-[200px] w-full max-w-full flex-col justify-center gap-[30px] rounded-xl border-2 border-solid px-2  pb-[20px] pt-[20px]'>
          <h4 className='text-center'>No Offers Available</h4>
        </div>
      ) : (
        <div className=' relative flex w-full max-w-full flex-col gap-[30px] px-2'>
          {(highestOffer && recommendOffer?.length) ||
          recommendOffer.length > 1 ? (
            <>
              <div
                className='left absolute left-[-8px] top-2/4 cursor-pointer md:left-[-30px] lg:left-[-30px]  xl:left-[-30px]'
                onClick={sliderRef?.slickPrev}
              >
                <img alt='LeftArrows' src={LeftArrows} className='w-[35px]' />
              </div>
              <div
                className='right absolute right-[-8px] top-2/4 cursor-pointer md:right-[-30px] lg:right-[-30px] xl:right-[-30px] '
                onClick={sliderRef?.slickNext}
              >
                <img alt='RightArrows' src={RightArrows} className='w-[35px]' />
              </div>
            </>
          ) : null}
          <div
            className={
              'currentSlide active mx-auto  w-full  max-w-[400px] md:max-w-[650px] lg:max-w-[690px] xl:max-w-[690px]'
            }
          >
            <div className='m-auto  mb-[50px] w-full max-w-[300px] rounded-xl border-2  border-solid border-[#0040E3] p-[15px] md:max-w-[400px] md:px-[36px] md:pb-[40px] md:pt-[20px] lg:max-w-[400px]  lg:px-[36px] lg:pb-[40px] lg:pt-[20px] xl:max-w-[400px] xl:px-[36px] xl:pb-[40px]  xl:pt-[20px]'>
              <Slider {...settings} ref={setSliderRef}>
                {/* Highest Offers */}
                {highestOffer && (
                  <div>
                    <div className='mb-[6px] flex w-full justify-center text-center'>
                      <img src={topOfferIcon} alt='RankIcon' />
                    </div>
                    <h4 className='mb-[10px] text-center text-xl font-bold not-italic leading-5 text-[#149300]'>
                      Top Offer
                    </h4>
                    <span className='m-auto flex h-[30px] max-w-[150px] items-center justify-center rounded-[100px] bg-[#E0FFDB] text-center text-[15px] font-bold not-italic leading-3 text-[#572A00]'>
                      {highestOffer?.offer?.offerDiscount} % RATE
                    </span>
                    <div className='m-auto mb-[20px] mt-[15px] w-full max-w-[300px] rounded-[8px] bg-[#E7F2FD] p-[20px]'>
                      <span className='mb-[10px] block text-center text-xs font-extrabold not-italic leading-[8px] text-[#0012B6]'>
                        RECEIVING
                      </span>
                      <span className='mb-[10px] block text-center text-2xl font-bold not-italic leading-6 text-black'>
                        0.000036{' '}
                        <span className='text-base font-bold not-italic leading-4 text-[#676767]'>
                          BTC
                        </span>{' '}
                      </span>
                      <span className='block text-center text-2xl font-bold not-italic leading-6 text-black'>
                        $
                        {discountPrice(
                          state?.giftCardAmount,
                          highestOffer?.offer?.offerDiscount
                        )}
                        <span className='text-base font-bold not-italic leading-4 text-[#676767]'>
                          {highestOffer?.offer?.currency}
                        </span>
                      </span>
                    </div>
                    {highestOffer.matchingAttributes?.length > 0 ? (
                      <div className=''>
                        <span className='block text-center text-[14px] font-bold not-italic leading-[10px] text-[#515151]'>
                          TO QUALIFY:
                        </span>
                        <div className='mt-[15px] flex flex-col items-center justify-center gap-[8px]'>
                          {highestOffer?.matchingAttributes.map((i, idx) => (
                            <div
                              key={idx + ' '}
                              className='mb-[10px] flex items-center  justify-center gap-[8px]'
                            >
                              <img
                                src={RightClick}
                                alt='RedCross'
                                className='w-[20px]'
                              />
                              <span className='text-[15px] font-medium not-italic leading-3 text-[rgb(0,144,12)]  '>
                                {i.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <p className='mb-[112px] text-center'>
                        No additional requirements.
                      </p>
                    )}
                  </div>
                )}

                {/* recommendOffer Offers */}
                {recommendOffer?.length > 0
                  ? recommendOffer.map((i, idx) => (
                      <div key={idx + ' '}>
                        <div className='mb-[6px] flex w-full justify-center text-center'>
                          <img src={RankIcon} alt='RankIcon' />
                        </div>
                        <h4
                          className='mb-[10px] text-center text-xl font-bold not-italic leading-5 text-[#936A00]'
                          key={idx}
                        >
                          Upgrade Offer
                        </h4>
                        <span className='m-auto flex h-[30px] max-w-[150px] items-center justify-center rounded-[100px] bg-[#FFF5DB] text-center text-[15px] font-bold not-italic leading-3 text-[#572A00]'>
                          {state.giftCardAmount - i?.offer?.offerDiscount}% RATE
                        </span>
                        <div className='m-auto mb-[20px] mt-[15px] w-full max-w-[300px] rounded-[8px] bg-[#E7F2FD] p-[20px]'>
                          <span className='mb-[10px] block text-center text-xs font-extrabold not-italic leading-[8px] text-[#0012B6]'>
                            RECEIVING
                          </span>
                          <span className='mb-[10px] block text-center text-2xl font-bold not-italic leading-6 text-black'>
                            0.000036{' '}
                            <span className='text-base font-bold not-italic leading-4 text-[#676767]'>
                              BTC
                            </span>{' '}
                          </span>
                          <span className='block text-center text-2xl font-bold not-italic leading-6 text-black'>
                            ${state.giftCardAmount - i?.offer?.offerDiscount}
                            <span className='text-base font-bold not-italic leading-4 text-[#676767]'>
                              USD
                            </span>
                          </span>
                        </div>
                        <div className=''>
                          <span className='block text-center text-[14px] font-bold not-italic leading-[10px] text-[#515151]'>
                            TO QUALIFY:
                          </span>
                          {i?.missingAttributes?.length > 0 &&
                            i?.missingAttributes.map((i, idx) => (
                              <div
                                className='mt-[10px] flex flex-col items-center justify-center gap-[8px] md:mt-[15px] lg:mt-[15px] xl:mt-[15px]'
                                key={idx + ' '}
                              >
                                <div className='mb-[10px] flex items-center  justify-center gap-[8px]'>
                                  <img
                                    src={RedCross}
                                    alt='RedCross'
                                    className='w-[20px]'
                                  />
                                  <span className='text-[15px] font-medium not-italic leading-3 text-[#900000]  '>
                                    {i.name}
                                  </span>
                                </div>
                              </div>
                            ))}
                          {i?.matchingAttributes?.length > 0 &&
                            i.matchingAttributes.map((i, idx) => (
                              <div
                                className='mt-[15px] flex flex-col items-center justify-center gap-[8px]'
                                key={idx + ''}
                              >
                                <div className='mb-[10px] flex items-center  justify-center gap-[8px]'>
                                  <img
                                    src={RightClick}
                                    alt='RedCross'
                                    className='w-[20px]'
                                  />
                                  <span className='text-[15px] font-medium not-italic leading-3 text-[rgb(0,144,12)]  '>
                                    {i.name}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))
                  : null}
              </Slider>
            </div>
          </div>
        </div>
      )}

      <div className='flex w-full items-center justify-center gap-[16px]'>
        <Button
          onClick={() => handleStep(-1)}
          className='flex h-[50px] max-w-[172px] items-center justify-center gap-[16px] text-[18px]'
        >
          <img src={LeftIcon} alt='LeftIcon' />
          Back
        </Button>
        <Button
          disabled={
            (highestOffer === null && recommendOffer?.length === 0) ||
            status === 'loading'
          }
          onClick={() => {
            !state.eCode ? onNext() : handleStep(1);
          }}
          className='flex h-[50px]  max-w-[172px] items-center justify-center gap-[16px] text-[18px]'
        >
          Next <img src={RightIcon} alt='RightIcon' />
        </Button>
      </div>
    </>
  );
};

const StepFive = ({
  handleStep,
  state,
  onNext,
  handleUpdate,
  sellerOfferId,
  getCheckoutDetail,
}) => {
  const dispatch = useAppDispatch();
  const { status } = useSelector(updateSellerOfferSelector);
  const handleSellerOfferUpdate = () => {
    const payload = {
      id: sellerOfferId,
      eCodeValue: state.eCodeValue,
    };
    dispatch(sellerOfferUpdateApi(payload))
      .unwrap()
      .then(() => {
        getCheckoutDetail(state);
        onNext();
      });
  };
  return (
    <>
      <div className='flex flex-col items-center justify-center gap-y-3 lg:flex-row'>
        <Heading size='h5'>Enter Ecode</Heading>
      </div>
      <div className=' mt-40 w-[70%] '>
        <Input
          maxLength={16}
          onChange={(e) => handleUpdate('eCodeValue', e.target.value)}
          type='text'
          placeholder='Enter e-code here'
          className='w-full'
        />
      </div>
      <div className='mt-44 flex w-full items-center justify-center gap-[16px]'>
        <Button
          disabled={status === 'loading'}
          onClick={() => handleStep(-1)}
          className='flex h-[50px] max-w-[172px] items-center justify-center gap-[16px] text-[18px]'
        >
          <img src={LeftIcon} alt='LeftIcon' />
          Back
        </Button>
        <Button
          loading={status === 'loading'}
          disabled={status === 'loading' || state.eCodeValue.length !== 16}
          onClick={handleSellerOfferUpdate}
          className='flex h-[50px]  max-w-[172px] items-center justify-center gap-[16px] text-[18px]'
        >
          Next <img src={RightIcon} alt='RightIcon' />
        </Button>
      </div>
    </>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, apiEndPoints } from 'utils/helper/config';

const signUpApi = createAsyncThunk(
  'auth/signupApi',
  async (params: object, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.signUpUser, params);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const loginApi = createAsyncThunk(
  'auth/loginApi',
  async (params: object, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(
        apiEndPoints.authenticateUser,
        params
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const forgotPasswordApi = createAsyncThunk(
  'auth/forgotPasswordApi',
  async (params: object, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(
        apiEndPoints.forgotPassword,
        params
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const resetPasswordApi = createAsyncThunk(
  'auth/resetPasswordApi',
  async (payload: object, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.resetPassword, payload);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const newSignUpApi = createAsyncThunk(
  'auth/newSignUpApi',
  async (params: object, {  rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.signUpEmail, params);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const verifyEmailApi = createAsyncThunk(
  'auth/verifyEmailApi',
  async (otp: string, {  rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.signupVerifyEmail+`?${`otp=${otp}`}`);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const createUserApi = createAsyncThunk(
  'auth/createUserApi',
  async (payload: object, {  rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.createUser,payload);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
export { signUpApi, loginApi, forgotPasswordApi, resetPasswordApi ,newSignUpApi,verifyEmailApi,createUserApi};

import { useDropzone, DropzoneState, DropzoneRootProps } from 'react-dropzone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TbFileCheck, TbFilePlus } from 'react-icons/tb';
import { FiDelete } from 'react-icons/fi';
import cn from 'classnames';

import Labeled from 'components/ui/labeled';
import Modal from './modal';

import checkcricle from 'assets/icons/check-circle.svg';

export interface ImageInputProps extends React.HTMLProps<HTMLDivElement> {
  label: string;
  id: string;
  name: string;
  onChange?: any;
  normalInput?: boolean;
  className?: string;
  inage?: string;
}

export default function ImageInput({
  label,
  id,
  name,
  onChange,
  normalInput,
  className,
  image,
}: ImageInputProps & any): JSX.Element {
  const [resetKey] = useState<number>(() => Math.random());
  const [open, setOpen] = useState(false);

  const reset = () => {
    // setResetKey(Math.random())
    setOpen(true);
  };
  const handleModal = () => {
    setOpen(false);
  };
  return label ? (
    <>
      <Labeled
        name={label}
        htmlFor={id}
        sideLabel={
          <button
            className='inline-flex items-center rounded-full border border-transparent bg-blue-50 px-4 text-xs font-bold text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-black focus:ring-offset-2'
            type='button'
            onClick={reset}
          >
            View Example
          </button>
        }
      >
        <FileZone
          id={id}
          key={resetKey}
          name={name}
          onChange={onChange}
          normalInput={normalInput}
        />
      </Labeled>
      <Modal
        DialogPanelClass='!max-w-[360px]'
        cancel={true}
        open={open}
        setOpen={handleModal}
        containerClassName='w-[349px] h-[400px] shrink-0'
        title={label}
      >
        <div>
          <div className='mb-5'>
            <img alt={label} src={image} />
          </div>
          <div>
            <p className='mb-3.5 flex gap-3 font-medium '>
              <img src={checkcricle} alt='check' />
              The photo is taken in good light
            </p>
            <p className='mb-3.5 flex gap-3 font-medium'>
              <img src={checkcricle} alt='check' /> Details are in focus
            </p>
            <p className='mb-3.5 flex gap-3 font-medium'>
              <img src={checkcricle} alt='check' /> There is no glare on the
              picture
            </p>
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <FileZone
      id={id}
      key={resetKey}
      name={name}
      onChange={onChange}
      normalInput={normalInput}
      className={className}
      resetButton={
        <FiDelete
          onClick={reset}
          className='cursor-pointer text-xl text-red-800'
        />
      }
    />
  );
}

interface FileZoneProps {
  id: string;
  name: string;
  onChange: any;
  normalInput?: boolean;
  resetButton?: React.ReactNode;
  className?: string;
}

function FileZone({
  id,
  name,
  onChange,
  normalInput,
  resetButton,
  className,
}: FileZoneProps) {
  const input = useRef<HTMLInputElement | null>(null);
  const [images, setImages] = useState<any>([]);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files

    // const dT = new DataTransfer()
    // for (const file of acceptedFiles) {
    //   dT.items.add(file)
    // }

    // if (input.current) {
    //   input.current.files = dT.files
    // }

    // const result = Array.from(dT.files).map(file =>
    //   URL.createObjectURL(file)
    // )[0]

    setImages([acceptedFiles[0]]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    draggedFiles,
  }: DropzoneRootProps & DropzoneState = useDropzone({ onDrop });

  useEffect(() => {
    onChange(images);
    return function cleanup() {
      // Avoid memory leaks
      images.forEach((image) => URL.revokeObjectURL(image));
    };
  }, [images, onChange]);

  return normalInput ? (
    <div {...getRootProps}>
      <div className='flex'>
        <label htmlFor={id} className='flex-1'>
          <div
            className={cn(
              'flex w-full items-center justify-between p-3',
              'border-2 border-gray-100 text-sm',
              'cursor-pointer rounded-md text-black',
              draggedFiles?.length || images?.length ? 'border-r-0' : ''
            )}
          >
            {draggedFiles?.length || images?.length
              ? 'ID Uploaded'
              : 'Upload ID'}
          </div>
          <input
            {...getInputProps()}
            ref={input}
            id={id}
            name={name}
            type='file'
            className='sr-only'
          />
        </label>
        <div
          className={cn(
            'items-center justify-center border-2 border-l-0 border-gray-100 p-3',
            draggedFiles?.length || images?.length ? 'flex' : 'hidden'
          )}
        >
          {resetButton}
        </div>
      </div>
    </div>
  ) : (
    <div
      {...getRootProps()}
      className={cn(
        'flex justify-center  rounded-md border-2 px-4 py-2',
        draggedFiles?.length || images?.length
          ? 'border-blue-500'
          : 'border-dashed border-gray-50',
        isDragActive ? 'bg-gray-50' : images.length ? 'bg-white' : '',
        className
      )}
    >
      <div>
        <div className='py-1'>
          {draggedFiles?.length || images?.length ? (
            <>
              <TbFileCheck className='mx-auto text-3xl' />
              <label
                htmlFor={id}
                className='cursor-pointer font-bold text-blue-500 hover:text-blue-600'
              >
                <span>Upload Different Photo</span>
                <input
                  {...getInputProps()}
                  ref={input}
                  id={id}
                  name={name}
                  type='file'
                  className='sr-only'
                />
              </label>
            </>
          ) : (
            <>
              <TbFilePlus className='mx-auto text-3xl' />
              <div className='flex gap-1 font-medium'>
                <p className='pl-1'>Drag here or click</p>{' '}
                <label
                  htmlFor={id}
                  className='fond-bold cursor-pointer text-blue-500 hover:text-blue-600'
                >
                  <span>Browse</span>
                  <input
                    {...getInputProps()}
                    ref={input}
                    id={id}
                    name={name}
                    type='file'
                    className='sr-only'
                  />
                </label>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import {
  attributeGetAll,
  createOrderApi,
  cryptoCardApi,
  currencyCodesApi,
  getRecommendationApi,
  giftCardApi,
  providerDetailApi,
  sellerOfferApi,
  sellerOfferUpdateApi,
  sendOtpApi,
  textExtractApi,
  userUploadCardApi,
  verifyOtpApi,
} from '../actions/checkoutAction';

export interface LogCheckout {
  data: any;
  status: 'idle' | 'error' | 'loading' | 'success';
  error: any;
}
const values = { data: [], status: 'idle', error: null } as LogCheckout;

const initialState = {
  giftCardApi: values,
  cryptoCardApi: values,
  currencyCodesApi: values,
  attributeGetAll: values,
  getRecommendationApi: values,
  textExtractApi: values,
  sellerOfferApi: values,
  userUploadCardApi: values,
  sendOtpApi: values,
  verifyOtpApi: values,
  createOrderApi: values,
  providerDetailApi: values,
  sellerOfferUpdateApi: values,
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    clearCheckOutStatus: (state) => {
      const keys = Object.keys(state);
      for (let index = 0; index < keys.length; index++) {
        state[keys[index]].status = 'idle';
      }
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        (action) =>
          [
            createOrderApi.pending,
            giftCardApi.pending,
            cryptoCardApi.pending,
            currencyCodesApi.pending,
            attributeGetAll.pending,
            getRecommendationApi.pending,
            textExtractApi.pending,
            sellerOfferApi.pending,
            userUploadCardApi.pending,
            sendOtpApi.pending,
            verifyOtpApi.pending,
            providerDetailApi.pending,
            sellerOfferUpdateApi.pending,
          ].some((type) => action.type.endsWith(type)),
        (state, { type }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'loading';
          // state[apiName].data = [];
          state[apiName].error = null;
        }
      )
      .addMatcher(
        (action) =>
          [
            createOrderApi.fulfilled,
            giftCardApi.fulfilled,
            cryptoCardApi.fulfilled,
            currencyCodesApi.fulfilled,
            attributeGetAll.fulfilled,
            getRecommendationApi.fulfilled,
            textExtractApi.fulfilled,
            sellerOfferApi.fulfilled,
            userUploadCardApi.fulfilled,
            sendOtpApi.fulfilled,
            verifyOtpApi.fulfilled,
            providerDetailApi.fulfilled,
            sellerOfferUpdateApi.fulfilled,
          ].some((type) => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].status = 'success';
          state[apiName].data = payload;
          state[apiName].error = null;
        }
      )
      .addMatcher(
        (action) =>
          [
            createOrderApi.rejected,
            giftCardApi.rejected,
            cryptoCardApi.rejected,
            currencyCodesApi.rejected,
            attributeGetAll.rejected,
            getRecommendationApi.rejected,
            textExtractApi.rejected,
            sellerOfferApi.rejected,
            userUploadCardApi.rejected,
            sendOtpApi.rejected,
            verifyOtpApi.rejected,
            providerDetailApi.rejected,
            sellerOfferUpdateApi.rejected,
          ].some((type) => action.type.endsWith(type)),
        (state, { type, payload }) => {
          const apiName = type.split('/')[1];
          state[apiName].error = payload;
          state[apiName].status = 'error';
          state[apiName].data = [];
        }
      );
  },
});
// Step 1
export const giftCardSelector = (state: {
  checkout: { giftCardApi: LogCheckout };
}) => state.checkout.giftCardApi;
export const attributeSelector = (state: {
  checkout: { attributeGetAll: LogCheckout };
}) => state.checkout.attributeGetAll;
export const recommendationOfferSelector = (state: {
  checkout: { getRecommendationApi: LogCheckout };
}) => state.checkout.getRecommendationApi;
export const sellerOfferSelector = (state: {
  checkout: { sellerOfferApi: LogCheckout };
}) => state.checkout.sellerOfferApi;

// Step 2
export const textExtractApiSelector = (state: {
  checkout: { textExtractApi: LogCheckout };
}) => state.checkout.textExtractApi;
export const userUploadCardSelector = (state: {
  checkout: { userUploadCardApi: LogCheckout };
}) => state.checkout.userUploadCardApi;

// Step 3
export const sendOtpSelector = (state: {
  checkout: { sendOtpApi: LogCheckout };
}) => state.checkout.sendOtpApi;
export const verifyOtpSelector = (state: {
  checkout: { verifyOtpApi: LogCheckout };
}) => state.checkout.verifyOtpApi;

// order
export const createOrderSelector = (state: {
  checkout: { createOrderApi: LogCheckout };
}) => state.checkout.createOrderApi;

// provider detail
export const providerDetailSelector = (state: {
  checkout: { providerDetailApi: LogCheckout };
}) => state.checkout.providerDetailApi;

//update seller offer
export const updateSellerOfferSelector = (state: {
  checkout: { sellerOfferUpdateApi: LogCheckout };
}) => state.checkout.sellerOfferUpdateApi;

export const { clearCheckOutStatus } = checkoutSlice.actions;
export default checkoutSlice.reducer;

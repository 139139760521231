import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useState, useRef } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { errorMotionProps } from 'utils/constants';

export interface InputProps {
  leftIcon?: React.ReactNode | string;
  rightIcon?: React.ReactNode | string;
  className?: string;
  outerClassName?: string;
  type?: string;
  iconFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  errorMessage: string;
}

export default function Input({
  leftIcon,
  rightIcon: rightIconProp,
  className,
  outerClassName,
  type = 'text',
  iconFocus = true,
  onFocus,
  onBlur,
  errorMessage,
  ...props
}: InputProps & any) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [passView, setPassView] = useState(false);
  const [focused, setFocused] = useState(false);
  const rightIcon =
    type === 'password' ? (
      passView ? (
        <AiOutlineEye />
      ) : (
        <AiOutlineEyeInvisible />
      )
    ) : (
      rightIconProp
    );

  const handleClick = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  const handleFocus = () => {
    if (onFocus) onFocus();
    setFocused(true);
  };

  const handleBlur = () => {
    if (onBlur) onBlur();
    setFocused(false);
  };

  return (
    <div className='relative'>
      <div
        className={cn(
          focused ? 'outline outline-2 outline-blue-500' : '',
          'flex cursor-text rounded-md',
          'border-2 border-gray-100',
          outerClassName
        )}
        onClick={iconFocus ? handleClick : undefined}
      >
        <div className={cn(!!leftIcon ? 'flex' : 'hidden', 'px-3')}>
          <div className={cn('m-auto', className)}>
            {typeof leftIcon === 'string' ? (
              <img src={leftIcon} alt='' />
            ) : (
              leftIcon
            )}
          </div>
        </div>
        <input
          className={cn(
            'w-full flex-1 rounded-md p-3 font-satoshi text-[16px] text-black outline-none placeholder:text-[16px] placeholder:text-gray-600',
            type === 'password' && !passView
              ? '!placeholder:text-[14px] text-[16px] font-medium tracking-[8px] placeholder:tracking-normal'
              : '',
            rightIcon ? 'pr-0' : '',
            leftIcon ? 'pl-0' : '',
            className
          )}
          type={type === 'password' && !passView ? 'password' : 'text'}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          {...props}
        />

        <div
          className={cn(
            type === 'password' ? 'cursor-pointer' : '',
            !!rightIcon ? 'flex' : 'hidden',
            'p-1'
          )}
          onClick={() => setPassView(!passView)}
        >
          <div className={cn('m-auto', className)}>
            {typeof rightIcon === 'string' ? (
              <img src={rightIcon} alt='' />
            ) : (
              rightIcon
            )}
          </div>
        </div>
      </div>
      {errorMessage && (
        <motion.span
          {...errorMotionProps}
          className='absolute px-1 text-red-500  '
        >
          {errorMessage}
        </motion.span>
      )}
    </div>
  );
}

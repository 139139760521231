import { createSlice } from "@reduxjs/toolkit";
import { Type } from "utils/interface";
import { createNewOfferApi, myOffersAllApi, updateOfferApi, vendorOfferOrdersApi, vendorOrdersReceiptApi } from "../actions/myOfferAction";


const values = { data: [], status: 'idle', error: null } as Type
const initialState = {
    createNewOfferApi: values,
    vendorOfferOrdersApi:values,
    myOffersAllApi:values,
    updateOfferApi:values,
    vendorOrdersReceiptApi:values,
};
const myOfferSlice = createSlice({
    name: 'myOffer',
    initialState,
    reducers: {
        clearMyOfferStatus: (state) => {
            const keys = Object.keys(state)
            for (let index = 0; index < keys.length; index++) {
                state[keys[index]].status = 'idle'
            }
        }
    },
    extraReducers(builder) {
        builder.addMatcher(action => [createNewOfferApi.pending,vendorOfferOrdersApi.pending,myOffersAllApi.pending,updateOfferApi.pending,vendorOrdersReceiptApi.pending].some(type => action.type.endsWith(type)), (state, { type }) => {
            const apiName = type.split('/')[1];
            state[apiName].status = 'loading';
            // state[apiName].data = [];
            state[apiName].error = null;

        })
            .addMatcher(action => [createNewOfferApi.fulfilled,vendorOfferOrdersApi.fulfilled,myOffersAllApi.fulfilled,updateOfferApi.fulfilled,vendorOrdersReceiptApi.fulfilled].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].status = 'success';
                state[apiName].data = payload;
                state[apiName].error = null;

            })
            .addMatcher(action => [createNewOfferApi.rejected,vendorOfferOrdersApi.rejected,myOffersAllApi.rejected,updateOfferApi.rejected,vendorOrdersReceiptApi.rejected].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].status = 'error';
                state[apiName].data = [];
                state[apiName].error = payload;
            });

    },
});

export const addNewOfferSelector = (state: { myOffer: { createNewOfferApi: Type } }) => state.myOffer.createNewOfferApi
export const vendorOfferOrdersSelector = (state: { myOffer: { vendorOfferOrdersApi: Type } }) => state.myOffer.vendorOfferOrdersApi
export const myOffersAllSelector=(state: { myOffer: { myOffersAllApi: Type } }) => state.myOffer.myOffersAllApi
export const updateOfferSelector=(state: { myOffer: { updateOfferApi: Type } }) => state.myOffer.updateOfferApi
export const vendorOfferReceiptSelector=(state: { myOffer: { vendorOrdersReceiptApi: Type } }) => state.myOffer.vendorOrdersReceiptApi

export const { clearMyOfferStatus } = myOfferSlice.actions
export default myOfferSlice.reducer
export const colors = {
  blue: {
    50: '#E1EAFF',
    100: '#D1DEFF',
    200: '#86A8FF',
    300: '#5282FF',
    400: '#1552F0',
    500: '#0040E3',
    600: '#0032B3',
    700: '#002585',
    800: '#001B5F',
    900: '#001038',
    950: '#101014',
  },
  gray: {
    50: '#F1F1F1',
    100: '#F6F6F6',
    200: '#D6D6D6',
    300: '#979797',
    400: '#6F6F6F',
    500: '#696969',
    600: '#545454',
    700: '#404040',
    800: '#484848',
    900: '#515151',
    950: '#333333',
  },
};

export const ITEMS_PER_PAGE = 10;

export const detectOS = () => {
  const platform = navigator.platform.toLowerCase();

  if (platform.includes('win')) {
    return 'Windows';
  } else if (platform.includes('mac')) {
    return 'Mac';
  } else if (platform.includes('linux')) {
    return 'Linux';
  } else if (platform.includes('iphone') || platform.includes('ipad')) {
    return 'iOS';
  } else if (platform.includes('android')) {
    return 'Android';
  } else {
    return 'Unknown OS';
  }
};

export const getBrowserInfo = () => {
  const { userAgent } = navigator;

  // Check for various browsers
  if (userAgent.includes('Chrome')) {
    return 'Google Chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'Mozilla Firefox';
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    return 'Apple Safari';
  } else if (userAgent.includes('Edge')) {
    return 'Microsoft Edge';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'Internet Explorer';
  } else {
    return 'Unknown Browser';
  }
};
export const getBase64 = (file: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = function () {
      resolve(reader.result as string);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export function discountPrice(amount, discountPercentage) {
  const value = amount * (1 - discountPercentage / 100);
  return value.toFixed(2);
}

export const onErrorMsg = (error) => {
  if (error?.response?.data?.message) {
    return error.response.data.message;
  } else if (error?.data?.message) {
    return error.data.message;
  } else if (error?.message) {
    return error?.message[0]?.msg || error?.message;
  } else {
    return 'Something went wrong';
  }
};

const dateFormat = (date: Date) => {
  if (!date) return 'Invalid Format';
  return new Date(date).toLocaleDateString(undefined, {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  });
};

const timeFormat = (time: Date) => {
  if (!time) return 'Invalid Format';
  return new Date(time).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

const errorMotionProps = {
  initial: { opacity: 0, x: -20, scale: 0.5, color: '#EF4444' },
  animate: { opacity: 1, x: 0, scale: 1, color: '#DC2626' },
  transition: { duration: 0.5 },
};

const cutZeros = (number) => {
  return +parseFloat(number).toFixed(8);
};

export { dateFormat, timeFormat, errorMotionProps, cutZeros };

import React from 'react';
import { motion } from 'framer-motion';

// import Input from 'components/ui/input';

import joinImg from 'assets/images/join-over-image.png';
import Heading from 'components/ui/heading';
import { Link } from 'react-router-dom';
import { authRoutes } from 'routes/routes';

const Newsletter = () => {
  return (
    <div className='w-full' >
    {/* <motion.section
      initial={{ rotate: 0, scale: 0.9 }}
      whileInView={{
        rotate: 0,
        scale: 1,
        transition: { delay: 0.4, type: 'bounce', stiffness: 500 },
      }}
      className='m-auto mb-[40px] w-[100%] flex-shrink-0 rounded-[12px] border-2 border-[#f1f1f1]  bg-white dark:bg-gray-900  lg:mb-[150px] lg:max-w-[1080px] '
    >
      <div className='mx-auto  px-[16px]  pb-10 pt-10 lg:px-6  '>
        <div className='mx-auto max-w-screen-md sm:text-center'>
          <Heading
            size='h4'
            className='max-w-[100%] pb-5  text-center !text-[36px] font-bold not-italic leading-8 text-[#000] md:w-[100%] lg:max-w-[100%] '
          >
            Subscribe To Our Newsletter
          </Heading>
          <p className=' mb-4 w-[100%]  max-w-2xl  text-center  text-lg  font-medium not-italic leading-4 text-[#464646] dark:text-gray-400 md:mb-12 lg:mb-16'>
            Subscribe to our newsletter and get notified of new deals, blogs,
            and more!
          </p>
          <form action='#'>
            <div className='mx-auto mb-3 max-w-screen-sm items-center space-y-4 sm:flex sm:space-y-0'>
              <div className='relative w-full'>
                <label
                  htmlFor='email'
                  className='mb-2 hidden text-sm font-medium text-gray-900 dark:text-gray-300'
                >
                  Email address
                </label>

                <Input
                  outerClassName='font-medium focus:outline-none text-base  placeholder:text-black-medium  font-medium text-black !rounded-[10px] !border-2 border-solid border-[#F1F1F1]     outline-none'
                  placeholder='Enter your email address'
                  rightIcon={
                    <div className='flex items-center justify-center gap-2'>
                      <button
                        type='submit'
                        className='border-primary-600 hover:bg-primary-800 focus:ring-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800  w-full cursor-pointer rounded-[10px] border bg-blue-400 p-[18px] px-2  py-1  text-center text-[16px] text-white
                           focus:ring-4 lg:p-3 lg:px-6 lg:py-3'
                      >
                        Subscribe
                      </button>
                    </div>
                  }
                />
              </div>
              <div></div>
            </div>
            <p className='text-black-500 newsletter-form-footer  mx-auto flex max-w-screen-sm justify-center pb-4 pt-[58px] text-left text-base font-medium text-[#464646] dark:text-gray-300 lg:pb-8 lg:pt-16'>
              Join our growing community and unlock exclusive account perks!
            </p>
            <div className='flex justify-center'>
              <img src={joinImg} alt='join-images' />
            </div>
          </form>
        </div>
      </div>
    </motion.section> */}
    <motion.section
      initial={{ rotate: 0, scale: 0.9 }}
      whileInView={{
        rotate: 0,
        scale: 1,
        transition: { delay: 0.4, type: 'bounce', stiffness: 500 },
      }}
      className='m-auto mb-[40px] w-[100%] flex-shrink-0 rounded-[12px] border-2 border-[#f1f1f1]  bg-white dark:bg-gray-900  lg:mb-[150px] lg:max-w-[1080px] '
    >
      <div className='mx-auto  px-[16px]  pb-10 pt-10 lg:px-6  '>
        <div className='mx-auto max-w-screen-md sm:text-center'>
          <Heading
            size='h5'
            className='max-w-[100%] pb-5  text-center xl:!text-[36px] lg:!text-[36px] md:!text-[36px] !text-[20px]  font-bold not-italic leading-8 text-[#000] md:w-[100%] lg:max-w-[100%] '
          >
           Sign Up For An Account Now
          </Heading>
          <p className=' mb-4 w-[100%]  max-w-2xl  text-center  text-lg  font-medium not-italic leading-4 text-[#464646] dark:text-gray-400 md:mb-12 lg:mb-16'>
          Sign up for an account and get your own wallet, higher rates, and more!
          </p>
          <form action='#'>
            <div className='mx-auto mb-3 max-w-screen-sm items-center space-y-4 sm:flex sm:space-y-0'>
              <div className='relative w-full'>
                <label
                  htmlFor='email'
                  className='mb-2 hidden text-sm font-medium text-gray-900 dark:text-gray-300'
                >
                  Email address
                </label>

             
                  <div className='flex items-center justify-center gap-2'>
                  <Link to={authRoutes.signup.path}
                    type='submit'
                    className='border-primary-600 hover:bg-primary-800 focus:ring-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800  w-full cursor-pointer rounded-[100px]  max-w-[260px] m-auto border bg-blue-400 p-[18px] px-2  py-1  text-center text-[16px] text-white
                       focus:ring-4 lg:p-3 lg:px-6 lg:py-3'
                  >
                  Sign Up Now
                  </Link>
                </div>
              
              </div>
              <div></div>
            </div>
            <p className='text-black-500 newsletter-form-footer  mx-auto flex max-w-screen-sm justify-center pb-4 pt-[58px] text-left text-base font-medium text-[#464646] dark:text-gray-300 lg:pb-8 lg:pt-16'>
              Join our growing community and unlock exclusive account perks!
            </p>
            <div className='flex justify-center'>
              <img src={joinImg} alt='join-images' />
            </div>
          </form>
        </div>
      </div>
    </motion.section>
    </div>
  );
};

export default Newsletter;

import whiteLogoSvg from 'assets/vectors/white-logo.svg'
import logoSvg from 'assets/icons/xd-logo.svg'
import HeaderLogo from 'assets/icons/header-logo.svg'


import cn from 'classnames'

export interface LogoProps {
  reverse?: boolean
  className?: string
  headerLogo?: boolean
}

export default function Logo({ reverse, className, headerLogo }: LogoProps): JSX.Element {
  return (
    <>
      {headerLogo ? (
        <>
          <img
            id="giftcardx-logo"
            className={cn(
              reverse ? 'hidden dark:block' : 'block dark:hidden',
              className
            )}
            src={HeaderLogo}
            alt="GiftCardX"
          />
        </>
      ) : (
        <>
          <img
            id="giftcardx-logo"
            className={cn(
              reverse ? 'hidden dark:block' : 'block dark:hidden',
              className
            )}
            src={logoSvg}
            alt="GiftCardX"
          />
          <img
            id="giftcardx-logo"
            className={cn(
              reverse ? 'block dark:hidden' : 'hidden dark:block',
              className
            )}
            src={whiteLogoSvg}
            alt="GiftCardX"
          />

          <img
            id="giftcardx-logo"
            className={cn(
              reverse ? 'block dark:hidden' : 'hidden dark:block',
              className
            )}
            src={whiteLogoSvg}
            alt="GiftCardX"
          />
        </>
      )}

    </>
  )
}

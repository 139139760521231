import { useAuthContext } from 'components/providers/auth-state-provider';
import { Loader } from 'components/ui/loader';

import { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export default function AppLayout(): JSX.Element {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (!isAuthenticated) navigate('/', { replace: true });
  }, [isAuthenticated, navigate]);

  return (
    <>
      <div className='flex min-h-screen flex-col justify-center py-12 sm:py-14 lg:py-16'>
        <Suspense fallback={<Loader className='' />}>
          <Outlet />
        </Suspense>
      </div>
    </>
  );
}

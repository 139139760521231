import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface LoadingSkeletonProps {
  width?: number | string;
  height?: number | string;
  count?: number;
  duration?: number;
  circle?: boolean;
  wrapper?: any;
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
  width='100%',
  height,
  count,
  duration,
  circle,
  wrapper,
  ...rest
}) => {
  return (
    <SkeletonTheme baseColor='#ebebeb' highlightColor='#f5f5f5'>
      <Skeleton
        width={width}
        height={height}
        count={count}
        duration={duration}
        circle={circle}
        wrapper={wrapper}
        {...rest}
      />
    </SkeletonTheme>
  );
};

export default LoadingSkeleton;

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAppDispatch } from 'store/store';
// import ProfileIcon from 'assets/icons/use-profile-icon.svg';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import HomeIcons from 'assets/icons/dashboard-home-icon.svg';
import LimitsIcon from 'assets/icons/Limits-icon.svg';
import HoldingsIcon from 'assets/icons/HoldingsIcon.svg';
import ReportIcon from 'assets/icons/Report-icon.svg';
import LogOutIcon from 'assets/icons/Log-Out-Icon.svg';
import Bitcoin from 'assets/vectors/Bitcoin.svg';
import BitcoinRund from 'assets/icons/bitcoin-rund.svg';
import AnimatedCounter from './AnimatedCounter';
import { FaAngleDown } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { internalWalletBalanceSelector } from 'features/app/slices/walletSlice';
import {
  cryptoPriceSelector,
  setNavWalletAmount,
  showAmountSelector,
  userProfileSelector,
} from 'features/public/slices/homeSlice';
import UnlimitedConsumptionIcon from 'assets/icons/Unlimited-Consumption.svg';
import Spinner from './spinner';
import { dashBoardRoutes } from 'routes/routes';
import { Box, LinearProgress } from '@mui/material';
import LoadingSkeleton from './skeloton-loading';
import { cutZeros } from 'utils/constants';
/*import TetherRoun from 'assets/icons/TetherRoun.svg'
import USDCoinRound from 'assets/icons/USD-Coin-round.svg'
import { appRoutes } from 'routes/routes';
import UserIcons from 'assets/icons/user-icon.svg'
import Tether from 'assets/vectors/Tether.svg'
import Usdcoin from 'assets/vectors/Usdcoin.svg'*/

export default function MenuDropdown(): JSX.Element {
  const navWalletAmount = useSelector(showAmountSelector);

  const dispatch = useAppDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { data: INTERNAL_WALLET_BALANCE } = useSelector(
    internalWalletBalanceSelector
  );
  const { data: USER_DETAILS, status: USER_STATUS } =
    useSelector(userProfileSelector);
  const { data: CRYPTO_PRICE } = useSelector(cryptoPriceSelector);
  const BTC: any = CRYPTO_PRICE?.filter(
    (crypto: any) => crypto.symbol === 'BTC'
  )[0];
  const { data: walletBalance } = INTERNAL_WALLET_BALANCE;
  const { available: availableBalance } = walletBalance ?? {};
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    dispatch({ type: 'logout' });
  };

  useEffect(() => {
    if (window.innerWidth < 1023) {
      setIsOpen(true);
    }
    const handleOutsideClick = (event: any) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    const handleClick = (event: any) => {
      handleOutsideClick(event);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  return (
    <div
      ref={dropdownRef}
      className='z-11 relative ml-[0px] mt-[10px]  flex w-[100%] flex-wrap justify-between gap-[10px] border-t-2 pt-[0px] sm:flex-wrap md:ml-[0px] md:max-w-[100%]  md:flex-wrap lg:ml-[0] lg:mt-[0] lg:max-w-[270px] lg:border-0 lg:pb-[0]'
    >
      <div className='  ml-0 hidden w-[100%] max-w-[110px] cursor-pointer items-center   justify-between rounded-lg border-2 border-solid border-[#F1F1F1] p-[9px] lg:flex'>
        <div>
          <span className='block text-[8px] font-extrabold not-italic leading-[8px] text-[#626262]'>
            Your Holdings
          </span>
          <span className='text-xs font-extrabold not-italic leading-3 text-[#0F0F0F]'>
            {navWalletAmount ? (
              <>
                ${' '}
                <AnimatedCounter
                  value={cutZeros(+availableBalance?.btcBalance * BTC?.price)}
                />
              </>
            ) : (
              '*****'
            )}
          </span>
        </div>
        {!navWalletAmount ? (
          <AiOutlineEyeInvisible
            onClick={() => dispatch(setNavWalletAmount(!navWalletAmount))}
          />
        ) : (
          <AiOutlineEye
            onClick={() => dispatch(setNavWalletAmount(!navWalletAmount))}
          />
        )}
      </div>
      <div
        className='flex w-[100%] cursor-pointer items-center justify-between rounded-lg  border-[#F1F1F1]  p-[9px]  md:max-w-[100%] md:border-0  lg:max-w-[150px] lg:border-2 lg:border-solid'
        onClick={toggleMenu}
      >
        {USER_DETAILS?.avatar ? (
          <img
            src={USER_DETAILS?.avatar}
            alt=''
            className='h-[32px] w-[32px] rounded-full'
          />
        ) : (
          <span className='flex h-[32px] w-[32px] items-center justify-center rounded-[50%] bg-[#002585] text-center'>
            <span className='text-xs font-extrabold not-italic leading-3 tracking-[0.24px] text-white'>
              {USER_STATUS === 'loading' ? (
                <Spinner />
              ) : (
                USER_DETAILS?.username &&
                String(USER_DETAILS?.username).slice(0, 2).toUpperCase()
              )}
            </span>
          </span>
        )}
        {USER_STATUS === 'loading' ? (
          <span className='mb-[6px] block text-[16px] font-extrabold not-italic  leading-3 text-[#0F0F0F] lg:text-[10px] xl:text-[10px]'>
            Loading....
          </span>
        ) : (
          <div className='ml-[6px] '>
            <span className='mb-[6px] block text-[16px] font-extrabold not-italic leading-3 text-[#0F0F0F] lg:text-[10px] xl:text-[10px]'>
              {USER_DETAILS?.username
                ? String(USER_DETAILS?.username).slice(0, 8) + '...'
                : ''}
            </span>
            <span className='block text-[8px] font-extrabold not-italic leading-[8px] text-[#0040E3]'>
              {USER_DETAILS?.role?.name}
            </span>
          </div>
        )}
        <FaAngleDown
          className={`ml-2 ${isOpen ? ' hidden rotate-180 lg:block ' : ''}`}
        />
      </div>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className='xl:max-w-48  lg:max-w-48 right-0  mt-[10px] w-full  overflow-y-auto border-solid bg-white md:relative  md:top-[0]   md:max-w-full md:border-0 md:shadow-[0] lg:absolute lg:top-[78px] lg:rounded-md lg:border-2 lg:border-[#F1F1F1] lg:shadow-[0px_0px_12px_0px_rgba(0,0,0,0.04)]'
        >
          <ul className='px-[6px] py-2' onClick={toggleMenu}>
            <li className=' flex items-center gap-[10px] pb-[10px]'>
              {' '}
              <span className=''>
                {' '}
                <img
                  src={HomeIcons}
                  alt='Home-icon'
                  className='h-[32px] w-[32px]'
                />
              </span>{' '}
              <Link
                to='/dashboard'
                className='text-sm font-bold not-italic leading-[14px] text-black'
              >
                Dashboard
              </Link>
            </li>
            <li className=' flex items-center gap-[10px] py-[10px]'>
              {' '}
              <span className=''>
                {' '}
                <img
                  src={LimitsIcon}
                  alt='Limits'
                  className='h-[32px] w-[32px]'
                />
              </span>
              <Link
                to={dashBoardRoutes.myLimitKyc.path}
                className='text-sm font-bold not-italic leading-[14px] text-black'
              >
                Limits
              </Link>{' '}
            </li>
            {USER_STATUS === 'loading' ? (
              <div className='w-full'>
                <div className='w-full'>
                  <LoadingSkeleton width={'100%'} />
                </div>
                <LoadingSkeleton width={'100%'} />
              </div>
            ) : (
              <>
                <div className='mb-[10px] mt-[4px] w-full justify-between '>
                  <div className='flex w-full items-center justify-between gap-[6px]'>
                    {!USER_DETAILS?.isUnlimited ? (
                      <>
                        <div>
                          <span className='text-base font-bold not-italic leading-4 text-[#0040E3]'>
                            ${USER_DETAILS?.consumption || 0}{' '}
                          </span>{' '}
                          /{' '}
                          <span className='text-base font-bold not-italic leading-4 text-[#676767]'>
                            ${USER_DETAILS?.userLimit || 0}
                          </span>
                        </div>
                        <Link
                          to={dashBoardRoutes.myLimitKyc.path}
                          className='flex h-6 w-[100px] shrink-0 items-center justify-center rounded-md bg-[#0040E3] text-center  text-xs font-bold not-italic leading-3 text-white'
                        >
                          INCREASE
                        </Link>
                      </>
                    ) : (
                      <>
                        <div className=' flex gap-1'>
                          <img
                            src={UnlimitedConsumptionIcon}
                            alt='UnlimitedConsumptionIcon'
                            className='h-4'
                          />
                          <span className='text-base font-bold not-italic leading-4 text-[#0040E3]'>
                            Unlimited
                          </span>{' '}
                        </div>
                        <Link
                          to={dashBoardRoutes.myLimitKyc.path}
                          className='flex h-6 w-[100px] shrink-0 items-center justify-center rounded-full bg-[#C6D5FF] text-center  text-xs font-bold not-italic leading-3 text-[#0040E3]'
                        >
                          Unlimited
                        </Link>
                      </>
                    )}
                  </div>
                </div>
                <div className='mb-[20px] h-[12px] w-full rounded-[100px] bg-[#ECF1FF] '>
                  <Box width={'100%'}>
                    <LinearProgress
                      sx={{
                        color: '#0040E3',
                        height: '12px',
                        width: '100%',
                        borderRadius: '100px',
                        backgroundColor: '#ffff',
                      }}
                      color='inherit'
                      variant='determinate'
                      value={Number(
                        ((USER_DETAILS?.consumption || 0) /
                          (USER_DETAILS?.userLimit || 10000000)) *
                        100
                      )}
                    />
                  </Box>
                </div>
              </>
            )}

            <li className=' flex items-center gap-[10px] pb-[10px]'>
              {' '}
              <span className=''>
                {' '}
                <img
                  src={HoldingsIcon}
                  alt='Home-icon'
                  className='h-[32px] w-[32px]'
                />
              </span>{' '}
              <Link
                to='/dashboard/my-wallet'
                className='text-sm font-bold not-italic leading-[14px] text-black'
              >
                Holdings
              </Link>
            </li>
            <div className='mb-[10px] flex flex-wrap rounded-xl border-2 border-solid border-[#F1F1F1] p-[10px]'>
              <div className='w-[100%]'>
                <div className='mb-[10px] flex items-center gap-[8px]'>
                  <span className=''>
                    <img
                      src={Bitcoin}
                      alt='bitcoin'
                      className='h-[18px] w-[24px]'
                    />
                  </span>
                  <Link
                    to='/'
                    className='text-xs font-bold not-italic leading-3 text-black'
                  >
                    {' '}
                    Bitcoin{' '}
                    <span className='text-xs font-bold not-italic leading-3 text-black'>
                      (BTC){' '}
                    </span>
                  </Link>
                </div>
                <p className='mb-[10px] text-[10px] font-bold not-italic leading-[10px] text-[#595959]'>
                  YOUR HOLDINGS:
                </p>
                <div className='flex'>
                  <Link
                    to='/'
                    className='mb-[10px] flex w-[100%] items-center gap-[8px]'
                  >
                    {' '}
                    <span>
                      <img
                        src={BitcoinRund}
                        alt='BitcoinRund'
                        className='h-[12px] w-[12px]'
                      />
                    </span>{' '}
                    <span className='text-xs font-bold capitalize not-italic leading-3 text-black'>
                      {' '}
                      {(availableBalance &&
                        cutZeros(availableBalance?.btcBalance)) ||
                        0}
                    </span>
                  </Link>
                </div>
                <span className='text-xs font-medium capitalize not-italic leading-3 text-[#414141]'>
                  ~${' '}
                  <AnimatedCounter
                    value={cutZeros(+availableBalance?.btcBalance * BTC?.price)}
                  />
                </span>
              </div>
            </div>
            <li className='flex items-center gap-[10px] pb-[10px] pt-[20px]'>
              <span className=''>
                <img
                  src={ReportIcon}
                  alt='Home-icon'
                  className='h-[32px] w-[32px]'
                />
              </span>
              <Link
                to='/dashboard/contact-us'
                className='text-sm font-bold not-italic leading-[14px] text-black'
              >
                Report An Issue
              </Link>
            </li>
            <button
              onClick={handleLogout}
              className='flex items-center gap-[10px] py-[10px]'
            >
              <span className=''>
                <img
                  src={LogOutIcon}
                  alt='Home-icon'
                  className='h-[32px] w-[32px]'
                />
              </span>
              <Link
                to='/'
                className='text-sm font-bold not-italic leading-[14px] text-black'
              >
                Log Out
              </Link>
            </button>
          </ul>
        </motion.div>
      )}
    </div>
  );
}

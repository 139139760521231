import { Loader } from 'components/ui/loader';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
export default function PublicLayout(): JSX.Element {
  return (
    <>
      <div className='py-[40px] sm:py-[30px] lg:py-[30px]'>
        <Suspense fallback={<Loader className='' />}>
          <Outlet />
        </Suspense>
      </div>
    </>
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { apiClient, apiEndPoints } from 'utils/helper/config';
import { setLocalStorage, storageKeys } from 'utils/helper/storage';

const cryptoPriceApi = createAsyncThunk(
  'home/cryptoPriceApi',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.get(apiEndPoints.cryptoPrice);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const fetchCryptoPrice = async () => {
  const response = await apiClient.get(apiEndPoints.cryptoPrice);
  const data = await response.data;
  return data;
};

const userProfileApi = createAsyncThunk(
  'home/userProfileApi',
  async (
    { token }: { token: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        apiEndPoints.profile + '?token=' + token
      );
      const { data } = response;
      const decoded = jwtDecode(token);
      const updatedUserDetails = {
        ...data?.data,
        _id: decoded['_id'], // Add the decoded user ID to USER_DETAILS as '_id'
      };
      setLocalStorage(
        storageKeys.userDetail,
        JSON.stringify(updatedUserDetails)
      );
      return fulfillWithValue(updatedUserDetails);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const contactUsApi = createAsyncThunk(
  'home/contactUsApi',
  async ({ payload }: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.contactUs, payload);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
export { cryptoPriceApi, userProfileApi, fetchCryptoPrice, contactUsApi };

import cn from 'classnames';

export interface Props {
  name: React.ReactNode;
  sideLabel?: React.ReactNode;
  children: React.ReactNode;
  required?: boolean;
}

export default function Labeled({
  name,
  sideLabel,
  children,
  required,
  className,
  ...props
}: Props & any) {
  return (
    <div className={cn('flex flex-col ', className)}>
      <div className='mb-[10px] flex items-center  justify-between'>
        <label
          {...props}
          className='text-left  font-satoshi  text-[16px] font-bold text-black'
        >
          {name} {required && <span className='text-sm text-red-600'>*</span>}
        </label>
        {sideLabel}
      </div>
      {children}
    </div>
  );
}

import cn from 'classnames'

interface NumberItemProps {
  number: number | string
  title: string
  active?: boolean
  className?: string
  titleClassName?: string,
  onClick?: any
}

export default function NumberItem({
  number,
  title,
  active,
  className,
  titleClassName,
  onClick
}: NumberItemProps) {
  const handleClick = () => {
    onClick()
  }
  return (
    <div className={cn(' flex items-center gap-2 md:max-w-[50%]', className)} >
      <div
        onClick={handleClick}
        className={cn(
          'flex h-7 w-7 items-center justify-center rounded-full text-xl font-extrabold lg:h-9 lg:w-9',
          active ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-400'
        )}
      >
        {number}
      </div>
      <p
        className={cn(
          'max-w-[4.5rem] text-sm font-bold leading-[1rem]',
          active ? 'text-blue-500' : 'text-gray-400',
          titleClassName
        )}
      >
        {title}
      </p>
    </div>
  )
}

export const storageKeys = {
  tmpEmail: 'tmp_email',
  jwtToken: 'jwt_token',
  verifyJwt: 'verify_jwt',
  isLogin: 'is_login',
  tmpToken: 'tmp_token',
  userDetail: 'user_detail',
};

var CryptoJS = require('crypto-js');
var SecureStorage = require('secure-web-storage');
export const COOKIES_SECRET_KEY = process.env.REACT_APP_COOKIES_SECRET_KEY;
var SECRET_KEY = process.env.REACT_APP_STORAGE_SECRET_KEY;

const secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  },
});

//Set Secure Storage
export const setStorage = (key, data) => secureStorage.setItem(key, data);

//Get Secure Storage
export const getStorage = (key: string) => secureStorage.getItem(key);

//Remove Data from Storage
export const removeStorage = (key) => secureStorage.removeItem(key);

//Clear all the Data from Session & Local Storage
export const clearStorage = () => secureStorage.clear();


export const saveTmpEmail = (email: string) =>
  localStorage.setItem(storageKeys.tmpEmail, email);

export const clearUserData = () => {
  removeStorage(storageKeys.isLogin);
  removeStorage(storageKeys.jwtToken);
  removeStorage(storageKeys.userDetail);
};

export const setLocalStorage = (key: string, data: string) => {
  // localStorage.setItem(key, data);
  setStorage(key, data);
};
export const getLocalStorage = (key: string): string | null | any =>
  // localStorage.getItem(key);
  getStorage(key);

export const clearLocalStorage = (key: string) => localStorage.removeItem(key);

export const saveLogin = (role: string | boolean | null) =>
  setStorage(storageKeys.isLogin, String(role));

export const isLogin = (): boolean | string => {
  const jwtToken = getStorage('jwt_token');
  const role = getStorage(storageKeys.isLogin);
  return jwtToken ? String(role) : false;
};


export default secureStorage;

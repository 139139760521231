import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import accountSettingSlice from 'features/app/slices/accountSettingSlice';
import connectedAccountSlice from 'features/app/slices/connectedAccountSlice';
import dashBoardSlice from 'features/app/slices/dashboardSlice';
import myOfferSlice from 'features/app/slices/myOfferSlice';
import walletSlice from 'features/app/slices/walletSlice';
import authSlice from 'features/auth/slices/authSlice';
import blogSlice from 'features/public/slices/blogSlice';
import checkoutSlice from 'features/app/slices/checkoutSlice';
import homeSlice from 'features/public/slices/homeSlice';
import { clearUserData } from 'utils/helper/storage';
import sellerOrderSlice from 'features/app/slices/sellerOrderSlice';
import vendorSlice from 'features/app/slices/vendorSlice';
import acceptedCardsSlice from 'features/public/slices/acceptedCardsSlice';
import twofaverificationSlice from 'features/app/slices/twofaverificationSlice';

const rootReducerWithLogout = (state, action) => {
  if (action.type === 'logout') {
    clearUserData();
    state = undefined;
    document.location.href = '/';
  }
  return rootReducer(state, action);
};

const rootReducer = combineReducers({
  auth: authSlice,
  checkout: checkoutSlice,
  blog: blogSlice,
  dashboard: dashBoardSlice,
  home: homeSlice,
  wallet: walletSlice,
  connectedAccount: connectedAccountSlice,
  myOffer: myOfferSlice,
  accountSetting: accountSettingSlice,
  sellerOrder:sellerOrderSlice,
  vendor:vendorSlice,
  acceptedCars:acceptedCardsSlice,
  securitytwoFa:twofaverificationSlice
});

export const store = configureStore({
  reducer: rootReducer,
});
store.replaceReducer(rootReducerWithLogout);

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateProfileDetail } from 'features/public/slices/homeSlice';
import { apiClient, apiEndPoints } from 'utils/helper/config';

const accountSettingsApi = createAsyncThunk(
  'dashboard/accountSettingsApi',
  async (
    { userId }: { userId: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${apiEndPoints.accountSettings}/${userId}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const verifyEmailApi = createAsyncThunk(
  'accountSetting/verifyEmailApi',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await apiClient.post(apiEndPoints.verifyEmail);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const verifyEmailOTPApi = createAsyncThunk(
  'accountSetting/verifyEmailOTPApi',
  async (otp: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await apiClient.post(apiEndPoints.verifyEmailOtp + "?otp=" + otp);
      const { data } = response;
      if (data?.isSuccess) {
        const payload = { email_verified: true }
        dispatch(updateProfileDetail(payload))
      }
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

// update user profile picture
const updateProfileApi = createAsyncThunk(
  'accountSetting/updateProfileApi',
  async (profilePicture :any , { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await apiClient.put(apiEndPoints.updateProfile, profilePicture, { headers: { 'content-type': 'multipart/form-data' } });
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

export { verifyEmailApi, verifyEmailOTPApi, accountSettingsApi, updateProfileApi };

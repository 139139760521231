import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import CheckoutFirstStep from 'controllers/checkout/checkout-first-step';
import CheckoutSecondStep from 'controllers/checkout/checkout-second-step';
import CheckoutThirdStep from 'controllers/checkout/checkout-third-step';
import CheckoutFourthStep from 'controllers/checkout/checkout-fourth-new-step';
import CheckoutFifthStep from 'controllers/checkout/checkout-fifth-step';

import NumberItem from 'components/ui/number-item';
import { motionProps } from 'components/ui/page';
import { getLocalStorage, storageKeys } from 'utils/helper/storage';
import Button from 'components/ui/button';
import { FaArrowRightLong } from 'react-icons/fa6';
import { authRoutes } from 'routes/routes';

const guestCheckoutProcess = [
  { title: 'Start Order' },
  { title: 'Upload Images' },
  { title: 'Verify SMS' },
  { title: 'View Order' },
];

const guestFinishingProcess = ['', '', '', ''];

export default function GuestCheckout() {
  const isLoggedIn = getLocalStorage(storageKeys.jwtToken) || '';
  const [URLSearchParam] = useSearchParams();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [checkOutData, setCheckOutDetail] = useState<any>('');

  useEffect(() => {
    setStep(Number(URLSearchParam.get('step') || '1') - 1);
  }, [URLSearchParam]);

  const handleBack = () => {
    const newStep = step - 1;
    if (newStep >= 0) navigate('/?step=' + (newStep + 1));
  };

  const handleNext = useCallback(() => {
    const newStep = step + 1;
    if (newStep < guestCheckoutProcess.length + guestFinishingProcess.length)
      navigate('/?step=' + (newStep + 1));
  }, [navigate, step]);

  const getCheckoutDetail = useCallback((detail: any) => {
    setCheckOutDetail(detail);
  }, []);

  const steps = [
    <CheckoutFirstStep
      onNext={handleNext}
      getCheckoutDetail={getCheckoutDetail}
    />,
    <CheckoutSecondStep
      onBack={handleBack}
      onNext={handleNext}
      state={checkOutData}
    />,
    <CheckoutThirdStep onBack={handleBack} onNext={handleNext} />,
    <CheckoutFourthStep
      onBack={() => setStep(3)}
      onNext={handleNext}
      checkOutData={checkOutData}
    />,
    <CheckoutFifthStep />,
  ];
  return (
    <div className='flex w-full flex-1 flex-col items-center justify-between gap-8'>
      <AnimatePresence mode='wait'>
        <motion.div
          key={JSON.stringify(step < guestCheckoutProcess.length)}
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          className={cn(
            'w-full flex-wrap  justify-between  gap-x-[10px]  gap-y-4 sm:flex-nowrap md:flex-nowrap lg:flex-nowrap',
            step < guestCheckoutProcess.length ? 'flex' : 'hidden'
          )}
        >
          {guestCheckoutProcess.map(({ title }, index) => (
            <NumberItem
              onClick={() => {
                if (step >= index) setStep(index);
              }}
              key={index}
              title={title}
              number={index + 1}
              active={index === step}
            />
          ))}
        </motion.div>
      </AnimatePresence>
      <AnimatePresence mode='wait'>
        <motion.div
          key={step + ''}
          {...motionProps}
          className='flex w-full flex-1 flex-col items-center justify-between gap-8'
        >
          {!isLoggedIn ? (
            <div className='flex  shrink-0   items-center justify-between gap-3 rounded-[100px] bg-[#dae4ff] px-[8px] py-[5px] md:h-[26px] md:p-[3px] lg:h-[26px] lg:p-[3px]'>
              <Button className='!h-[22px] !w-[64px] !shrink-0  !p-0'>
                <span className='flex flex-nowrap text-center text-[8px] font-extrabold not-italic leading-[8px]  text-white'>
                  {' '}
                  GUEST MODE
                </span>
              </Button>
              <span>Click here to sign up for the full experience.</span>
              <Link to={authRoutes.signup.path}>
                <FaArrowRightLong className=' cursor-pointer' color='#0040E3' />
              </Link>
            </div>
          ) : (
            ''
          )}

          <div className='-mb-8' />
          {/* checkout steps */}
          {steps[step]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

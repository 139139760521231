import cn from 'classnames'

TwoCol.tr = Tr
TwoCol.th = Th
TwoCol.td = Td

export default function TwoCol({ children, className }: any) {
  return (
    <div
      className={cn(
        'lg:text-medium flex w-full flex-col gap-4 rounded-lg bg-gray-100 p-5 text-sm font-medium text-gray-600',
        className
      )}
    >
      {children}
    </div>
  )
}

function Tr({ children }) {
  return <div className="flex w-full">{children}</div>
}

function Td({ children }) {
  return (
    <div className="ml-auto text-right leading-[1rem]">
      <p className="whitespace-pre">{children}</p>
    </div>
  )
}

function Th({ children }) {
  return <div className="flex items-center gap-2">{children}</div>
}

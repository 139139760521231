import Page from 'components/ui/page'
import React from 'react'

const className = 'mb-[10px] text-sm font-medium not-italic leading-5 text-black'

export default function TermOfService() {
    return (
        <Page>
            <p className={className}>
            Our terms now reflect new partnerships, enhancing our ability to convert gift cards to cryptocurrency.
            </p>
            <p className=' mb-[10px] text-sm font-medium not-italic leading-5 text-[#F04438]'>
            We've expanded the features and services GiftXD offers for a seamless liquidation process.
            </p>
            <p className={className}>
            New account management safeguards are in place, including the definition of "control person" roles.
            </p>
            <p className=' mb-[10px] text-sm font-medium not-italic leading-5 text-[#F04438]'>
            We've refined our legal statements to better protect all users within the GiftXD ecosystem.
            </p>
            <p className={className}>
            An Arbitration agreement and Class Action Waiver have been added to streamline dispute resolution.
            </p>
            <p className={className}>
            You'll find comprehensive information on how GiftXD collaborates with third parties to offer you more value.
            </p>
        </Page>
    )
}

import cn from 'classnames'

export interface Props {
  containerClassName?: string
  className?: string
  children?: React.ReactNode
}

export default function CheckoutCard({
  children,
  className,
  containerClassName
}: Props) {
  return (
    <div
      className={cn(
        'mx-auto w-full max-w-xl px-4 sm:px-6 lg:max-w-3xl lg:px-8',
        containerClassName
      )}
    >
      <div
        className={cn(
          'flex min-h-[500px] w-full flex-col items-center justify-between gap-12 rounded-[25px] border-2 border-gray-50 p-4 text-gray-400 shadow-lg sm:p-8  lg:rounded-[50px] lg:px-20 lg:py-14',
          className
        )}
      >
        {children}
      </div>
    </div>
  )
}

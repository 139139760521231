import cn from "classnames"

interface LoaderProps {
    className?: string
}

export const Loader: React.FC<LoaderProps> = ({ className }) => {
    return (
        <div className={cn("flex items-center justify-center h-screen w-full max-w-[100%]", className)}>
            <div className="relative flex justify-center items-center w-full text-center max-w-[100%]">
                <div className="h-24  rounded-full border-t-8 border-b-8 border-gray-200 " />
                <div className="absolute top-0 left-[50%]  h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin "></div>
            </div>
        </div>
    )
}

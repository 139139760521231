import { createContext, useContext, useEffect, useState } from 'react';

interface IThemeStateContext {
  theme: 'light' | 'dark';
  setTheme: React.Dispatch<React.SetStateAction<'light' | 'dark'>>;
}
/**
 * @IThemeStateContext
 */

const ThemeStateContext = createContext<IThemeStateContext | undefined>(
  undefined
);

interface ThemeStateProviderProps {
  children: React.ReactNode;
}

function ThemeStateProvider(props: ThemeStateProviderProps): JSX.Element {
  const [theme, setTheme] = useState<'light' | 'dark'>(
    () => (localStorage.theme || 'light') as 'light' | 'dark'
  );

  useEffect(() => {
    const root = window.document.documentElement;
    const newTheme = theme === 'dark' ? 'light' : 'dark';

    root.classList.remove(newTheme);
    root.classList.add(theme);

    document.documentElement.style.backgroundColor =
      theme === 'light' ? '#fff' : '#141B25';

    // localStorage.setItem('theme', theme);
  }, [theme]);



  const value: IThemeStateContext = {
    theme,
    setTheme,
  };

  return (
    <ThemeStateContext.Provider value={value}>
      {props.children}
    </ThemeStateContext.Provider>
  );
}

function useThemeContext(): IThemeStateContext {
  const context = useContext(ThemeStateContext);
  if (!context) {
    throw new Error(
      'useThemeContext cannot be used outside of a ThemeStateProvider'
    );
  }
  return context;
}

export { ThemeStateProvider, useThemeContext, ThemeStateContext };

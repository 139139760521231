import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
// import { TbQuestionMark } from 'react-icons/tb';

// import Checkbox from 'components/reusable/checkbox'
import Labeled from 'components/ui/labeled';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import { useEffect, useState } from 'react';
import Modal from 'components/ui/modal';
import TermOfService from 'components/user/public/term-of-service';
// import Dropdown from 'components/ui/dropdown';

// import arrowDown from 'assets/icons/dropdownarrowdown.svg';
type cardDetailType = {
  cardNumber: string,
  validThruMonth: string,
  validThruYear: string,
  cvv: string,
}
const cartDetailValue: cardDetailType = {
  cardNumber: '',
  validThruMonth: '',
  validThruYear: '',
  cvv: ''
}
export default function CheckoutFourthStep({ onBack, onNext, cardDetail, setECodeValue, handleDropDown, handleInput, eCodeValue, }) {
  const [open, setOpen] = useState(false);
  const [userCardDetail, setUserCardDetail] = useState<cardDetailType>(cartDetailValue)

  useEffect(() => {
    if (eCodeValue) {
      setUserCardDetail((pre: cardDetailType) => ({ ...pre, cardNumber: eCodeValue }))
    }
    setUserCardDetail(cardDetail)
  }, [cardDetail, eCodeValue])

  const handleModal = () => {
    setOpen(false);
  };
  // const month = [
  //   '01',
  //   '02',
  //   '03',
  //   '04',
  //   '05',
  //   '06',
  //   '07',
  //   '08',
  //   '09',
  //   '10',
  //   '11',
  //   '12',
  // ];
  // const year = [
  //   { value: 24, label: '24(2024)' },
  //   { value: 25, label: '25(2025)' },
  //   { value: 26, label: '26(2026)' },
  //   { value: 27, label: '27(2027)' },
  //   { value: 28, label: '28(2028)' },
  // ];
  // const [selectedOption, setSelectedOption] = useState('');
  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  //   handleDropDown(event.target.id, event.target.value);
  // };

  // handling the input value change in run time 
  const handleInputValue = (event: any) => {
    setUserCardDetail((pre: cardDetailType) => ({ ...pre, [event.target.id]: event.target.value?.replace(/[^a-zA-Z0-9 ]/g, "").trim() }));
  };

  // updating the value of the card detail after the next click
  const handleNext = () => {
    handleInput('cardNumber', userCardDetail?.cardNumber);
    handleInput('validThruMonth', userCardDetail?.validThruMonth);
    handleInput('validThruYear', userCardDetail?.validThruYear);
    handleInput('cvv', userCardDetail?.cvv);
    setECodeValue(userCardDetail?.cardNumber);
    onBack()
  }  
  return (
    <>
      <div className='mb-[108px] w-full'>
        <h3 className='text-center text-xl font-bold not-italic leading-5 text-black'>
        Update Ecode
        </h3>
      </div>
      <div className='flex w-full max-w-md flex-col gap-5 px-2'>
        <Labeled name='Card Number'>
          <Input
            type='text'
            maxLength={16}
            id='cardNumber'
            onChange={handleInputValue}
            placeholder='Enter card number here'
            value={userCardDetail?.cardNumber || ''}
          />
        </Labeled>
        {/* <div className=''>
          <Labeled name='Card Expiration Month'>
            <Dropdown
              id='validThruMonth'
              value={
                userCardDetail?.validThruMonth
                  ? userCardDetail.validThruMonth
                  : selectedOption
              }
              onChange={handleOptionChange}
              placeHolder='Select card expiration month'
              endIcon={arrowDown}
            >
              {month.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </Dropdown>
          </Labeled>
          <Labeled name='Card Expiration Year'>
            <Dropdown
              id='validThruYear'
              value={
                userCardDetail?.validThruYear
                  ? userCardDetail.validThruYear
                  : selectedOption
              }
              onChange={handleOptionChange}
              placeHolder='Select card expiration year'
              endIcon={arrowDown}
            >
              {year.map((year, index) => (
                <option key={index + ' '} value={year.value}>
                  {year.label}
                </option>
              ))}
            </Dropdown>
          </Labeled>
          <Labeled
            name={
              <div className='flex items-center gap-3'>
                <p>CCV</p>
                <TbQuestionMark className='rounded-full border text-gray-400' />
              </div>
            }
          >
            <Input
              maxLength={4}
              id='cvv'
              onChange={handleInputValue}
              placeholder='Enter CVV'
              value={userCardDetail?.cvv}
            />
          </Labeled>
        </div> */}
      </div>
      <div className='flex  w-full max-w-md gap-4 px-2 md:gap-10'>
        <Button onClick={onBack} className="rounded-xl py-2 text-xl">
          <FaAngleLeft className="mr-2 mt-0.5 inline text-2xl" /> Back
        </Button>
        <Button
          onClick={handleNext}
          className='!w-full rounded-xl py-2 text-xl'
          disabled={!userCardDetail?.cardNumber || userCardDetail?.cardNumber?.length < 16}
        >
          Next
          <FaAngleRight className='ml-2 mt-0.5 inline text-2xl' />
        </Button>
      </div>
      <Modal open={open} setOpen={handleModal} title='Terms of Service'>
        <div>
          <div className=' mb-[20px] h-[276px] overflow-y-scroll rounded-[9px] border-2 p-[12px]'>
            <b className='mb-[10px] block text-sm font-bold not-italic leading-5 text-black'>
              Tirm 1:
            </b>
            <TermOfService />
          </div>
        </div>
        <div>
          <Button
            onClick={handleModal}
            className='h-[50px] rounded-xl border-solid border-[#0040E3] text-lg  font-bold '
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

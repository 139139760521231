import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { motionProps } from './page';
import cn from 'classnames'
export interface ErrorProps {
    message?: string;
    className?:string
}
const Error = ({ message ,className}: ErrorProps): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(true);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setVisible(false);
        }, 5000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    let errorMessage = message;

    if (typeof message === "object") {
        errorMessage = JSON.stringify(message);
    }
    return (
        <>
            {visible && (
                <motion.div key={message + ''} {...motionProps} className={cn("bg-red-100 flex  max-w-[444px] w-full justify-center items-center text-red-700 px-4 py-2 rounded relative text-center",className)} role="alert">
                    <span className="block sm:inline ">{errorMessage}</span>
                </motion.div>
            )}

        </>

    );
};

export default Error;

import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useEffect, useState } from 'react';

import ImageInput from 'components/ui/image-input';
import Button from 'components/ui/button';

import frontOfCard from 'assets/images/Front-of-Card.svg';
import backOfCard from 'assets/images/Back-of-Card.svg';
import receiptOfCard from 'assets/images/Receipt-Card.svg';
import Heading from 'components/ui/heading';

// import NeradsIcon from 'assets/icons/Nerads.svg';
import Checkbox from 'components/ui/checkbox';
import Modal from 'components/ui/modal';

import GuestCheckoutFourthStep from './checkout-fourth-step';
import { useAppDispatch } from 'store/store';
import {
  textExtractApi,
  userUploadCardApi,
} from 'features/app/actions/checkoutAction';
import { useSelector } from 'react-redux';
import {
  clearCheckOutStatus,
  providerDetailSelector,
  recommendationOfferSelector,
  sellerOfferSelector,
  textExtractApiSelector,
  userUploadCardSelector,
} from 'features/app/slices/checkoutSlice';
import Error from 'components/ui/error';
import { Banner } from 'components/user/app/banner';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { publicRoutes } from 'routes/routes';
import PrivacyPolicy from 'components/user/public/privacy-policy';
import TermOfService from 'components/user/public/term-of-service';

interface ReadValueInterface {
  isPrivacyPolicyRead: boolean;
  isTermsOfServiceRead: boolean;
}
const isRead = { isPrivacyPolicyRead: false, isTermsOfServiceRead: false };


export default function CheckoutSecondStep({ onBack, onNext, state }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // redux states
  const { data: recommendedOffers } = useSelector(recommendationOfferSelector);

  const attributesName = ['Front of Card Pictures', 'Back of Card Pictures', 'Receipt Pictures'];
  
  const matchingAttributes = !recommendedOffers?.data?.recommendedOffers?.matchingAttributes?.length ?
    recommendedOffers?.data?.highestOffer?.matchingAttributes?.filter((attribute: any) => attributesName.includes(attribute.name)) :
    recommendedOffers?.data?.recommendedOffers?.matchingAttributes?.filter((attribute: any) => attributesName.includes(attribute.name))
    ;

  const textExtracts = useSelector(textExtractApiSelector);
  const userUploadState = useSelector(userUploadCardSelector);
  const sellerOffer = useSelector(sellerOfferSelector);
  const sellerOfferId = sellerOffer.data?.data?.offerId
    ? sellerOffer.data?.data?.offerId
    : '';

  const { data: providersData } = useSelector(providerDetailSelector);
  const providersDetail = providersData?.data ? providersData.data : {};
  const provider = providersDetail[_.camelCase(state.paymentMethod?.value)];

  // local states
  const [frontCard, setFrontCard] = useState([]);
  const [backCard, setBackCard] = useState([]);
  const [receipt, setReceipt] = useState([]);

  const [step, setStep] = useState<number>(0);
  const [edit, setEdit] = useState<boolean>(false);
  const [showCardDetail, setShowCardDetail] = useState<boolean>(false);
  const [openServiceMOdal, setOpenServiceMOdal] = useState(false);
  const [openPolicyMOdal, setOpenPolicyMOdal] = useState(false);
  const [extractedData, setExtractedData] = useState<any>('');
  const [policy, setPolicy] = useState(false);
  const [eCodeValue, setECodeValue] = useState(state?.eCodeValue);

  // handling card images 
  const handleTextRact = async () => {
    const formData = new FormData();
    formData.append('files', frontCard[0]);
    formData.append('files', backCard[0]);
    formData.append('files', receipt[0]);
    dispatch(textExtractApi(formData));
  };

  // uploading the card value 
  const handleUploadCard = () => {
    const payload = {
      front_side_image: frontCard[0],
      back_side_image: backCard[0],
      receipt_front_side: receipt[0],
      receipt_back_side: receipt[0],
      sellerOffer: sellerOfferId,
      card_number: extractedData?.cardNumber,
      card_exp_month: extractedData?.validThruMonth,
      card_cvc: extractedData?.cvv,
      card_exp_year: extractedData?.validThruYear,
    };
    dispatch(userUploadCardApi(payload));
  };

  useEffect(() => {
    if (!matchingAttributes?.length) {
      setStep((pre) => pre + 1);
    }
    if (!state.paymentMethod?.value) {
      navigate(publicRoutes.home.path, { replace: true });
    }
    if (textExtracts.status === 'success' && textExtracts.data) {
      setExtractedData(textExtracts.data?.createCardDto);
      setStep((pre) => pre + 1);
      dispatch(clearCheckOutStatus());
    }
    if (userUploadState.status === 'success') {
      onNext();
      dispatch(clearCheckOutStatus());
    }
    if (textExtracts.status === 'error' || userUploadState.status === 'error') {
      setTimeout(() => {
        dispatch(clearCheckOutStatus());
      }, 5000);
    }
  }, [
    textExtracts,
    userUploadState,
    onNext,
    dispatch,
    state.paymentMethod?.value,
    navigate,
    matchingAttributes?.length,
  ]);

  const handleDropDown = (key: string, value: any) => {
    setExtractedData((pre) => ({ ...pre, [key]: value }));
  };

  // handling card input value change
  const handleInput = (key: string, value: any) => {
    setExtractedData((pre) => ({ ...pre, [key]: value }));
  };

  // card attribute picture types
  const attributeType = [
    {
      name: 'Front of Card Pictures',
      image: frontOfCard,
      handleImages: setFrontCard,
      value: frontCard,
    },
    {
      name: 'Back of Card Pictures',
      image: backOfCard,
      handleImages: setBackCard,
      value: backCard,
    },
    {
      name: 'Receipt Pictures',
      image: receiptOfCard,
      handleImages: setReceipt,
      value: receipt,
    },
  ];

  const [read, setRead] = useState<ReadValueInterface>(isRead);

  // Checking the Privacy policy and Terms Of Service are full read or not
  const handleScroll = (e: any, key: string) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      setRead((pre: any) => ({ ...pre, [key]: true }));
    }
  };
  return (
    <>
      <div className='w-full'>
        <Banner state={state} />
      </div>
      {!edit ? (
        step === 0 ? (
          <>
            <div className='mb-[80px] mt-[30px] w-full'>
              <h3 className='text-center text-xl font-bold not-italic leading-5 text-black'>
                Upload Gift Card Images{' '}
              </h3>
            </div>
            {textExtracts.status === 'error' && (
              <Error message={textExtracts.error?.error} />
            )}
            <div className='flex w-full max-w-md flex-col gap-5 px-2'>
              {matchingAttributes?.map((attributes: any, index: number) => (
                <ImageInput
                  key={attributes._id}
                  label={attributes.name}
                  id={attributes._id}
                  name={attributes._id}
                  image={
                    attributes.name === attributeType[index].name &&
                    attributeType[index].image
                  }
                  onChange={
                    attributes.name === attributeType[index].name ?
                      attributeType[index].handleImages : () => { }
                  }
                />
              ))}
            </div>
            <div className='grid w-full max-w-md grid-cols-2 gap-4 px-2 md:gap-10'>
              <Button
                disabled={textExtracts.status === 'loading'}
                onClick={onBack}
                className='rounded-xl py-2 text-xl'
              >
                <FaAngleLeft className='mr-2 mt-0.5 inline text-2xl' /> Back
              </Button>
              <Button
                onClick={handleTextRact}
                disabled={textExtracts.status === 'loading'}
                className='rounded-xl py-2 text-xl'
                loading={textExtracts.status === 'loading'}
              >
                Next <FaAngleRight className='ml-2 mt-0.5 inline text-2xl' />
              </Button>
            </div>
          </>
        ) : (
          <>
            <>
              <div className='w-full'>
                <Heading
                  size='h3'
                  className='text-center !text-xl font-bold not-italic leading-5 text-black'
                >
                  Gift Card Preview
                </Heading>
              </div>
              <div
                style={{ backgroundColor: '#' + provider?.backgroundColor }}
                className={`m-auto h-[220px] w-full max-w-[360px]  rounded-[12px] px-[20px] py-[21px]`}
              >
                <div className='mb-[52px] flex  justify-between'>
                  <h4 className='text-[20px] font-semibold not-italic leading-10 tracking-[0.8px] text-white md:text-[30px] lg:text-[30px] xl:text-[30px]'>
                    {' '}
                    {provider?.name}
                  </h4>
                  <div className='flex w-full max-w-[130px] flex-col items-end'>
                    <span
                      style={{ color: '#' + provider?.backgroundColor }}
                      className={`flex h-[20px] w-full max-w-[120px] items-center justify-center gap-[6px] rounded-[100px] bg-white text-center text-[10px] font-bold not-italic leading-[10px] `}
                    >
                      <img
                        src={provider?.logo}
                        alt='card logo'
                        className='h-[11px] w-[15px]'
                      />
                      STATS FOR NERDS
                    </span>
                    {showCardDetail && (
                      <div className='mt-[6px] w-full max-w-[120px] rounded-[2px] bg-white p-[5px]'>
                        <span
                          style={{ color: '#' + provider?.backgroundColor }}
                          className={`mb-[6px] block text-[8px] font-bold not-italic leading-[8px]`}
                        >
                          Detection Accuracy Rate:
                        </span>
                        <span
                          style={{ color: '#' + provider?.backgroundColor }}
                          className={`mb-[6px] block text-[8px] font-bold not-italic leading-[8px] `}
                        >
                          Card Number: 99.4%
                        </span>
                        <span
                          style={{ color: '#' + provider?.backgroundColor }}
                          className={`mb-[6px] block text-[8px] font-bold not-italic leading-[8px] `}
                        >
                          Expiration Date: 88.2%
                        </span>
                        <span
                          style={{ color: '#' + provider?.backgroundColor }}
                          className={`mb-[6px] block text-[8px] font-bold not-italic leading-[8px]`}
                        >
                          Security Code: 97.6%
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='w-full'>
                  <span className='block text-[30px] font-normal not-italic leading-4 text-white  '>
                    {eCodeValue
                      ? eCodeValue
                      : showCardDetail
                        ? extractedData && extractedData?.cardNumber
                        : `**** **** **** ${extractedData && extractedData?.cardNumber?.slice(-4)
                        }`}
                  </span>
                  {/* <div className='mt-[40px] flex items-center gap-[41px]'>
                    <span className='flex items-center gap-[4px]'>
                      <span className='block max-w-[40px] text-[10px] font-extrabold not-italic leading-[15px] text-white'>
                        VALID THRU
                      </span>
                      <span className='text-[18px] font-normal not-italic leading-3 text-white'>
                        {showCardDetail
                          ? extractedData && extractedData?.validThruMonth +
                          '/' +
                          extractedData?.validThruYear
                          : '** / **'}
                      </span>
                    </span>
                    <span className='flex items-center gap-[8px]'>
                      <span className='text-[10px] font-extrabold not-italic leading-[8px] text-white'>
                        CVV
                      </span>
                      <span className='text-[16px] font-extrabold not-italic leading-[8px] text-white'>
                        {showCardDetail ? extractedData && extractedData?.cvv : '***'}
                      </span>
                    </span>
                  </div> */}
                </div>
              </div>
              <div className='m-auto w-full max-w-[360px] '>
                <Button
                  onClick={() => setShowCardDetail(!showCardDetail)}
                  className='!h-[50px] w-full rounded-[12px] !bg-[#EEF3FF] !text-lg  font-bold  !leading-[18px] !text-[#0040E3]'
                >
                  {showCardDetail ? 'Hide Details' : 'Show Details'}
                </Button>
              </div>
              <div className='m-auto w-full max-w-[360px] '>
                <Button
                  onClick={() => setEdit(true)}
                  className='!h-[50px] w-full rounded-[12px]  !text-lg  font-bold !leading-[18px]'
                >
                  Edit Details
                </Button>
              </div>
              {userUploadState.error && !userUploadState.error?.isSuccess ? <Error message={userUploadState.error?.error} /> : ''}
              <div className='m-auto grid max-w-[360px] grid-cols-[1fr_auto] gap-[20px]'>
                <Checkbox
                  checked={policy}
                  disabled={userUploadState.status === 'loading'}
                  onChange={(e) => setPolicy(e.target.checked)}
                  className='!h-[18px] !max-w-[18px] cursor-pointer'
                />
                <span className='text-xs font-medium not-italic leading-5 text-[#303030]'>
                  By proceeding, you confirm the gift card details are accurate,
                  authorize GiftXD to charge your card, and acknowledge you've
                  read GiftXD's
                  <span
                    onClick={() => setOpenPolicyMOdal(true)}
                    className='cursor-pointer text-[#0040E3]'
                  >
                    {' '}
                    Privacy Policy{' '}
                  </span>{' '}
                  and{' '}
                  <span
                    onClick={() => setOpenServiceMOdal(true)}
                    className='cursor-pointer text-[#0040E3]'
                  >
                    Terms of Service..
                  </span>{' '}
                </span>
              </div>
              <div className='grid w-full max-w-md grid-cols-2 gap-4 px-2 md:gap-10'>
                <Button
                  disabled={userUploadState.status === 'loading'}
                  onClick={() =>
                    !matchingAttributes?.length
                      ? onBack()
                      : setStep((pre) => pre - 1)
                  }
                  className='rounded-xl py-2 text-xl'
                >
                  <FaAngleLeft className='mr-2 mt-0.5 inline text-2xl' /> Back
                </Button>
                <Button
                  loading={userUploadState.status === 'loading'}
                  disabled={userUploadState.status === 'loading' || !policy}
                  onClick={handleUploadCard}
                  className='rounded-xl py-2 text-xl'
                >
                  Next <FaAngleRight className='ml-2 mt-0.5 inline text-2xl' />{' '}
                </Button>
              </div>
            </>
          </>
        )
      ) : (
        <>
          <GuestCheckoutFourthStep
            cardDetail={extractedData}
            onBack={() => setEdit(false)}
            onNext={onNext}
            setECodeValue={setECodeValue}
            eCodeValue={eCodeValue}
            handleDropDown={handleDropDown}
            handleInput={handleInput}
          // provider={provider}
          />
        </>
      )}

      <Modal
        DialogPanelClass='!max-w-[360px]'
        open={openServiceMOdal}
        setOpen={() => setOpenServiceMOdal(true)}
        title='Terms of Service'
      >
        <div>
          <div
            onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) =>
              handleScroll(e, 'isTermsOfServiceRead')
            }
            className=' mb-[20px] h-[276px] overflow-y-scroll rounded-[9px] border-2 p-[12px]'>
            <b className='mb-[10px] block text-sm font-bold not-italic leading-5 text-black'>
              Tirm 1:
            </b>
            <TermOfService />
          </div>
        </div>
        <div>
          <Button
            disabled={!read.isTermsOfServiceRead}
            onClick={() => setOpenServiceMOdal(false)}
            className='h-[50px] rounded-xl border-solid border-[#0040E3] text-lg  font-bold '
          >
            Done
          </Button>
        </div>
      </Modal>
      <Modal
        DialogPanelClass='!max-w-[360px]'
        open={openPolicyMOdal}
        setOpen={() => setOpenPolicyMOdal(true)}
        title='Privacy Policy'
      >
        <div>
          <div
            onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) =>
              handleScroll(e, 'isPrivacyPolicyRead')
            }
            className=' mb-[20px] h-[276px] overflow-y-scroll rounded-[9px] border-2 p-[12px]'>
            <PrivacyPolicy />
          </div>
        </div>
        <div>
          <Button
            disabled={!read.isPrivacyPolicyRead}
            onClick={() => setOpenPolicyMOdal(false)}
            className='h-[50px] rounded-xl border-solid border-[#0040E3] text-lg  font-bold '
          >
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
}

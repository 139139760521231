import { motion } from 'framer-motion'
import { useEffect } from 'react'

export const motionProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
}

export interface PageProps {
  title?: string
  defaultTitle?: string
  animate?: boolean
  children: React.ReactNode
}

export default function Page(props: PageProps): JSX.Element {
  const { title, defaultTitle = 'GiftXD', animate = true } = props

  useEffect(() => {
    if (title) document.title = title + ' - GiftXD'
    else document.title = defaultTitle
  }, [defaultTitle, title])

  return animate ? <AnimatedPage {...props} /> : <>{props.children}</>
}

function AnimatedPage(props): JSX.Element {
  return <motion.div {...motionProps}>{props.children}</motion.div>
}

import ReactDOM from 'react-dom/client';
import React from 'react';

import 'index.css';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import App from 'App';
import { AuthStateProvider } from 'components/providers/auth-state-provider';
import { QueryClient, QueryClientProvider } from 'react-query';
// import GlobalErrorBoundary from 'components/reusable/global-error-boundary';

const client: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 5,
      retryDelay: 500,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <AuthStateProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </AuthStateProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, apiEndPoints } from 'utils/helper/config';

interface Pagination {
  page: number;
  limit: number;
}
const getBlogsApi = createAsyncThunk(
  'blog/getBlogsApi',
  async (pagination: Pagination, { rejectWithValue, fulfillWithValue }) => {
    try {
      let queryString = '';
      if (pagination) {
        for (let key in pagination) {
          queryString += `${key}=${pagination[key]}&`;
        }
      }
      const response = await apiClient.get(
        apiEndPoints.getBlogs + `${'?' + queryString}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

export { getBlogsApi };

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, apiEndPoints } from 'utils/helper/config';

interface vendorOfferOrderProps {
  page_size?: number;
  page_number?: number;
  status?: string;
}

const createNewOfferApi = createAsyncThunk(
  'myOffer/createNewOfferApi',
  async (
    { payload }: { payload: any },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {

      const response = await apiClient.post(apiEndPoints.createOffer, payload);
      const { data } = response;
      await dispatch(myOffersAllApi({ page_number: 1, page_size: 10, status: '' }))
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);


const vendorOfferOrdersApi = createAsyncThunk(
  'myOffer/vendorOfferOrdersApi',
  async (
    { page_number, page_size, status }: vendorOfferOrderProps,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {

      const response = await apiClient.get(
        `${apiEndPoints.vendorOfferOrders}?page_number=${page_number}&page_size=${page_size}&status=${status}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const myOffersAllApi = createAsyncThunk(
  'myOffer/myOffersAllApi',
  async (
    { page_number, page_size, status }: vendorOfferOrderProps,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${apiEndPoints.myOffersAll}?page_number=${page_number}&page_size=${page_size} ${status ? '&status=' + status : ''}`
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);

const updateOfferApi = createAsyncThunk(
  'myOffer/updateOfferApi',
  async (
    { payload }: { payload: any },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await apiClient.put(apiEndPoints.updateOffer, payload);
      const { data } = response;
      const filterCondition = payload.status === 'Active' ? '' : payload.status.toUpperCase()
      await dispatch(myOffersAllApi({ page_number: 1, page_size: 10, status: filterCondition }))
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
const vendorOrdersReceiptApi = createAsyncThunk(
  'myOffer/vendorOrdersReceiptApi',
  async (
    { id }:{id:string} ,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        apiEndPoints.viewVendorOrderReceipt +`?id=`+id
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error: any) {
      const { data } = error.response;
      return rejectWithValue(data);
    }
  }
);
export { createNewOfferApi, vendorOfferOrdersApi, myOffersAllApi, updateOfferApi,vendorOrdersReceiptApi };




import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import React, { Children, Fragment } from 'react';
import cn from 'classnames';
import CancelIcon from 'assets/icons/cancel.svg';
import MenuIcon from 'assets/icons/manuIcon.svg';
Menu.Item = Item
Menu.Button = HeadlessMenu.Button
interface MenuProps {
  children?: React.ReactNode;
  altButton?: any;
}


export default function Menu({ children, altButton }: MenuProps) {
  return (
    <div className="relative">
      <HeadlessMenu>
        {({ open }) => (
          <>
            {altButton ? (
              altButton
            ) : (
              <HeadlessMenu.Button
                className={
                  cn(
                    'flex h-11 w-11 items-center justify-center rounded-xl bg-white text-sm',
                    'border-2 border-gray-100 text-gray-300',
                    'focus:outline-none'
                  )}
              >
                <span className="sr-only">Open user menu</span>
                <img
                  src={open ? CancelIcon : MenuIcon}
                  alt=""
                />
              </HeadlessMenu.Button>
            )}
            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <HeadlessMenu.Items
                className="absolute right-0 mt-[16px] md:w-[calc(100vw-_45px)] sm:w-[calc(100vw-_45px)] w-[calc(100vw-_30px)] z-100 lg:w-[350px] xl:w-[360px] origin-top-right rounded-md bg-white py-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-[#141B25]"
              >
                {Children.map(children, (child, i) => (
                  <HeadlessMenu.Item key={'md-nav-items-' + i}>{child}</HeadlessMenu.Item>
                ))}
              </HeadlessMenu.Items>
            </Transition>
          </>
        )}
      </HeadlessMenu>
    </div>
  );
}

interface ItemProps {
  active?: boolean;
  children?: React.ReactNode;
  to?: string;
  as?: any;
  className?: string;
}

function Item({ active, children, to, className, ...initProps }: ItemProps & any) {
  const { as, ...props } = initProps;

  const renderProps = {
    to: to,
    className: cn(
      'block w-full px-[20px] py-[16px] text-left hover:bg-gray-100',
      active ? 'font-bold text-blue-500' : 'font-medium text-gray-950',
      className
    ),
    ...props
  };

  return as ? (
    <initProps.as {...renderProps}>{children}</initProps.as>
  ) : (
    <div {...renderProps}>{children}</div>
  );
}

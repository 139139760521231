import { ToastrError, ToastrSuccess } from 'components/ui/toastr';
import { useEffect } from 'react';
import { useAppDispatch } from 'store/store';

const useApiErrorHandling = (apiStatuses) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    apiStatuses.forEach(
      ({ status, successMessage, errorMessage, clearState }) => {
        if (status === 'success') {
          if(successMessage){
            ToastrSuccess(successMessage);
          }
          if (typeof clearState !== 'undefined') {
            dispatch(clearState());
          }
        } else if (status === 'error') {
          if(errorMessage){
            ToastrError(errorMessage);
          }
          if (typeof clearState !== 'undefined') {
            setTimeout(() => {
            dispatch(clearState());
            }, 5000);
          }
        }
      }
    );
  }, [apiStatuses, dispatch]);
};

export default useApiErrorHandling;

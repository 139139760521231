import React, { memo, useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import HomeIcon from 'assets/icons/home-Icon.svg';
import SellGiftCardIcon from 'assets/icons/SellGiftCardIcon.svg';
import ConnectedAccountsIcon from 'assets/icons/ConnectedAccounts.svg';
import OrderHistoryIcon from 'assets/icons/OrderHistoyIcon.svg';
import InstaOfferIcon from 'assets/icons/InstaOffer-icon.svg';
import OpenOrdersIcon from 'assets/icons/Open-Orders-icon.svg';
import MyWalletsIcon from 'assets/icons/wallet.svg';
import KYCLimitsIcon from 'assets/icons/KYC-My-Limits.svg';
import SecurityIcon from 'assets/icons/Security-icon.svg';
import AccountSettingsIcon from 'assets/icons/Account-Settings-icon.svg';
import AffiliatePortalIcon from 'assets/icons/Affiliate-Portal-icon.svg';
import BecomeVendorIcon from 'assets/icons/Become-Vendor-icon.svg';
import StatusIcon from 'assets/icons/Status-icon.svg';
import SubmitSuggestionIcon from 'assets/icons/Submit-Suggestion-icon.svg';
import ContactIcon from 'assets/icons/Contact-icon.svg';
import BecomeVendor from './become-vendor';
import { dashBoardRoutes, publicRoutes } from 'routes/routes';
import { useSelector } from 'react-redux';
import { userProfileSelector } from 'features/public/slices/homeSlice';

const SIDE_NAV_LINKS = [
  { name: 'Dashboard', to: '/dashboard', icon: HomeIcon },
  {
    name: 'Sell Your Gift Card',
    to: publicRoutes.home.path,
    icon: SellGiftCardIcon,
  },
  {
    name: 'InstaOffer',
    to: dashBoardRoutes.instaOffer.path,
    icon: InstaOfferIcon,
  },
  {
    name: 'Open Orders',
    to: dashBoardRoutes.sellerOpenOrder.path,
    icon: OpenOrdersIcon,
  },
  {
    name: 'Order History',
    to: dashBoardRoutes.transactionHistory.path,
    icon: OrderHistoryIcon,
  },
  {
    name: 'My Wallets',
    to: dashBoardRoutes.myWallet.path,
    icon: MyWalletsIcon,
  },
  {
    name: 'KYC & My Limits',
    to: dashBoardRoutes.myLimitKyc.path,
    icon: KYCLimitsIcon,
  },
  {
    name: 'Security & 2FA',
    to: dashBoardRoutes.security2fa.path,
    icon: SecurityIcon,
  },
  {
    name: 'Account Settings',
    to: dashBoardRoutes.accountSetting.path,
    icon: AccountSettingsIcon,
  },
  {
    name: 'Affiliate Portal',
    to: dashBoardRoutes.affilatePortal.path,
    icon: AffiliatePortalIcon,
  },
  { name: 'Become A Vendor', to: '', icon: BecomeVendorIcon },
  { name: 'Status', to: dashBoardRoutes.status.path, icon: StatusIcon },
  {
    name: 'Submit Suggestion',
    to: dashBoardRoutes.submitSuggestion.path,
    icon: SubmitSuggestionIcon,
  },
  { name: 'Contact', to: dashBoardRoutes.contactUs.path, icon: ContactIcon },
];
const VENDOR_SIDE_NAV_LINKS = [
  { name: 'Dashboard', to: '/dashboard', icon: HomeIcon },
  {
    name: 'Sell Your Gift Card',
    to: publicRoutes.home.path,
    icon: SellGiftCardIcon,
  },
  {
    name: 'InstaOffer',
    to: dashBoardRoutes.instaOffer.path,
    icon: InstaOfferIcon,
  },
  {
    name: 'My Offers',
    to: dashBoardRoutes.myoffers.path,
    icon: InstaOfferIcon,
  },
  {
    name: 'Connected Accounts',
    to: dashBoardRoutes.connectedAccounts.path,
    icon: ConnectedAccountsIcon,
  },
  {
    name: 'Open Orders',
    to: dashBoardRoutes.openOrders.path,
    icon: OpenOrdersIcon,
  },
  {
    name: 'Order History',
    to: dashBoardRoutes.orderHistory.path,
    icon: OrderHistoryIcon,
  },
  {
    name: 'My Wallets',
    to: dashBoardRoutes.myWallet.path,
    icon: MyWalletsIcon,
  },
  {
    name: 'Affiliate Portal',
    to: dashBoardRoutes.affilatePortal.path,
    icon: AffiliatePortalIcon,
  },
  {
    name: 'Submit Suggestion',
    to: dashBoardRoutes.submitSuggestion.path,
    icon: SubmitSuggestionIcon,
  },
  {
    name: 'Contact & Support',
    to: dashBoardRoutes.contactUs.path,
    icon: ContactIcon,
  },
  {
    name: 'KYC & My Limits',
    to: dashBoardRoutes.myLimitKyc.path,
    icon: KYCLimitsIcon,
  },
  {
    name: 'Security & 2FA',
    to: dashBoardRoutes.security2fa.path,
    icon: SecurityIcon,
  },
  {
    name: 'Account Settings',
    to: dashBoardRoutes.accountSetting.path,
    icon: AccountSettingsIcon,
  },
];

export default memo(function Sidenav() {
  const { pathname } = useLocation();
  const { data: USER_DETAILS } = useSelector(userProfileSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleModal = useCallback(() => {
    setIsOpen(false);
  }, []);
  const navLinks =
    USER_DETAILS?.role?.name === 'CLIENT'
      ? SIDE_NAV_LINKS
      : VENDOR_SIDE_NAV_LINKS;

  return (
    <>
      <div className='top-[100px] w-[100%] max-w-[100%] md:w-[100%] md:max-w-[100%] lg:sticky lg:w-[300px]'>
        <aside
          className=' w-[100%] max-w-[100%] md:w-[100%] md:max-w-[100%] lg:w-[300px]'
          aria-label='Sidebar '
        >
          <div className=' overflow-y-auto rounded-md border-2 border-solid border-[#F1F1F1] px-[15px] py-[30px] shadow-[0px_0px_6px_0px_rgba(0,0,0,0.04)] '>
            <ul className='space-y-2 font-medium sm:flex sm:flex-wrap sm:gap-x-[20px] md:flex  md:flex-wrap md:gap-x-[30px] lg:block'>
              {navLinks.map((i, idx) => (
                <li
                  key={idx}
                  className={` relative  !mb-[18px] w-full max-w-[100%] rounded px-[15px] py-[8px]  sm:max-w-[calc(100%/2_-_20px)]  md:max-w-[calc(100%/3_-_20px)] lg:max-w-[100%] ${
                    i.to === pathname &&
                    ' bg-[#D0DDFF] after:absolute after:left-0 after:top-[11px] after:h-5 after:w-[3.25px] after:shrink-0 after:rounded-[0px_44px_22px_0px] after:bg-[#0040E3] after:content-[""]'
                  }`}
                >
                  {i.name === 'Become A Vendor' ? (
                    <span
                      onClick={() => setIsOpen(true)}
                      className={` flex cursor-pointer items-center gap-[10px] text-lg font-medium not-italic leading-[18px] tracking-[0.18px] text-[#797979]  ${
                        i.to === pathname && 'text-blue-700'
                      } `}
                    >
                      <img
                        src={i.icon}
                        alt='HomeIcon'
                        className={`h-[18px] w-[18px]  ${
                          i.to === pathname &&
                          'brightness-[91%] contrast-[101%] hue-rotate-[227deg] invert-[14%] saturate-[6303%] sepia-[92%]'
                        } `}
                      ></img>
                      <span className='flex w-full items-center justify-between text-[16px]'>
                        {i.name}
                      </span>
                    </span>
                  ) : i.name === 'InstaOffer' ? (
                    <button
                      disabled
                      className={` flex  w-full items-center gap-[10px] font-medium not-italic leading-[18px] tracking-[0.18px] text-[#797979] disabled:cursor-not-allowed md:text-[16px] lg:text-lg  ${
                        i.to === pathname && 'text-blue-700'
                      } `}
                    >
                      <img
                        src={i.icon}
                        alt='HomeIcon'
                        className={`h-[18px] w-[18px]  ${
                          i.to === pathname &&
                          'brightness-[94%] contrast-[101%] hue-rotate-[226deg] invert-[9%] saturate-[20000%] sepia-[0%]'
                        } `}
                      ></img>
                      <span className=' flex w-full  items-center justify-between text-[16px]'>
                        {i.name}{' '}
                        {i.name === 'InstaOffer' && (
                          <span className='flex h-[20px] w-full max-w-[80px] items-center justify-center rounded-[100px] bg-[#d1ddfd] text-[9px] font-semibold uppercase text-[#2c3ade]'>
                            coming soon
                          </span>
                        )}
                      </span>
                    </button>
                  ) : i.name === 'Affiliate Portal' ? (
                    <button
                      disabled
                      className={` flex  w-full items-center gap-[10px] font-medium not-italic leading-[18px] tracking-[0.18px] text-[#797979] disabled:cursor-not-allowed md:text-[16px] lg:text-lg  ${
                        i.to === pathname && 'text-blue-700'
                      } `}
                    >
                      <img
                        src={i.icon}
                        alt='HomeIcon'
                        className={`h-[18px] w-[18px]  ${
                          i.to === pathname &&
                          'brightness-[94%] contrast-[101%] hue-rotate-[226deg] invert-[9%] saturate-[20000%] sepia-[0%]'
                        } `}
                      ></img>
                      <span className=' flex w-full  items-center justify-between text-[16px]'>
                        {i.name}{' '}
                        {i.name === 'Affiliate Portal' && (
                          <span className='flex h-[20px] w-full max-w-[80px] items-center justify-center rounded-[100px] bg-[#d1ddfd] text-[9px] font-semibold uppercase text-[#2c3ade]'>
                            coming soon
                          </span>
                        )}
                      </span>
                    </button>
                  ) : (
                    <Link
                      to={i.to}
                      className={`flex items-center gap-[10px] font-medium not-italic leading-[18px] tracking-[0.18px] text-[#797979] md:text-[16px] lg:text-lg  ${
                        i.to === pathname && 'text-blue-700'
                      } `}
                    >
                      <img
                        src={i.icon}
                        alt='HomeIcon'
                        className={`h-[18px] w-[18px]  ${
                          i.to === pathname &&
                          'brightness-[94%] contrast-[101%] hue-rotate-[226deg] invert-[9%] saturate-[20000%] sepia-[0%]'
                        } `}
                      ></img>
                      <span className=' flex w-full items-center justify-between text-[16px]'>
                        {i.name}{' '}
                      </span>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </aside>
        <BecomeVendor open={isOpen} setOpen={handleModal} />
      </div>
    </>
  );
});

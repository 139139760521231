import { getLocalStorage, storageKeys } from 'utils/helper/storage';
import axios from 'axios';

export const serverAPIUrl = 'https://stagingbackend.giftxd.com';
export const apiClient = axios.create({
  baseURL: serverAPIUrl,
  headers: {
    'X-Real-IP': '127.0.0.1',
    'Content-type': 'application/json',
  },
});

/* Handle  requests */
apiClient.interceptors.request.use(
  (config) => {
    const token = getLocalStorage(storageKeys.jwtToken)
      ? getLocalStorage(storageKeys.jwtToken)
      : '';
    config.headers['x-access-token'] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* Handle  responses */
apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.error(
      'Error in the Api',
      error?.response?.data?.message
        ? error.response.data.message
        : error?.data?.message
        ? error.data.message
        : 'Something went wrong'
    );
    return Promise.reject(error);
  }
);

/*---------------------------------------------------*/

export const apiEndPoints = {
  // Auth
  signUpEmail: '/website/user/signup',
  authenticateUser: '/website/user/login',
  signUpUser: '/website/user/signup',
  forgotPassword: '/website/user/forgetPassword',
  resetPassword: '/website/user/resetPassword',
  signupVerifyEmail: '/website/verify-email',
  createUser: 'website/user/create',
  
  // 2Fa  for for seller
  verifyTwoFA: 'website/verifyTwoFA',
  enableTwoFA: 'website/enableTwoFA',
  disableTwoFA: 'website/disableTwoFA',
 twoFaSetting:'/website/twoFASettings',
  // secutity and 2fa page
  enableTwoFASecurty:'/2fa/enable/',  
  verifyTwoFASecurty:'/2fa/verify/',
  disableTwoFASecurty:'/2fa/disable/',
  //Home and checkout
  giftCards: '/provider/getAll',
  providerDetail: '/provider/provider-details',
  cryptoCards: '/crypto-cards',
  cryptoPrice: '/crypto',
  currency_codes: '/currency-codes',
  attributeGetAll: '/attribute/getAll',
  textExtract: '/textract/extract-text',
  sellerOffers: '/seller/offer',
  userUploadCard: '/seller/uploadGiftCard',
  sendOtp: '/otp/send',
  verifyOtp: '/otp/verify',
  profile: '/website/user/profile',
  updateSellerOffer: '/seller/update',
  contactUs: '/contact-us/messages',

  //Blog
  getBlogs: '/blogs',

  //dashboard
  getTransactions: '/transaction/all',
  getSellerRecommdation: '/offer/getRecommdation',
  submitSuggestion: 'suggestioncard/create',

  // wallet and account
  accountSettings: '/settings',
  internalWalletBalance: '/wallet/internal/balance',
  pendingWalletBalance: '/wallet/external/pendingBalance',
  walletTransaction: '/wallet/external/transfer',
  internalWalletTransfer: 'wallet/internal/transfer',
  externalWithdrawal: '/wallet/external/withdrawal',
  getWalletAddress: '/wallet/external/get-wallet-address',
  verifyId: '/client/verify-identity',

  // connected accounts,
  getAllLinkedAccount: '/linkedAccount/getAll',
  LinkNewAccounts: '/linkedAccount/create',
  updateLinkedAccount: '/linkedAccount/update',
  deleteLinkedAccount: '/linkedAccount/delete',

  // order
  createOrder: '/order/create',
  viewVendorOrderReceipt: '/offer/orderGetById',
  // my-offers
  createOffer: '/offer/create',
  vendorOfferOrders: '/offer/orders',
  myOffersAll: '/offer/getAll',
  updateOffer: '/offer/update',

  // account setting
  verifyEmail: '/client/send-verification-email',
  verifyEmailOtp: '/client/verify-email',
  updateProfile: '/website/user/profile/upload',

  // seller
  sellerOrdersHistory: '/seller/orders',
  viewSellerOrderReceipt: '/seller/OrderById',

  // vendor
  createVendor: '/vendor/create',
  // accepted-cards
  acceptedCards:'/accepted-cards',

  
};

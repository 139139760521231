import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSignal } from '@preact/signals-react';

import PinInput from 'components/ui/pin-input';
import Button from 'components/ui/button';
import Heading from 'components/ui/heading';
import Checkbox from 'components/ui/checkbox';
import { useAppDispatch } from 'store/store';
import {
  clearCheckOutStatus,
  sendOtpSelector,
  verifyOtpSelector,
} from 'features/app/slices/checkoutSlice';
import { useSelector } from 'react-redux';
import { sendOtpApi, verifyOtpApi } from 'features/app/actions/checkoutAction';
import { Country } from 'country-state-city';
import Error from 'components/ui/error';
import CountryDropDown from 'components/ui/countrydropdown';
import QuestioMark from 'assets/icons/questionmark.svg';
import { motionProps } from 'components/ui/page';
import { userProfileSelector } from 'features/public/slices/homeSlice';

export default function CheckoutThirdStep({ onBack, onNext }) {
  const { data: USER_DETAILS } = useSelector(userProfileSelector);
  const dispatch = useAppDispatch();
  const country = Country.getAllCountries();
  const sendOptState = useSelector(sendOtpSelector);
  const [step, setStep] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState<any>('');
  const [activismsMethod] = useState<'sms' | 'whatsapp'>(
    'sms'
  );
  const [selectedCountry, setSelectedCountry] = useState<any>(country);

  const [errors, setErrors] = useState<any>({ isError: false, message: '' });
  useEffect(() => {
    if (USER_DETAILS?.phoneVerified) {
      onNext();
    }
    if (sendOptState.status === 'success') {
      if (sendOptState.data?.status === 'pending') {
        setStep((prev) => prev + 1);
        dispatch(clearCheckOutStatus())
      }
    }
  }, [sendOptState.status, onNext, USER_DETAILS?.phoneVerified, dispatch, sendOptState.data?.status]);

  function handleSendCode() {
    if (phoneNumber.length > 0 && selectedCountry?.phonecode !== undefined) {
      setErrors({ isError: false, message: '' });
      dispatch(
        sendOtpApi({
          phoneNumber: `${selectedCountry?.phonecode + phoneNumber}`,
        })
      );
    } else if (phoneNumber.length === 0) {
      const error = {
        isError: true,
        message: 'Number is required',
      };
      setErrors(error);
    } else if (selectedCountry?.phonecode === undefined) {
      const error = {
        isError: true,
        message: 'Please select Country',
      };
      setErrors(error);
    }
  }

  const handleCountryChange = (e) => {
    const selectedCountryCode = e?.currency;
    const newSelectedCountry = country.find(
      (country) => country.currency === selectedCountryCode
    );
    if (
      newSelectedCountry?.phonecode &&
      !newSelectedCountry?.phonecode.startsWith('+')
    ) {
      newSelectedCountry.phonecode = `+${newSelectedCountry.phonecode}`;
    }
    setSelectedCountry(newSelectedCountry);
  };

  return (
    <>
      <AnimatePresence mode='wait'>
        <motion.div
          className='flex w-full max-w-md flex-col gap-5 px-2'
          {...motionProps}
          key={step + ''}
        >
          {step === 0 ? (
            <>
              <Heading
                size='h3'
                className='mb-[70px] text-center !text-xl font-bold not-italic leading-5 text-black'
              >
                {activismsMethod === 'sms'
                  ? 'Enter Phone Number'
                  : 'Enter WhatsApp Number'}
              </Heading>

              {/* <div className='m-auto mb-[40px] flex h-[50px] w-[260px] items-center justify-evenly gap-10 rounded-xl bg-[#F1F1F1] p-0 md:w-[360px] lg:w-[360px] xl:w-[360px]'>
                <Button
                  onClick={() => setActiveSmsMethod('sms')}
                  className={` ${activismsMethod === 'sms'
                    ? 'bg-blue-400  focus:!ring-0'
                    : '!bg-transparent !text-gray-400'
                    }`}
                >
                  SMS
                </Button>
                <Button
                  onClick={() => setActiveSmsMethod('whatsapp')}
                  className={` ${activismsMethod === 'whatsapp'
                    ? 'bg-blue-400 focus:!ring-transparent'
                    : '!bg-transparent !text-gray-400'
                    }`}
                >
                  WhatsApp
                </Button>
              </div> */}
              {errors.isError === true ? (
                <motion.p className='text-red-500'>{errors.message}</motion.p>
              ) : (
                ''
              )}
              {sendOptState.status === 'error' && (
                <Error message={sendOptState.error?.error} />
              )}
              <div
                className={`${ errors.isError === true
                  ? ' rounded-lg border-[2px] border-red-700 '
                  : ''
                  }`}
              >
                <div className='flex cursor-text  items-center rounded-md border-2 border-gray-100'>
                  <div className='w-full max-w-[250px]'>
                    <CountryDropDown
                      currencyFlag={false}
                      startIcon={QuestioMark}
                      placeHolder='Select Country'
                      className='!border-none'
                      searchIcon={false}
                      onSelect={handleCountryChange}
                      value={selectedCountry?.name}
                      options={country}
                      label='name'
                    />
                  </div>
                  {selectedCountry?.phonecode && (
                    <p className='w-full max-w-[75px] font-bold'>
                      {selectedCountry?.phonecode}
                    </p>
                  )}
                  <div className='flex h-[100%] w-full items-center justify-between'>
                    <input
                      type='text'
                      placeholder={
                        activismsMethod === 'sms'
                          ? 'Phone Number'
                          : 'WhatsApp Number'
                      }
                      value={phoneNumber.replace(/[^0-9 ]/g, '')}
                      className={`h-full w-full max-w-[calc(100%_-_40px)] !border-0 bg-transparent px-0 py-0 text-base font-bold  outline-none placeholder:font-normal disabled:cursor-not-allowed md:px-3`}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      maxLength={10}
                    />
                  </div>
                </div>
              </div>
              <Button
                loading={sendOptState.status === 'loading'}
                disabled={sendOptState.status === 'loading'}
                onClick={handleSendCode}
                className='!hover:bg-blue-100 rounded-xl !bg-blue-50 py-3 text-lg'
              >
                <span className='!text-blue-500'>Send Code</span>
              </Button>
              {/* <div className=''>
                <span className='mb-[10px] block text-center text-xs font-normal not-italic leading-3 text-[#383838]'>
                  Want to use WhatsApp instead? Click
                  <span className='cursor-pointer text-xs text-[#0040E3]'>
                    {' '}
                    here.
                  </span>
                </span>
                <span className='block text-center text-xs font-normal not-italic leading-3 text-[#383838]'>
                  Don’t have WhatsApp? Click
                  <span className='cursor-pointer text-xs text-[#0040E3]'>
                    {' '}
                    here.
                  </span>
                </span>
              </div> */}
              <div className='flex flex-cols-[1fr_auto] gap-[20px] mb-[70px]'>
                <Checkbox className='!h-[24px] !max-w-[24px] !rounded-lg cursor-pointer mt-2' />
                <span className='text-[16px] font-medium not-italic leading-8 text-[#303030]'>
                  I consent to receive text messages from GiftXD. Standard
                  messaging rates may apply.
                </span>
              </div>
              <div className='grid w-full max-w-md grid-cols-2 gap-4 px-2 md:gap-10'>
                <Button
                  onClick={() => {
                    onBack()
                  }}
                  className='rounded-xl py-2 text-xl'
                >
                  <FaAngleLeft className='mr-2 mt-0.5 inline text-2xl' /> Back
                </Button>
                <Button
                  disabled
                  className='rounded-xl py-2 text-xl'
                >
                  Next
                  <FaAngleRight className='ml-2 mt-0.5 inline text-2xl' />
                </Button>
              </div>
            </>
          ) : (
            <VerifyOTP countryPhoneCode={selectedCountry?.phonecode} phoneNumber={phoneNumber} sendCodeAgain={handleSendCode} onNext={onNext} setStep={setStep} />
          )}
        </motion.div>
      </AnimatePresence>

    </>
  );
}

const VerifyOTP: FC<{ countryPhoneCode: any, phoneNumber: any, sendCodeAgain: Function, onNext: any, setStep: any }> = ({ countryPhoneCode, phoneNumber, sendCodeAgain, onNext, setStep }): JSX.Element => {
  const dispatch = useAppDispatch()
  const verifyOptState = useSelector(verifyOtpSelector);
  const [verified, setVerified] = useState(false);
  const [resent, setResent] = useState(false);
  const pin = useSignal('');
  const [counter, setCounter] = useState(60)

  const handlePinChange = useCallback((value: string) => {
    pin.value = value;
  }, [pin])

  function handleVerify() {
    const payload = {
      phoneNumber: `${(countryPhoneCode) + (phoneNumber)}`,
      otpCode: pin.value,
    };
    if (payload.phoneNumber) {
      dispatch(verifyOtpApi(payload));
    }
  }
  function handleResend() {
    sendCodeAgain()
    setResent(true);
  }
  useEffect(() => {
    if (verifyOptState.status === 'success') {
      setVerified(true);
      onNext();
    }
    let interval: NodeJS.Timeout
    if (resent) {
      interval = setInterval(() => {
        if (counter === 1) {
          setCounter(60)
          setResent(false)
        } else {
          setCounter(pre => pre - 1)
        }
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [counter, onNext, resent, verifyOptState.status])

  return (
    <>
      <div className='mb-[180px]'>
        <Heading
          size='h3'
          className='text-center !text-xl font-bold not-italic leading-5 text-black'
        >
          Verify Code
        </Heading>
      </div>
      <PinInput
        verified={verified}
        onPinChange={handlePinChange}
        length={6}
        initialValue={pin}
      />

      <Button
        type='button'
        loading={verifyOptState.status === 'loading'}
        disabled={
          verifyOptState.status === 'loading' ||
          verifyOptState.data?.status === 'approved'
          || !pin.value
        }
        onClick={handleVerify}
        className='!hover:bg-blue-100 rounded-xl !bg-blue-50 py-3 text-lg'
      >
        <span className='text-blue-500'>Verify Code</span>
      </Button>
      {!resent ? (
        <>
          <div className='mb-[179px] text-center text-black'>
            Need a new code? Click{' '}
            <span
              onClick={handleResend}
              className='cursor-pointer text-blue-500 hover:text-blue-600'
            >
              here
            </span>
            .
            {verifyOptState.status === 'error' && <p className='text-[#C64848] text-xs not-italic font-medium leading-4 mt-2'>The code you entered appears to be invalid or contains errors. Please double-check the code and try again. (2 Attempts Remaining)</p>}

          </div>

        </>


      ) : (
        <div className='mb-[179px]'>
          <span className='text-center text-[#0040E3] block mb-[10px]  font-medium'>New code sent!!</span>
          <span className='block text-center '>You can  request new code in <span className='text-[#0040E3] font-medium'>{counter} seconds</span></span>
        </div>
      )
      }
      <div className='grid w-full max-w-md grid-cols-2 gap-4 px-2 md:gap-10'>
        <Button
          onClick={() => {
            setStep(0);
          }}
          className='rounded-xl py-2 text-xl'
        >
          <FaAngleLeft className='mr-2 mt-0.5 inline text-2xl' /> Back
        </Button>
        <Button
          onClick={onNext}
          disabled={!verified}
          className='rounded-xl py-2 text-xl'
        >
          Next
          <FaAngleRight className='ml-2 mt-0.5 inline text-2xl' />
        </Button>
      </div>
    </>
  )
}
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { motion, useAnimation } from 'framer-motion';
import Logo from 'components/ui/logo';
import { useAuthContext } from './auth-state-provider';
import { getStorage, setStorage } from 'utils/helper/storage';

interface IPreloaderStateContext {
  preloaderAnimate: (initial?: any) => void;
}

export const PreloaderStateContext = createContext<
  IPreloaderStateContext | undefined
>(undefined);

interface PreloaderStateProviderProps {
  children: React.ReactNode;
}

export function PreloaderStateProvider(
  props: PreloaderStateProviderProps
): JSX.Element {
  const { isAuthenticated } = useAuthContext();
  const [isPreloaderActive, setIsPreloaderActive] = useState(true);
  const whiteBgControls = useAnimation();
  const logoDivControls = useAnimation();
  const blueBgControls = useAnimation();
  const countdownRef = useRef<any>(null);

  const whiteBgAnimate = useCallback(
    async (initial = false) => {
      await whiteBgControls.start({
        opacity: [initial ? 1 : 0, 1, 1, 0],
        pointerEvents: [initial ? 'auto' : 'none', 'auto', 'auto', 'none'],
        transition: { duration: 2, times: [0, 0.125, 0.875, 1] },
      });
    },
    [whiteBgControls]
  );

  const logoDivAnimate = useCallback(async () => {
    await logoDivControls.start({
      width: ['0%', '100%', '100%', '0%'],
      transition: { duration: 1.5, times: [0, 0.167, 0.833, 1] },
    });
  }, [logoDivControls]);

  const blueBgAnimate = useCallback(async () => {
    await blueBgControls.start({
      height: ['0%', '100%', '100%', '0%'],
      transition: { duration: 1.5, times: [0, 0.7, 0.999, 1] },
    });
  }, [blueBgControls]);

  const updateCountdown = useCallback(
    (count: string | number) => {
      if (countdownRef.current) countdownRef.current.innerHTML = count;
    },
    [countdownRef]
  );

  const preloaderAnimate = useCallback(
    (initial = false) => {
      if (isAuthenticated) {
        setIsPreloaderActive(false);
        return;
      }

      whiteBgAnimate(initial);
      updateCountdown('');

      setTimeout(() => {
        logoDivAnimate();
      }, 250);

      setTimeout(() => {
        blueBgAnimate();
      }, 500);

      setTimeout(() => {
        let count = 0;
        const interval = setInterval(() => {
          if (count > 100) {
            clearInterval(interval);
          } else {
            const innerHTML =
              (count + '').length === 1
                ? '00' + count
                : (count + '').length === 2
                  ? '0' + count
                  : count;
            updateCountdown(innerHTML);
            count += 2;
          }
        }, 10);
      }, 500);
    },
    [
      isAuthenticated,
      whiteBgAnimate,
      logoDivAnimate,
      blueBgAnimate,
      updateCountdown,
    ]
  );

  useEffect(() => {
    const loader = getStorage('loader');
    if (loader === null) {
      preloaderAnimate(true);
      setTimeout(() => {
        setStorage('loader', true);
      }, 2000);
    } else {
      setIsPreloaderActive(false);
    }
  }, [preloaderAnimate, isPreloaderActive]);

  const values = { preloaderAnimate };

  return (
    <PreloaderStateContext.Provider value={values}>
      {isPreloaderActive && (
        <motion.div
          animate={whiteBgControls}
          initial={{ opacity: 1, pointerEvents: 'auto' }}
          className='fixed left-0 top-0 z-[99999] flex h-full w-full flex-col bg-white'
        >
          <motion.div
            animate={blueBgControls}
            className='absolute w-full bg-blue-50'
          />
          <motion.div
            animate={logoDivControls}
            initial={{ width: '0%' }}
            className='absolute flex h-full items-center justify-center self-end'
          >
            <h1
              ref={countdownRef}
              className='font-[Clash Display] text-8rem font-black text-gray-100 sm:text-[10rem] lg:text-[15rem]'
            >
              {' '}
            </h1>
          </motion.div>
          <motion.div
            animate={logoDivControls}
            initial={{ width: '0%' }}
            className='absolute flex h-full items-center justify-center'
          >
            <Logo className='absolute h-12 sm:h-14 lg:h-20' />
          </motion.div>
        </motion.div>
      )}

      {props.children}
    </PreloaderStateContext.Provider>
  );
}

export function usePreloaderContext(): IPreloaderStateContext {
  const context = useContext<IPreloaderStateContext | undefined>(
    PreloaderStateContext
  );
  if (!context) {
    throw new Error(
      'usePreloaderContext cannot be used outside of a PreloaderStateProvider'
    );
  }
  return context;
}

import { motion, useAnimation } from 'framer-motion';
import React, { memo, useEffect, useState } from 'react';

import Button from 'components/ui/button';
import Modal from 'components/ui/modal';

import congratulation from 'assets/images/congratulaton.svg';
import signOk from 'assets/icons/signOk.svg';
import DocumentsIcon from 'assets/icons/Documents-icon.svg';
import CheckIcon from 'assets/icons/check-icons-gray.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { createVendorApi } from 'features/app/actions/vendorAction';
import { useSelector } from 'react-redux';
import { createVendorSelector } from 'features/app/slices/vendorSlice';
import { onErrorMsg } from 'utils/constants';
import useApiErrorHandling from 'hooks/useApiErrorHandling';
import { updateProfileDetail, userProfileSelector } from 'features/public/slices/homeSlice';
interface BecomeVendorProps {
  open: boolean;
  setOpen: Function;
}

const BecomeVendor: React.FC<BecomeVendorProps> = ({
  open,
  setOpen,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { status, error } = useSelector(createVendorSelector)
  const { data: USER_DETAILS } = useSelector(userProfileSelector);
  const animation = useAnimation();
  const [step, setStep] = useState<number>(0);

  const handleSteps = () => {
    if (step === 0) {
      dispatch(createVendorApi())
    } else {
      navigate('/')
      setOpen();
      setStep(0);
    }
  };

  const apiStatuses = [
    {
      status: status,
      successMessage: 'You Become A Vendor Successfully',
      errorMessage: onErrorMsg(error),
    },]

  useApiErrorHandling(apiStatuses)

  useEffect(() => {
    if (status === 'success') {
      setStep((pre) => pre + 1);
      let newRole = { ...USER_DETAILS.role }
      newRole = { ...newRole, name: 'VENDOR', permissions: newRole.permissions }
      const payload = { role: newRole }
      dispatch(updateProfileDetail(payload))
    }
  }, [USER_DETAILS.role, dispatch, status])

  return (
    <>
      <Modal
        title='Become A Vendor'
        cancel={true}
        open={open}
        setOpen={setOpen}
      >
        <motion.div animate={animation}>
          {USER_DETAILS?.email_verified &&
            USER_DETAILS?.idVerified &&
            USER_DETAILS?.phoneVerified ? (
            step === 0 ? (
              <BecomeAVendorFirstStep onNext={handleSteps} status={status} />
            ) : (
              <>
                <div className='mb-[30px] flex justify-center'>
                  <img src={congratulation} alt='congratulation' />
                </div>
                <div className='m-auto mb-[30px] w-full max-w-[296px] text-center'>
                  <h3 className='mb-[20px] text-center xl:text-[32px] lg:text-[32px] md:text-[28px] text-[24px] font-bold not-italic leading-8 text-black'>
                    Congratulations!
                  </h3>
                  <p className='text-center xl:text-lg lg:text-lg  text-base font-medium not-italic leading-[18px] text-[#464646]'>
                    You have been instantly approved to become a vendor.
                  </p>
                </div>
                <div className='rounded-[8px] bg-[#ECF1FF] px-[10px] py-[15px]'>
                  <h4 className='mb-[20px] text-lg font-bold not-italic leading-[18px] text-black'>
                    You now have access to:
                  </h4>
                  <div>
                    <div className='mb-[20px]  flex items-center gap-[10px]'>
                      <img src={signOk} alt='signOk' />
                      <div className=''>
                        <span className='text-base font-bold not-italic leading-4 text-black'>
                          My Offers
                        </span>
                        <span className='text-[12px] font-[500] not-italic leading-4 text-black'>
                          (Create custom buy and sell offers in minutes)
                        </span>
                      </div>
                    </div>
                    <div className='flex  items-center gap-[10px]'>
                      <img src={signOk} alt='signOk' />
                      <div className=''>
                        <span className='text-base font-bold not-italic leading-4 text-black'>
                          Connected Accounts
                        </span>
                        <span className='text-[12px] font-[500] not-italic leading-4 text-black'>
                          {' '}
                          (Attach custom accounts to your offers)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-[30px]'>
                  <Button
                    onClick={handleSteps}
                    className='!h-[44px]  !rounded-[6px] !text-[16px]'
                  >
                    Go To Home
                  </Button>
                </div>
              </>
            )
          ) : (
            <BecomeAVendorThirdStep
              onNext={handleSteps}
              seOpen={setOpen}
              userProfile={USER_DETAILS}
            />
          )}
        </motion.div>
      </Modal>
    </>
  );
};

export default memo(BecomeVendor);

// first step
const BecomeAVendorFirstStep = ({ onNext, status }: any) => {
  const [read, setRead] = useState<boolean>(true);
  const handleScroll = (e: any) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      setRead(false);
    }
  };
  return (
    <>
      <div>
        <div>
          <p className='mb-[10px] text-sm font-bold not-italic leading-[14px] text-black'>
            Vendor Agreement:
          </p>
        </div>
        <div
          onScroll={(e: React.WheelEvent<HTMLDivElement>) => handleScroll(e)}
          className=' scrollbar mb-[20px] xl:h-[422px] lg:h-[400px] md:h-[380px] h-[300px] overflow-y-scroll rounded-[9px] border-2 p-[12px] '
        >
          <b className='mb-[12px] block text-sm font-bold not-italic leading-5 text-black'>
            Terms 1:
          </b>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
          <p className='mb-[15px] text-sm font-medium not-italic leading-5 text-black'>
            Lorem ipsum dolor sit amet consectetur. Id ullamcorper enim in ac
            nascetur et cursus ut sollicitudin. Eget diam leo orci consequat.
          </p>
        </div>
        <div>
          <Button
            loading={status === 'status'}
            disabled={read || status === 'loading'}
            onClick={onNext}
            className='!mt-[20px] h-[44px] !rounded-[6px] !text-[16px]'
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

const BecomeAVendorThirdStep = ({ onNext, seOpen, userProfile }: any) => {
  const handleModal = () => {
    seOpen();
  };
  return (
    <>
      <div className='w-full text-center'>
        <img src={DocumentsIcon} alt='DocumentsIcon' className='m-auto' />
        <div className='mb-[58px] mt-[20px] w-full px-[40px]'>
          <h3 className='mb-[20px] text-center text-[25px] font-bold not-italic leading-[32px] text-black'>
            Additional Documents Required
          </h3>
          <p className='text-center text-lg font-medium not-italic leading-[22px] text-[#464646]'>
            You need to add more documents to continue applying as a vendor.
          </p>
        </div>
        <div className=''>
          {!userProfile?.email_verified && (
            <div className='mb-[20px] flex items-center justify-between'>
              <div className='flex items-center gap-[10px]'>
                <span>
                  <img src={CheckIcon} alt='CheckIcon' />
                </span>
                <p className='text-[16px] font-bold not-italic leading-5 text-[#2E2E2E]'>
                  Verify Email Address
                </p>
              </div>
              <Link
                onClick={handleModal}
                to={'/dashboard/account-setting?email-verified=false'}
                className='flex h-8 w-[100px] shrink-0 items-center justify-center rounded-[100px] bg-[#0040E3] text-center text-xs font-bold not-italic leading-3 text-white'
              >
                VERIFY NOW
              </Link>
            </div>
          )}
          {!userProfile?.phoneVerified && (
            <div className='mb-[20px] flex items-center justify-between'>
              <div className='flex items-center gap-[10px]'>
                <span>
                  <img src={CheckIcon} alt='CheckIcon' />
                </span>
                <p className='text-[16px] font-bold not-italic leading-5 text-[#2E2E2E]'>
                  Verify Phone Number
                </p>
              </div>
              <Link
                onClick={handleModal}
                to={'/dashboard/account-setting?phoneNumber-verified=false'}
                className='flex h-8 w-[100px] shrink-0 items-center justify-center rounded-[100px] bg-[#0040E3] text-center text-xs font-bold not-italic leading-3 text-white'
              >
                VERIFY NOW
              </Link>
            </div>
          )}
          {!userProfile?.idVerified && (
            <div className='mb-[20px] flex items-center justify-between '>
              <div className='flex items-center gap-[10px]'>
                <span>
                  <img src={CheckIcon} alt='CheckIcon' />
                </span>
                <p className='text-[16px] font-bold not-italic leading-5 text-[#2E2E2E]'>
                  Verify ID
                </p>
              </div>
              <Link
                onClick={handleModal}
                to={'/dashboard/my-limit-kyc?id-verified=false'}
                className='flex h-8 w-[100px] shrink-0 items-center justify-center rounded-[100px] bg-[#0040E3] text-center text-xs font-bold not-italic leading-3 text-white'
              >
                VERIFY NOW
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

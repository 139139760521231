import { lazy } from 'react';
import Home from 'compositions/public/home';
import TwoFactorAuth from 'compositions/auth/two-factor-auth';

const OrderReceipt = lazy(
  () => import('compositions/app/dashboard/vendor-open-orders/order-receipt')
);

const DashboardHome = lazy(
  () => import('compositions/app/dashboard/dashboard-home/dashboard-home')
);

const Confirmation = lazy(() => import('compositions/auth/confirmation'));
const ForgotPassword = lazy(() => import('compositions/auth/forgot-password'));
const Login = lazy(() => import('compositions/auth/login'));
const ResetPassword = lazy(() => import('compositions/auth/reset-password'));
const Signup = lazy(() => import('compositions/auth/signup'));

const BuyCrypto = lazy(() => import('compositions/public/buy-crypto'));
const AcceptedCards = lazy(() => import('compositions/public/accepted-cards'));
const Company = lazy(() => import('compositions/public/company'));
const Singleblog = lazy(() => import('compositions/public/single-blog'));
const FAQ = lazy(() => import('compositions/public/faq'));
const Maps = lazy(() => import('compositions/public/map.card'));
const Wallet = lazy(() => import('compositions/public/wallet'));
const Becomevendor = lazy(() => import('compositions/public/become-a-vendor'));
const CreateTicket = lazy(
  () => import('compositions/app/dashboard/ticket/create-ticket')
);
const ViewAllTicket = lazy(
  () => import('compositions/app/dashboard/ticket/view-all-ticket')
);

const InstaOffers = lazy(
  () => import('compositions/app/dashboard/insta-offers')
);
const TransactionHistory = lazy(
  () =>
    import(
      'compositions/app/dashboard/seller-transaction-history/transaction-history'
    )
);
const TransactionHistoryReport = lazy(
  () =>
    import(
      'compositions/app/dashboard/seller-transaction-history/transaction-history-report'
    )
);
const Status = lazy(() => import('compositions/app/dashboard/status'));
const SubmitSuggestion = lazy(
  () => import('compositions/app/dashboard/submit-suggestion')
);
const ContactSupport = lazy(
  () => import('compositions/app/dashboard/contact-support')
);
const MyLimitKYC = lazy(
  () => import('compositions/app/dashboard/my-limit-kyc/my-limit-kyc')
);
const SecurityAnd2FA = lazy(
  () => import('compositions/app/dashboard/security-and-2fa/security&2fa')
);
const AccountSetting = lazy(
  () => import('compositions/app/dashboard/account-setting/account-setting')
);
const AffiliatePortal = lazy(
  () => import('compositions/app/dashboard/affiliate-portal')
);
const BecomeVendor = lazy(
  () => import('compositions/app/dashboard/become-vendor')
);
const SellGiftCard = lazy(
  () => import('compositions/app/dashboard/sell-gift-card')
);
const OrderHistory = lazy(
  () => import('compositions/app/dashboard/vendor-open-orders/order-history')
);
const SellerOpenOrder = lazy(
  () =>
    import(
      'compositions/app/dashboard/seller-transaction-history/seller-open-order'
    )
);
const ConnectedAccounts = lazy(
  () =>
    import('compositions/app/dashboard/connected-account/connected-accounts')
);
const MyOffers = lazy(
  () => import('compositions/app/dashboard/my-offers/my-offers')
);

const Profile = lazy(() => import('compositions/app/my-profile'));
const Blog = lazy(() => import('compositions/public/blog'));
const OpenOrders = lazy(
  () => import('compositions/app/dashboard/vendor-open-orders/open-orders')
);
const OpenOrdersDetail = lazy(
  () =>
    import('compositions/app/dashboard/vendor-open-orders/open-orders-detail')
);
const MyWallet = lazy(
  () => import('compositions/app/dashboard/my-wallet/my-wallet')
);
const ContactUs = lazy(() => import('compositions/public/contact-us'));
const TermsOfService = lazy(
  () => import('compositions/public/terms-of-service')
);
const PrivacyPolicy = lazy(() => import('compositions/public/privacy-policy'));
const NewSignUp = lazy(() => import('compositions/auth/signup-new/signup'));

const dashBoardRoutes = {
  dashBoardHome: { path: '/dashboard', element: <DashboardHome /> },
  instaOffer: { path: '/dashboard/insta-offer', element: <InstaOffers /> },
  openOrders: { path: '/dashboard/open-orders', element: <OpenOrders /> },
  openOrdersDetail: {
    path: '/dashboard/open-orders/detail',
    element: <OpenOrdersDetail />,
  },
  transactionHistory: {
    path: '/dashboard/transaction-history',
    element: <TransactionHistory />,
  },
  transactionHistoryDetail: {
    path: '/dashboard/transaction-history/detail',
    element: <TransactionHistoryReport />,
  },
  myWallet: { path: '/dashboard/my-wallet', element: <MyWallet /> },
  status: { path: '/dashboard/status', element: <Status /> },
  submitSuggestion: {
    path: '/dashboard/submit-suggestion',
    element: <SubmitSuggestion />,
  },
  contactUs: { path: '/dashboard/contact-us', element: <ContactSupport /> },
  myLimitKyc: { path: '/dashboard/my-limit-kyc', element: <MyLimitKYC /> },
  security2fa: { path: '/dashboard/security-2fa', element: <SecurityAnd2FA /> },
  accountSetting: {
    path: '/dashboard/account-setting',
    element: <AccountSetting />,
  },
  affilatePortal: {
    path: '/dashboard/affilate-portal',
    element: <AffiliatePortal />,
  },
  becomeAVendor: {
    path: '/dashboard/become-a-vendor',
    element: <BecomeVendor />,
  },
  sellGiftCard: {
    path: '/dashboard/sell-gift-card',
    element: <SellGiftCard />,
  },
  orderHistory: { path: '/dashboard/order-history', element: <OrderHistory /> },
  orderReceipt: { path: '/dashboard/order/:slug', element: <OrderReceipt /> },
  connectedAccounts: {
    path: '/dashboard/connected-accounts',
    element: <ConnectedAccounts />,
  },
  myoffers: { path: '/dashboard/my-offers', element: <MyOffers /> },
  sellerOpenOrder: {
    path: '/dashboard/open-order',
    element: <SellerOpenOrder />,
  },
  createTicket: { path: '/dashboard/create-ticket', element: <CreateTicket /> },
  viewAllTicket: {
    path: '/dashboard/view-all-tickets',
    element: <ViewAllTicket />,
  },
};

const appRoutes = {
  profile: { path: '/app/profile', element: <Profile /> },
};
const authRoutes = {
  login: { path: '/auth/login', element: <Login /> },
  newSignUp: { path: '/auth/old-signup', element: <Signup /> },
  forgotPassword: {
    path: '/auth/forgot-password',
    element: <ForgotPassword />,
  },
  resetPassword: { path: '/auth/reset-password', element: <ResetPassword /> },
  signup: { path: '/auth/signup', element: <NewSignUp /> },
  twoFactorAuth: { path: '/auth/2fa', element: <TwoFactorAuth /> },
  confirmation: { path: '/auth/confirmation', element: <Confirmation /> },
};

const publicRoutes = {
  home: { path: '/', element: <Home /> },
  buyCrypto: { path: '/buy-crypto', element: <BuyCrypto /> },
  acceptedCards: { path: '/accepted-cards', element: <AcceptedCards /> },
  company: { path: '/company', element: <Company /> },
  blog: { path: '/blog', element: <Blog /> },
  Singleblog: { path: '/blog/:slug', element: <Singleblog /> },
  faq: { path: '/faq', element: <FAQ /> },
  maps: { path: '/maps', element: <Maps /> },
  wallet: { path: '/wallet', element: <Wallet /> },
  becomevendor: { path: '/become-vendor', element: <Becomevendor /> },
  contactUs: { path: '/contact-us', element: <ContactUs /> },
  TermsOfService: { path: '/terms-of-service', element: <TermsOfService /> },
  PrivacyPolicy: { path: '/privacy-policy', element: <PrivacyPolicy /> },
};

export { dashBoardRoutes, publicRoutes, authRoutes, appRoutes };

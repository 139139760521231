import { createSlice } from "@reduxjs/toolkit";
import { Type } from "utils/interface";
import { sellerOrdersHistoryApi, sellerOrdersReceiptApi } from "../actions/sellerOrderAction";


const values = { data: [], status: 'idle', error: null } as Type
const initialState = {
    sellerOrdersHistoryApi: values,
    sellerOrdersReceiptApi:values,
};
const sellerOrderSlice = createSlice({
    name: 'seller',
    initialState,
    reducers: {
        clearSellerOrdersStatus: (state) => {
            const keys = Object.keys(state)
            for (let index = 0; index < keys.length; index++) {
                state[keys[index]].status = 'idle'
            }
        }
    },
    extraReducers(builder) {
        builder.addMatcher(action => [sellerOrdersHistoryApi.pending,sellerOrdersReceiptApi.pending].some(type => action.type.endsWith(type)), (state, { type }) => {
            const apiName = type.split('/')[1];
            state[apiName].status = 'loading';
            // state[apiName].data = [];
            state[apiName].error = null;

        })
            .addMatcher(action => [sellerOrdersHistoryApi.fulfilled,sellerOrdersReceiptApi.fulfilled].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].status = 'success';
                state[apiName].data = payload;
                state[apiName].error = null;

            })
            .addMatcher(action => [sellerOrdersHistoryApi.rejected,sellerOrdersReceiptApi.rejected].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].status = 'error';
                state[apiName].data = [];
                state[apiName].error = payload;
            });

    },
});

export const sellerOrderHistorySelector = (state: { sellerOrder: { sellerOrdersHistoryApi: Type } }) => state.sellerOrder.sellerOrdersHistoryApi
export const sellerOrderReceiptSelector = (state: { sellerOrder: { sellerOrdersReceiptApi: Type } }) => state.sellerOrder.sellerOrdersReceiptApi


export const { clearSellerOrdersStatus } = sellerOrderSlice.actions
export default sellerOrderSlice.reducer
import { createSlice } from "@reduxjs/toolkit";
import { Type } from "utils/interface";
import { createVendorApi } from "../actions/vendorAction";


const values = { data: [], status: 'idle', error: null } as Type
const initialState = {
    createVendorApi: values,
};
const vendorSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        clearVendorStatus: (state) => {
            const keys = Object.keys(state)
            for (let index = 0; index < keys.length; index++) {
                state[keys[index]].status = 'idle'
            }
        }
    },
    extraReducers(builder) {
        builder.addMatcher(action => [createVendorApi.pending].some(type => action.type.endsWith(type)), (state, { type }) => {
            const apiName = type.split('/')[1];
            state[apiName].status = 'loading';
            // state[apiName].data = [];
            state[apiName].error = null;

        })
            .addMatcher(action => [createVendorApi.fulfilled].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].status = 'success';
                state[apiName].data = payload;
                state[apiName].error = null;

            })
            .addMatcher(action => [createVendorApi.rejected].some(type => action.type.endsWith(type)), (state, { type, payload }) => {
                const apiName = type.split('/')[1];
                state[apiName].status = 'error';
                state[apiName].data = [];
                state[apiName].error = payload;
            });

    },
});

export const createVendorSelector = (state: { vendor: { createVendorApi: Type } }) => state.vendor.createVendorApi

export const { clearVendorStatus } = vendorSlice.actions
export default vendorSlice.reducer